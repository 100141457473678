var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import classnames from 'classnames';
import omit from 'lodash/omit';
import React, { forwardRef } from 'react';
import EdsButton from '@ellucian/react-design-system/core/es/Button';
import Icon from '../Icon';
import Text from '../Text';
import { createStyles, withStyles } from '../styles/withStyles';
import Tokens from '../styles/Tokens';
import { isWidthDown, withWidth } from '../withWidth/withWidth';
const styles = createStyles({
    centerAlign: {
        textAlign: 'center'
    },
    childrenContainer: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center'
    },
    contained: {},
    containedSecondary: {},
    disabled: {},
    inheritAlign: {
        textAlign: 'inherit'
    },
    justifyAlign: {
        textAlign: 'justify'
    },
    leftAlign: {
        textAlign: 'left'
    },
    rightAlign: {
        textAlign: 'right'
    },
    root: {},
    text: {},
    textInline: {
        textAlign: 'center',
        width: Tokens.widthFluid
    },
    sizeDefault: {},
    sizeLarge: {}
});
const Button = (props, ref) => {
    const { IconProps, TextProps, align, children, classes, disabled, fluid, loading, noTextCover, textVariantStyling, variant, width } = props, rest = __rest(props, ["IconProps", "TextProps", "align", "children", "classes", "disabled", "fluid", "loading", "noTextCover", "textVariantStyling", "variant", "width"]);
    const isXSmall = isWidthDown('xs', width);
    const isTextInlineButton = variant === 'text' && textVariantStyling === 'inline';
    const isTextStandaloneButton = variant === 'text' && textVariantStyling !== 'inline';
    const classOverrides = omit(classes, ['centerAlign', 'childrenContainer', 'inheritAlign',
        'justifyAlign', 'leftAlign', 'rightAlign', 'textInline']);
    if (align) {
        switch (align) {
            case 'center':
                classOverrides.textInline = classnames(classOverrides.textInline, classes.centerAlign);
                classOverrides.textStandalone = classnames(classOverrides.textStandalone, classes.centerAlign);
                break;
            case 'inherit':
                classOverrides.textInline = classnames(classOverrides.textInline, classes.inheritAlign);
                classOverrides.textStandalone = classnames(classOverrides.textStandalone, classes.inheritAlign);
                break;
            case 'justify':
                classOverrides.textInline = classnames(classOverrides.textInline, classes.justifyAlign);
                classOverrides.textStandalone = classnames(classOverrides.textStandalone, classes.justifyAlign);
                break;
            case 'left':
                classOverrides.textInline = classnames(classOverrides.textInline, classes.leftAlign);
                classOverrides.textStandalone = classnames(classOverrides.textStandalone, classes.leftAlign);
                break;
            case 'right':
                classOverrides.textInline = classnames(classOverrides.textInline, classes.rightAlign);
                classOverrides.textStandalone = classnames(classOverrides.textStandalone, classes.rightAlign);
                break;
        }
    }
    if (isXSmall && isTextStandaloneButton) {
        classOverrides.textStandalone = classnames(classOverrides.textStandalone, classes.textInline);
    }
    const contentButton = (React.createElement(React.Fragment, null, IconProps || loading ? (React.createElement("span", { className: classes.childrenContainer },
        loading ? (React.createElement(Icon, { marginRight: true, name: "spinner", spin: true })) : (IconProps && (React.createElement(Icon, Object.assign({ marginRight: true }, IconProps)))),
        React.createElement("span", null, children))) : (React.createElement("span", null, children))));
    const button = (React.createElement(EdsButton, Object.assign({ classes: classOverrides, disabled: loading ? true : disabled, fluid: !isXSmall
            || fluid !== undefined
            || isTextInlineButton ? fluid : true, textVariantStyling: textVariantStyling, variant: variant, ref: ref }, rest), contentButton));
    if (!noTextCover && isTextInlineButton) {
        return (React.createElement(Text, Object.assign({ component: "span", ref: ref }, TextProps), button));
    }
    else {
        return (React.createElement(React.Fragment, null, button));
    }
};
const ButtonFRC = forwardRef(Button);
ButtonFRC.displayName = 'ButtonFRC';
ButtonFRC.defaultProps = {
    id: ''
};
export default withStyles(styles, { name: 'ButtonWS' })(withWidth()(ButtonFRC));
