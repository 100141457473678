import classnames from 'classnames';
import React from 'react';
import EdsFooter from '@ellucian/react-design-system/core/es/Footer';
import Tokens from '../styles/Tokens';
import { createStyles, withStyles } from '../styles/withStyles';
const styles = createStyles({
    footer: {
        width: Tokens.widthFluid
    }
});
const Footer = (props) => {
    const { children, classes, className, id, text } = props;
    let classFooter = classes.footer;
    if (className) {
        classFooter = classnames(classFooter, className);
    }
    return (React.createElement("div", { className: classFooter },
        React.createElement(EdsFooter, { footerText: text, id: id }, children)));
};
export default withStyles(styles)(Footer);
