var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withEDSContext } from '../EDSContext/EDSContext';
import { default as MuiPopper } from '@material-ui/core/Popper';
import Paper from '../Paper';
import Typography from '../Typography';
import { borderWidthThin, borderRadiusMedium, spacing40, fontFamilyHeader, fontSizeHeader2, fontWeightNormal, lineHeightHeader2Small, fontSizeHeader2Small, lineHeightHeader2, colorGlobalBorderDefault } from '@ellucian/react-design-system/core/styles/tokens';
import withStyles from '@ellucian/react-design-system/core/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Fade from '@material-ui/core/Fade';

var styles = function styles(theme) {
    return {
        paperRoot: {
            padding: spacing40,
            border: borderWidthThin + ' solid ' + colorGlobalBorderDefault,
            borderRadius: borderRadiusMedium
        },
        popper: {
            zIndex: theme.zIndex.tooltip,
            // Arrow styling:
            // https://github.com/twbs/bootstrap/blob/master/dist/css/bootstrap.css#L5655
            '& .hedtech-popper-arrow': {
                position: 'absolute',
                display: 'block',
                width: '1rem',
                height: '0.5rem',
                margin: '0 0.3rem',
                '&::before, &::after': {
                    position: 'absolute',
                    display: 'block',
                    content: '""',
                    borderColor: 'transparent',
                    borderStyle: 'solid'
                }
            },
            '&[x-placement*="top"] .hedtech-popper-arrow': {
                bottom: '-.5rem',
                '&::before': {
                    bottom: 0,
                    borderWidth: '0.5rem 0.5rem 0',
                    borderTopColor: colorGlobalBorderDefault
                },
                '&::after': {
                    bottom: 1,
                    borderWidth: '0.5rem 0.5rem 0',
                    borderTopColor: theme.palette.grey[100]
                }
            },
            '&[x-placement*="right"] .hedtech-popper-arrow': {
                flip: false,
                left: '-.5rem',
                width: '0.5rem',
                height: '1rem',
                margin: '0.3rem 0',
                '&::before': {
                    flip: false,
                    left: 0,
                    borderWidth: '0.5rem 0.5rem 0.5rem 0',
                    borderRightColor: colorGlobalBorderDefault
                },
                '&::after': {
                    flip: false,
                    left: 1,
                    borderWidth: '0.5rem 0.5rem 0.5rem 0',
                    borderRightColor: theme.palette.grey[100]
                }
            },
            '&[x-placement*="bottom"] .hedtech-popper-arrow': {
                top: '-.5rem',
                '&::before': {
                    top: 0,
                    borderWidth: '0 0.5rem 0.5rem 0.5rem',
                    borderBottomColor: colorGlobalBorderDefault
                },
                '&::after': {
                    top: 1,
                    borderWidth: '0 0.5rem 0.5rem 0.5rem',
                    borderBottomColor: theme.palette.grey[100]
                }
            },
            '&[x-placement*="left"] .hedtech-popper-arrow': {
                flip: false,
                right: '-.5rem',
                width: '0.5rem',
                height: '1rem',
                margin: '0.3rem 0',
                '&::before': {
                    flip: false,
                    right: 0,
                    borderWidth: '0.5rem 0 0.5rem 0.5rem',
                    borderLeftColor: colorGlobalBorderDefault
                },
                '&::after': {
                    flip: false,
                    right: 1,
                    borderWidth: '0.5rem 0 0.5rem 0.5rem',
                    borderLeftColor: theme.palette.grey[100]
                }
            }
        },
        popperText: {
            marginBottom: spacing40
        },
        popperTitle: _defineProperty({ // h2 styles
            fontFamily: fontFamilyHeader,
            fontWeight: fontWeightNormal,
            fontSize: fontSizeHeader2Small,
            lineHeight: lineHeightHeader2
        }, theme.breakpoints.up('sm'), {
            fontSize: fontSizeHeader2,
            lineHeight: lineHeightHeader2Small
        })
    };
};

/**
 * Use `Popper` on top of content to give additional context or information.
 * @done true
 * @updated false
 * @versionAdded v2.3.0
 * @examples
 *  MinimalPopper
 *  CustomContent
 *  CloseOnClickaway
 *  ScrollPlayground
 */

var Popper = React.forwardRef(function (props, ref) {
    var arrow = props.arrow,
        children = props.children,
        classes = props.classes,
        edsContext = props.edsContext,
        classNameProp = props.className,
        modifiersProp = props.modifiers,
        PaperPropsProp = props.PaperProps,
        text = props.text,
        title = props.title,
        TextTypographyProps = props.TextTypographyProps,
        TitleTypographyProps = props.TitleTypographyProps,
        onClickAway = props.onClickAway,
        ClickAwayListenerProps = props.ClickAwayListenerProps,
        transitionProps = props.transitionProps,
        rest = _objectWithoutProperties(props, ['arrow', 'children', 'classes', 'edsContext', 'className', 'modifiers', 'PaperProps', 'text', 'title', 'TextTypographyProps', 'TitleTypographyProps', 'onClickAway', 'ClickAwayListenerProps', 'transitionProps']);

    var _useState = useState(null),
        _useState2 = _slicedToArray(_useState, 2),
        arrowRef = _useState2[0],
        setArrowRef = _useState2[1];

    var handleArrowRef = function handleArrowRef(node) {
        return setArrowRef(node);
    };
    var PaperProps = Object.assign({}, PaperPropsProp);

    // This line will help screen read to read popper content.
    if (!PaperProps['aria-live']) {
        PaperProps['aria-live'] = 'assertive';
    }

    var addSpace = function addSpace(data) {
        var newData = data;

        // Add additional space to Popper element (16px)
        if (data.placement.indexOf('top') !== -1) {
            newData.offsets.popper.top = data.offsets.popper.top - 16;
        } else if (data.placement.indexOf('right') !== -1) {
            newData.offsets.popper.left = data.offsets.popper.left + 16;
        } else if (data.placement.indexOf('bottom') !== -1) {
            newData.offsets.popper.top = data.offsets.popper.top + 16;
        } else if (data.placement.indexOf('left') !== -1) {
            newData.offsets.popper.left = data.offsets.popper.left - 16;
        }
        return newData;
    };

    var modifiers = Object.assign({
        arrow: {
            enabled: arrow,
            element: arrowRef
        },
        flip: {
            enabled: true
        },
        offset: {
            fn: addSpace
        }
    }, modifiersProp);

    var className = classNames(classes.popper, classNameProp);

    // Build Popper content
    var PopperContent = React.createElement(
        'div',
        { dir: edsContext.dir },
        arrow && React.createElement('span', { className: 'hedtech-popper-arrow', ref: handleArrowRef }),
        React.createElement(
            Paper,
            Object.assign({
                elevation: 0,
                classes: {
                    root: classes.paperRoot
                }
            }, PaperProps, {
                ref: ref
            }),
            title && React.createElement(
                Typography,
                Object.assign({
                    classes: { root: classes.popperTitle },
                    gutterBottom: true
                }, TitleTypographyProps),
                title
            ),
            text && React.createElement(
                Typography,
                Object.assign({
                    gutterBottom: true,
                    className: classNames(_defineProperty({}, classes.popperText, children !== undefined))
                }, TextTypographyProps),
                text
            ),
            children
        )
    );

    var Content = onClickAway ? React.createElement(
        ClickAwayListener,
        Object.assign({ onClickAway: onClickAway }, ClickAwayListenerProps),
        PopperContent
    ) : PopperContent;

    // NOTE: Structure must be, Popper > Transition > ClickAway > Content

    return rest.transition ? React.createElement(
        MuiPopper,
        Object.assign({ className: className, modifiers: modifiers }, rest),
        function (_ref) {
            var TransitionProps = _ref.TransitionProps;
            return React.createElement(
                Fade,
                Object.assign({}, TransitionProps, transitionProps),
                Content
            );
        }
    ) : React.createElement(
        MuiPopper,
        Object.assign({ className: className, modifiers: modifiers }, rest),
        Content
    );
});

Popper.muiName = 'Popper';
Popper.displayName = 'Popper';

Popper.propTypes = {
    /**
     * This is the DOM element, or a function that returns the DOM element,
     * that may be used to set the position of the popover.
     * The return value will passed as the reference object of the `Popper`
     * instance.
     */
    anchorEl: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    /**
     * If `true`, popper will display the arrow.
     */
    arrow: PropTypes.bool,
    /**
     * `Popper` render function or node.
     */
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    /**
     * @ignore
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object.isRequired,
    /**
     * A node, component instance, or function that returns either.
     * The `container` will passed to the Modal component.
     * By default, it uses the body of the `anchorEl`'s top-level document object,
     * so it's simply `document.body` most of the time.
     */
    container: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    /**
     * Disable the portal behavior.
     * The children stay within its parent DOM hierarchy.
     */
    disablePortal: PropTypes.bool,
    /**
     * Always keep the children in the DOM.
     */
    keepMounted: PropTypes.bool,
    /**
     * Popper.js is based on a "plugin-like" architecture,
     * most of its features are fully encapsulated "modifiers".
     *
     * A modifier is a function that is called each time Popper.js needs to
     * compute the position of the `Popper`.
     * For this reason, modifiers should be very performant to avoid bottlenecks.
     * To learn how to create a modifier, [read the modifiers documentation](https://github.com/FezVrasta/popper.js/blob/master/docs/_includes/popper-documentation.md#modifiers--object).
     */
    modifiers: PropTypes.object,
    /**
     * If `true`, the popper is visible.
     */
    open: PropTypes.bool.isRequired,
    /**
     * Props provided to the `Paper` component
     */
    PaperProps: PropTypes.object,
    /**
     * Props provided to the text `Typography` component
     */
    TextTypographyProps: PropTypes.object,
    /**
     * Props provided to the title `Typography` component
     */
    TitleTypographyProps: PropTypes.object,
    /**
     * `Popper` placement.
     */
    placement: PropTypes.oneOf(['bottom-end', 'bottom-start', 'bottom', 'left-end', 'left-start', 'left', 'right-end', 'right-start', 'right', 'top-end', 'top-start', 'top']),
    /**
     * Options provided to the [`popper.js`](https://github.com/FezVrasta/popper.js) instance.
     */
    popperOptions: PropTypes.object,
    /**
     * Paragraph text that will render below the `title` text and above any children.
     */
    text: PropTypes.string,
    /**
     * Paragraph text that renders above the `text` paragraph text.
     */
    title: PropTypes.string,
    /**
     * Help supporting a react-transition-group/Transition component.
     */
    transition: PropTypes.bool,
    /**
     * Properties applied to the Transition component.
     */
    transitionProps: PropTypes.object,
    // /**
    //  * The component used for the transition.
    //  */
    // TransitionComponent: PropTypes.oneOf([
    //     'Collapse', 'Fade', 'Grow', 'Slide', 'Zoom'
    // ]),
    /**
     * Function to perform when clicked outside of the `Popper`.
     */
    onClickAway: PropTypes.func,
    /**
     * Props provided to the `ClickAwayListener` component
     */
    ClickAwayListenerProps: PropTypes.object,
    /**
     * @ignore
     */
    edsContext: PropTypes.object
};

Popper.defaultProps = {
    placement: 'bottom',
    transition: true
};

export default withEDSContext(withStyles(styles)(Popper));