function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '../styles';
import Typography from '../Typography';
import Divider from '../Divider';
import { spacing50 } from '../styles/tokens';

var styles = {
    root: {
        marginBottom: spacing50
    }
};

/**
 * A component that displays the current step of the total steps
 */
var MobileStepperTitle = React.forwardRef(function (props, ref) {
    var withDivider = props.withDivider,
        children = props.children,
        classes = props.classes,
        rest = _objectWithoutProperties(props, ['withDivider', 'children', 'classes']);

    return React.createElement(
        'div',
        { className: classes.root, ref: ref },
        React.createElement(
            Typography,
            Object.assign({ variant: 'h2' }, rest),
            children
        ),
        withDivider && React.createElement(Divider, null)
    );
});

MobileStepperTitle.propTypes = {
    /**
     * The MobileStepper title value.
     */
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    /**
     * @ignore
     */
    classes: PropTypes.object,
    /**
     * Props will be passed into the rendered `<Typography>` component.
     */
    props: PropTypes.object,
    /**
     * Render the MobileStepperTitle with a divider. Set false to use without a Divider.
     */
    withDivider: PropTypes.bool
};

MobileStepperTitle.defaultProps = {
    withDivider: true
};

MobileStepperTitle.displayName = 'MobileStepperTitle';

export default withStyles(styles)(MobileStepperTitle);