var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import classnames from 'classnames';
import omit from 'lodash/omit';
import React from 'react';
import EdsBackdrop from '@ellucian/react-design-system/core/es/Backdrop';
import EdsCircularProgress from '@ellucian/react-design-system/core/es/CircularProgress';
import Tokens from '../styles/Tokens';
import { createStyles, withStyles } from '../styles/withStyles';
const styles = (theme) => createStyles({
    customPageLoader: {
        color: theme.palette.primary.main
    },
    overModal: {
        'z-index': `${Number(Tokens.zIndexModal) + 1}${Tokens.important}`
    }
});
const PageLoader = (props) => {
    const { classes, id, isDefault, open, overModal } = props, rest = __rest(props, ["classes", "id", "isDefault", "open", "overModal"]);
    const classOverrides = omit(classes, ['overModal', 'customPageLoader']);
    if (overModal) {
        classOverrides.root = classnames(classes.root, classes.overModal);
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(EdsBackdrop, { classes: classOverrides, open: open },
            React.createElement(EdsCircularProgress, Object.assign({ className: !isDefault ? classes.customPageLoader : undefined, id: id, ize: 50, role: "progressbar" }, rest)))));
};
export default withStyles(styles)(PageLoader);
