function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { default as withStyles } from '../styles';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '../IconButton/IconButton';
import Tooltip from '../Tooltip/Tooltip';
import ChevronUp from '@ellucian/ds-icons/lib/ChevronUp';
import ChevronDown from '@ellucian/ds-icons/lib/ChevronDown';
import Edit from '@ellucian/ds-icons/lib/Edit';
import { Typography } from '../';
import { withEDSContext } from '../EDSContext/EDSContext';

import { colorTextNeutral500, spacing40, sizingSmall, widthFluid } from '../styles/tokens';

var styles = function styles(theme) {
    return {
        root: {
            display: 'flex',
            padding: spacing40
        },
        flex: {
            flexGrow: 1,
            color: colorTextNeutral500
        },
        footer: {
            display: 'flex',
            justifyContent: 'flex-end',
            width: widthFluid,
            alignItems: 'center'
        },
        footerLeft: {
            justifyContent: 'flex-start'
        },
        iconSize: {
            height: sizingSmall,
            width: sizingSmall
        }
    };
};

/**
 * TableToolbar
 */
var TableToolbar = React.forwardRef(function (props, ref) {
    var _classnames;

    var classes = props.classes,
        caption = props.caption,
        showExpandAllToggle = props.showExpandAllToggle,
        showEditToggle = props.showEditToggle,
        children = props.children,
        expanded = props.expanded,
        onExpand = props.onExpand,
        onEdit = props.onEdit,
        location = props.location,
        footerAlignItems = props.footerAlignItems,
        showFooterToolbarOnEdit = props.showFooterToolbarOnEdit,
        edsContext = props.edsContext,
        TypographyProps = props.TypographyProps,
        rest = _objectWithoutProperties(props, ['classes', 'caption', 'showExpandAllToggle', 'showEditToggle', 'children', 'expanded', 'onExpand', 'onEdit', 'location', 'footerAlignItems', 'showFooterToolbarOnEdit', 'edsContext', 'TypographyProps']);

    var showToolbar = location === 'footer' && showFooterToolbarOnEdit || location === 'header';

    var toggleExpand = function toggleExpand(e) {
        if (e.type === 'keypress' && (e.key === 'Enter' || e.key === ' ')) {
            e.preventDefault();
            onExpand(e);
        } else if (e.type === 'click') {
            onExpand(e);
        }
    };

    var toggleEdit = function toggleEdit(e) {
        if (e.type === 'keypress' && (e.key === 'Enter' || e.key === ' ')) {
            e.preventDefault();
            onEdit(e);
        } else if (e.type === 'click') {
            onEdit(e);
        }
    };

    var expandLabel = expanded ? edsContext.formatMessage('component.Table.hideAll') : edsContext.formatMessage('component.Table.showAll');

    return React.createElement(
        React.Fragment,
        null,
        showToolbar && React.createElement(
            Toolbar,
            Object.assign({ className: classes.root }, rest, { ref: ref }),
            caption && React.createElement(
                Typography,
                Object.assign({ variant: 'h2', className: classes.flex }, TypographyProps),
                caption
            ),
            React.createElement(
                'div',
                { className: classnames((_classnames = {}, _defineProperty(_classnames, classes.footer, location === 'footer'), _defineProperty(_classnames, classes.footerLeft, footerAlignItems === 'left'), _classnames)) },
                children,
                showEditToggle && React.createElement(
                    Tooltip,
                    { title: edsContext.formatMessage('component.Table.edit') },
                    React.createElement(
                        IconButton,
                        {
                            color: 'gray',
                            'aria-expanded': expanded,
                            'aria-label': edsContext.formatMessage('component.Table.edit'),
                            onClick: function onClick(e) {
                                return toggleEdit(e);
                            },
                            tabIndex: 0
                        },
                        React.createElement(Edit, { className: classes.iconSize })
                    )
                ),
                showExpandAllToggle && React.createElement(
                    Tooltip,
                    { title: expandLabel },
                    React.createElement(
                        IconButton,
                        {
                            color: 'gray',
                            'aria-expanded': expanded,
                            'aria-label': expandLabel,
                            onClick: function onClick(e) {
                                return toggleExpand(e);
                            },
                            tabIndex: 0
                        },
                        expanded ? React.createElement(ChevronUp, { className: classes.iconSize }) : React.createElement(ChevronDown, { className: classes.iconSize })
                    )
                )
            )
        )
    );
});

TableToolbar.muiName = 'TableToolbar';
TableToolbar.displayName = 'TableToolbar';

TableToolbar.propTypes = {
    /**
     * The content of the table, normally `TableHeader` and `TableBody`.
     */
    children: PropTypes.node,
    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object,
    /**
     * Class name to be applied to the Alert container.
     */
    className: PropTypes.string,
    /**
     * Table caption or table title
     */
    caption: PropTypes.string,
    /**
     * Show expand all or collapse all icon for expansion panels on mobile or expandable rows.
     */
    showExpandAllToggle: PropTypes.bool,
    /**
     * Prop to toggle expand all.
     */
    expanded: PropTypes.bool,
    /**
     * @ignore
     */
    edsContext: PropTypes.object,
    /**
     * Callback to toggle the boolean value
     */
    onExpand: PropTypes.func,
    /**
     * Show editable toggle button to switch between edit and read-only table mode.
     */
    showEditToggle: PropTypes.bool,
    /**
     * Callback when edit toggle button is clicked
     */
    onEdit: PropTypes.func,
    /**
     * Align items for footer actions.
     */
    footerAlignItems: PropTypes.oneOf(['left', 'right']),
    /**
     * If you need to use actions along with table caption,
     * use `header` location. For action with footer, use `footer` location.
     * NOTE: Captions actions are always right aligned.
     * For footer actions, you can choose left or right.
     */
    location: PropTypes.oneOf(['header', 'footer']),
    /**
     * For footer, show the footer actions only when table is in edit mode.
     */
    showFooterToolbarOnEdit: PropTypes.bool,
    /**
     * Props that will be applied to the `Typography (caption)` element.
     */
    TypographyProps: PropTypes.object
};

TableToolbar.defaultProps = {
    showExpandAllToggle: false,
    showEditToggle: false,
    expanded: false,
    location: 'header',
    footerAlignItems: 'right'
};

export default withEDSContext(withStyles(styles)(TableToolbar));