import { Tokens } from '../react/core/styles';
import Constants from './Constants';
const ThemeHelper = {
    getDefaultEmpty() {
        return {
            actionColor: '',
            backgroundUrl: '',
            faviconUrl: '',
            logoAlternateText: '',
            logoUrl: '',
            primaryColor: '',
            secondaryColor: '',
            studyingCalendarColor: '',
            teachingCalendarColor: ''
        };
    },
    getDefaultValues() {
        return {
            actionColor: Constants.defaultActionColor,
            backgroundUrl: '',
            faviconUrl: '',
            logoAlternateText: '',
            logoUrl: '',
            primaryColor: Tokens.colorBrandPrimary,
            secondaryColor: Tokens.colorBrandSecondary,
            studyingCalendarColor: Tokens.tangerine600,
            teachingCalendarColor: Tokens.purple600
        };
    },
    withDefaultValues(theme) {
        if (theme) {
            const themeSettingsWithDefaults = Object.assign({}, theme);
            const defaultTheme = this.getDefaultValues();
            if (!themeSettingsWithDefaults.primaryColor) {
                themeSettingsWithDefaults.primaryColor = defaultTheme.primaryColor;
            }
            if (!themeSettingsWithDefaults.secondaryColor) {
                themeSettingsWithDefaults.secondaryColor = defaultTheme.secondaryColor;
            }
            if (!themeSettingsWithDefaults.actionColor) {
                themeSettingsWithDefaults.actionColor = defaultTheme.actionColor;
            }
            if (!themeSettingsWithDefaults.studyingCalendarColor) {
                themeSettingsWithDefaults.studyingCalendarColor = defaultTheme.studyingCalendarColor;
            }
            if (!themeSettingsWithDefaults.teachingCalendarColor) {
                themeSettingsWithDefaults.teachingCalendarColor = defaultTheme.teachingCalendarColor;
            }
            return themeSettingsWithDefaults;
        }
        return this.getDefaultValues();
    },
    hasHTML5ColorCompatibility() {
        try {
            const color = document.createElement('input');
            color.setAttribute('type', 'color');
            return color.type !== 'text';
        }
        catch (_a) {
            return false;
        }
    }
};
export default ThemeHelper;
