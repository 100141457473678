function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { default as withStyles } from '../styles';
import { default as MuiInputAdornment } from '@material-ui/core/InputAdornment';
import { spacing30, fontFamilyDefault, fontSizeDefault, fontSizeLarge, colorTextNeutral500 } from '../styles/tokens';

var styles = {
    root: {
        fontFamily: fontFamilyDefault,
        fontSize: fontSizeLarge,
        color: colorTextNeutral500
    },
    positionStart: {
        marginTop: '0.75rem',
        marginLeft: '.875rem',
        marginRight: 0,
        '&$smallTextField': {
            marginTop: 0,
            marginLeft: spacing30
        }
    },
    smallTextField: {
        fontSize: fontSizeDefault
    }
};

/**
 * Use `InputAdornment` to add a decoration to a `TextField`.
 * @done true
 * @updated false
 * @versionAdded v3.13.0
 * @examples
 *  InputAdornmentDemo
 */
var InputAdornment = React.forwardRef(function (props, ref) {
    var classes = props.classes,
        rest = _objectWithoutProperties(props, ['classes']);

    var classOverrides = {
        root: classes.root,
        positionStart: classNames(classes.positionStart, _defineProperty({}, classes.smallTextField, props.size === 'small')),
        positionEnd: classNames(_defineProperty({}, classes.smallTextField, props.size === 'small'))
    };
    //disableTypography={true}
    return React.createElement(MuiInputAdornment, Object.assign({ ref: ref, classes: classOverrides }, rest));
});

InputAdornment.muiName = 'InputAdornment';

InputAdornment.propTypes = {
    /**
     * The content of the component, normally an `Icon` or string.
     */
    children: PropTypes.node.isRequired,
    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object,
    /**
     * @ignore
     */
    className: PropTypes.string,
    /**
     * The component used for the root node.
     * Either a string to use a HTML element or a component.
     */
    component: PropTypes.elementType,
    /**
     * Disable pointer events on the root.
     * This allows for the content of the adornment to focus the input on click.
     */
    disablePointerEvents: PropTypes.bool,
    /**
     * If children is a string then disable wrapping in a Typography component.
     */
    disableTypography: PropTypes.bool,
    /**
     * @ignore
     */
    muiFormControl: PropTypes.object,
    /**
     * The position this adornment should appear relative to the `Input`.
     */
    position: PropTypes.oneOf(['start', 'end']),
    /**
     * @ignore
     * The size of the parent `TextField` or `FormControl` component.
     */
    size: PropTypes.oneOf(['small', 'medium']),
    /**
     * @ignore
     * The variant to use.
     * Note: If you are using the `TextField` component or the `FormControl` component
     * you do not have to set this manually.
     */
    variant: PropTypes.oneOf(['standard', 'outlined', 'filled'])
};

InputAdornment.displayName = 'InputAdornment';

InputAdornment.defaultProps = {
    component: 'div',
    disablePointerEvents: false,
    disableTypography: true
};

export default withStyles(styles)(InputAdornment);