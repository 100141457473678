function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { default as withStyles } from '../styles';
import { withEDSContext } from '../EDSContext/EDSContext';
import { default as MuiSnackbar } from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CheckFeedbackIcon from '@ellucian/ds-icons/lib/CheckFeedback';
import WarningIcon from '@ellucian/ds-icons/lib/Warning';
import ErrorIcon from '@ellucian/ds-icons/lib/Error';
import InfoIcon from '@ellucian/ds-icons/lib/Info';
import CloseIcon from '@ellucian/ds-icons/lib/Close';
import { spacingVariant } from '../globalSpacing';
import { getSpacingOptions } from './../globalSpacing';
import Tooltip from '../Tooltip/Tooltip';

import { boxShadowReset, sizingXxLarge1, sizingMedium, borderWidthThick, borderWidthThin, widthFluid, spacing40, spacing50, spacing60, spacing70 } from '../styles/tokens';

// Icon type object
var alertTypeIcon = {
    success: CheckFeedbackIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon
};

var styles = function styles(theme) {
    var _root, _pageAlertMessageText;

    return {
        root: (_root = {
            padding: '' + spacing40,
            width: widthFluid
        }, _defineProperty(_root, theme.breakpoints.up('md'), {
            padding: '' + spacing50
        }), _defineProperty(_root, theme.breakpoints.down('sm'), {
            padding: '' + spacing40
        }), _root),
        inlineTop: {
            left: 'auto',
            position: 'relative',
            right: 'auto',
            top: 'auto',
            transform: 'none',
            zIndex: theme.zIndex.inlineAlert,
            width: widthFluid
        },
        inlineAlert: {
            minHeight: '4.5rem',
            display: 'flex',
            boxShadow: boxShadowReset,
            maxWidth: 'none'
        },
        pageTop: _defineProperty({
            top: sizingXxLarge1, // Push the page alerts down the height of the header bar
            width: widthFluid,
            zIndex: theme.zIndex.pageAlert
        }, theme.breakpoints.down('xs'), {
            left: '0px'
        }),
        pageAlert: {
            display: 'flex',
            boxShadow: boxShadowReset,
            maxWidth: 'none'
        },
        contentRoot: {
            fontSize: theme.typography.body1.fontSize,
            boxShadow: boxShadowReset,
            maxWidth: 'none',
            width: widthFluid,
            padding: 0,
            borderRadius: '0'
        },
        messageWidth: {
            width: widthFluid
        },
        none: {
            padding: 0
        },
        // Background and text colors for alert variants
        success: {
            backgroundColor: theme.palette.status.success.background,
            color: theme.palette.status.success.text,
            fill: theme.palette.status.success.text
        },
        error: {
            backgroundColor: theme.palette.status.error.background,
            color: theme.palette.status.error.text,
            fill: theme.palette.status.error.text
        },
        info: {
            backgroundColor: theme.palette.status.neutral.background,
            color: theme.palette.status.neutral.text,
            fill: theme.palette.status.neutral.text
        },
        warning: {
            backgroundColor: theme.palette.status.warning.background,
            color: theme.palette.status.warning.text,
            fill: theme.palette.status.warning.text
        },
        // Border colors for inline alert variants
        successBorder: {
            borderTop: borderWidthThick + ' solid ' + theme.palette.status.success.fill
        },
        errorBorder: {
            borderTop: borderWidthThick + ' solid ' + theme.palette.status.error.fill
        },
        infoBorder: {
            borderTop: borderWidthThick + ' solid ' + theme.palette.status.neutral.fill
        },
        warningBorder: {
            borderTop: borderWidthThick + ' solid ' + theme.palette.status.warning.fill
        },
        // Page Alert Icon
        alertIcon: {
            marginRight: spacing40
        },
        // Parent container for message prop in Snackbar
        message: {
            display: 'flex',
            justifyContent: 'space-between'
        },
        // Target the text of the page alert
        inlineAlertMessageText: _defineProperty({
            flexGrow: 1,
            marginRight: spacing60
        }, theme.breakpoints.down('sm'), {
            marginRight: 4
        }),
        pageAlertMessageText: (_pageAlertMessageText = {
            flexGrow: 1,
            marginRight: spacing50
        }, _defineProperty(_pageAlertMessageText, theme.breakpoints.up('sm'), {
            marginRight: spacing40
        }), _defineProperty(_pageAlertMessageText, theme.breakpoints.up('md'), {
            marginRight: spacing70
        }), _defineProperty(_pageAlertMessageText, theme.breakpoints.up('lg'), {
            marginRight: spacing60
        }), _pageAlertMessageText),
        // Close Icon
        closeIcon: {
            alignSelf: 'flex-start',
            height: 'auto',
            width: 'auto',
            padding: 0,
            '&:hover': {
                backgroundColor: 'transparent'
            },
            '&:focus': {
                outline: borderWidthThin + ' solid'
            }
        },
        iconSvg: {
            width: sizingMedium,
            height: sizingMedium,
            verticalAlign: 'bottom'
        }
    };
};

// valid variant prop values
export var PAGE_VARIANT = 'page';
export var INLINE_VARIANT = 'inline';

/**
 * Use `Alert` to provide feedback on an operation through a message.
 * @done true
 * @updated false
 * @versionAdded v0.0.9
 * @examples
 *  AlertInlineExample
 *  AlertPageExample
 *  AlertTypesInlineExample
 *  AlertTypesPageExample
 *  AlertTimeout
 */
var Alert = React.forwardRef(function (props, ref) {
    var _classNames, _classNames3;

    var alertType = props.alertType,
        classes = props.classes,
        userDismissable = props.userDismissable,
        ContentPropsProp = props.ContentProps,
        id = props.id,
        onClose = props.onClose,
        text = props.text,
        variant = props.variant,
        edsContext = props.edsContext,
        spacingOptionsLocal = props.spacingOptions,
        rest = _objectWithoutProperties(props, ['alertType', 'classes', 'userDismissable', 'ContentProps', 'id', 'onClose', 'text', 'variant', 'edsContext', 'spacingOptions']);

    var spacingOptions = getSpacingOptions(spacingOptionsLocal);
    var handleClose = function handleClose(event, reason) {
        if (reason === 'clickaway') {
            return;
        }
        onClose(event);
    };

    // Assign the Icon to render according to the type sent via props.
    var AlertIcon = alertTypeIcon[alertType];

    // Determine alert variant before assigning classes
    var variantClasses = void 0;

    switch (variant) {
        case INLINE_VARIANT:
            variantClasses = classes.inlineTop;
            break;

        case PAGE_VARIANT:
            variantClasses = classes.pageTop;
            break;

        default:
            variantClasses = classes.inlineTop;
            break;
    }

    var snackbarClassOverrides = {
        anchorOriginTopCenter: variantClasses,
        root: classNames(classes.root, classes[alertType], (_classNames = {}, _defineProperty(_classNames, classes.none, spacingOptions.spacing === spacingVariant.NONE), _defineProperty(_classNames, classes[alertType + 'Border'], variant === INLINE_VARIANT), _classNames))
    };

    /**
     * ContentProps will be passed to the SnackbarContent component that MUI internally calls when rendering the Snackbar component
     * We are sending in the stylistic changes and an aria tag for improved screen reader experience.
     */
    var ContentProps = Object.assign({}, ContentPropsProp);
    ContentProps.classes = {
        message: classNames(classes.messageWidth, _defineProperty({}, classes.none, spacingOptions.spacing === spacingVariant.NONE)),
        root: classNames(classes.contentRoot, classes[alertType]),
        action: classes.close
    };
    ContentProps['aria-describedby'] = id;
    ContentProps['className'] = classNames(classes.alert);

    // warn about invalid prop values
    useEffect(function () {

        if (!props.userDismissable && props.variant === PAGE_VARIANT) {
            console.warn('Page-level alerts must be user-dismissable. Ignoring userDismissable prop.');
        }

        if (!props.userDismissable && props.onClose) {
            console.warn('The onClose callback is never invoked for non-user-dismissable alerts. Ignoring onClose prop.');
        }
    }, [props.userDismissable, props.variant, props.onClose]);

    return React.createElement(MuiSnackbar, Object.assign({}, rest, {
        ref: ref,
        anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
        },
        classes: snackbarClassOverrides,
        ContentProps: ContentProps,
        key: id + '_' + alertType + '_' + text,
        message: React.createElement(
            'div',
            { id: id, className: classes.message },
            React.createElement(
                'div',
                { className: classes.alertIcon },
                React.createElement(AlertIcon, { className: classes.iconSvg })
            ),
            React.createElement(
                'div',
                {
                    className: classNames((_classNames3 = {}, _defineProperty(_classNames3, classes.inlineAlertMessageText, variant === INLINE_VARIANT), _defineProperty(_classNames3, classes.pageAlertMessageText, variant === PAGE_VARIANT), _classNames3))
                },
                text
            ),
            (userDismissable || variant === PAGE_VARIANT) && React.createElement(
                Tooltip,
                { title: edsContext.formatMessage('component.Alert.close'), enterDelay: 1000, enterNextDelay: 1000 },
                React.createElement(
                    IconButton,
                    {
                        'aria-label': edsContext.formatMessage('component.Alert.close'),
                        className: classes.closeIcon,
                        color: 'inherit',
                        disableRipple: true,
                        onClick: handleClose
                    },
                    React.createElement(CloseIcon, { className: classes.iconSvg })
                )
            )
        )

        // only invoke onClose handler for user-dismissable alerts
        , onClose: userDismissable ? handleClose : null
    }));
});

Alert.muiName = 'Alert';

Alert.propTypes = {
    /**
     * Style type of the Alert to be displayed.
     */
    alertType: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired,
    /**
     * The number of milliseconds to wait before automatically calling the
     * `onClose` function. `onClose` should then set the state of the `open`
     * prop to hide the Alert. This behavior is disabled by default with
     * the `null` value.
     */
    autoHideDuration: PropTypes.number,
    /**
     * If you wish the take control over the children of the component you can use this property.
     * When used, you replace the `SnackbarContent` component with the children.
     */
    children: PropTypes.element,
    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object,
    /**
     * Class name to be applied to the Alert container.
     */
    className: PropTypes.string,
    /**
     * Properties applied to the `SnackbarContent` element.
     */
    ContentProps: PropTypes.object,
    /**
     * If `true`, the `autoHideDuration` timer will expire even if the window is not focused.
     */
    disableWindowBlurListener: PropTypes.bool,
    /**
     * id of the text on the Alert. aria-describedby="message-id" is set automatically to link to this id.
     */
    id: PropTypes.string,
    /**
     * @ignore
     */
    edsContext: PropTypes.object,
    /**
     * When displaying multiple consecutive `Snackbars` from a parent rendering a single
     * `Snackbar`, add the `key` property to ensure independent treatment of each message.
     * e.g. `<Snackbar key={message} />`, otherwise, the message may update-in-place and
     * features such as `autoHideDuration` may be canceled.
     */
    key: PropTypes.any,
    /**
     * Callback fired when the component requests to be closed.
     * Typically `onClose` is used to set state in the parent component,
     * which is used to control the `Alert` `open` prop.
     *
     * The `reason` parameter can optionally be used to control the response to `onClose`.
     *
     * This prop is only pertinent for user-dismissable alerts.
     *
     * @param {object} event The event source of the callback
     * @param {string} reason Can be:`"timeout"` (`autoHideDuration` expired)
     */
    onClose: PropTypes.func,
    /**
     * Callback fired before the transition is entering.
     */
    onEnter: PropTypes.func,
    /**
     * Callback fired when the transition has entered.
     */
    onEntered: PropTypes.func,
    /**
     * Callback fired when the transition is entering.
     */
    onEntering: PropTypes.func,
    /**
     * Callback fired before the transition is exiting.
     */
    onExit: PropTypes.func,
    /**
     * Callback fired when the transition has exited.
     */
    onExited: PropTypes.func,
    /**
     * Callback fired when the transition is exiting.
     */
    onExiting: PropTypes.func,
    /**
     * Callback fired when the mouse enters the component.
     */
    onMouseEnter: PropTypes.func,
    /**
     * Callback fired when the mouse leaves the component.
     */
    onMouseLeave: PropTypes.func,
    /**
     * If true, `Alert` is open.
     */
    open: PropTypes.bool,
    /**
     * The number of milliseconds to wait before dismissing after user interaction.
     * If `autoHideDuration` property isn't specified, it does nothing.
     * If `autoHideDuration` property is specified but `resumeHideDuration` isn't,
     * we default to `autoHideDuration / 2` ms.
     */
    resumeHideDuration: PropTypes.number,
    /**
     * Text to be displayed on the Alert
     */
    text: PropTypes.string,
    /**
     * Transition component.
     */
    TransitionComponent: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object]),
    /**
     * The duration for the transition, in milliseconds.
     * You may specify a single timeout for all transitions, or individually with an object.
     */
    transitionDuration: PropTypes.oneOfType([PropTypes.number, PropTypes.shape({ enter: PropTypes.number, exit: PropTypes.number })]),
    /**
     * Properties applied to the `Transition` element.
     */
    TransitionProps: PropTypes.object,
    /**
     * Whether the user can dismiss the Alert. If this is `false`, the close icon
     * will not appear in the alert, and the `onClose` handler will never be invoked.
     *
     * **Note**: Page alerts are _always_ user-dismissable. This prop is ignored for the `page` variant.
     */
    userDismissable: PropTypes.bool,
    /**
     * Style variant of the Alert to be displayed.
     */
    variant: PropTypes.oneOf(['inline', 'page']).isRequired,
    /**
     * Override global spacing options.
     * Available options are:
     * * `spacing: 'standard' | 'none'`
     *      * Controls the padding around the `Alert`.
     */
    spacingOptions: PropTypes.exact({
        spacing: PropTypes.string
    })
};

Alert.displayName = 'Alert';

Alert.defaultProps = {
    alertType: 'info',
    autoHideDuration: null,
    variant: INLINE_VARIANT,
    userDismissable: true,
    spacingOptions: { spacing: spacingVariant.STANDARD }
};

export default withEDSContext(withStyles(styles)(Alert));