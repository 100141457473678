function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { default as withStyles } from '../styles';
import classNames from 'classnames';
import { fontFamilyDefault, fontSizeDefault, lineHeightDefault, borderRadiusSmall, borderWidthThick, colorBorderGlobalFocus, spacing10 } from '../styles/tokens';
import isEmpty from 'lodash/isEmpty';

var styles = function styles(theme) {
    return {
        link: {
            color: theme.palette.action.base, // cta blue 600
            textDecoration: 'underline',
            padding: '0 ' + spacing10,
            '&:hover': {
                color: theme.palette.action.hover, // cta blue 700
                textDecoration: 'underline',
                cursor: 'pointer'
            },
            '&:active': {
                color: theme.palette.action.active, // cta blue 800s
                textDecoration: 'underline'
            },
            '&:focus': {
                outline: 'none',
                textDecoration: 'underline',
                borderRadius: borderRadiusSmall,
                boxShadow: '0 0 0 ' + borderWidthThick + ' ' + colorBorderGlobalFocus
            }
        },
        disabled: {
            fontFamily: 'inherit',
            fontSize: 'inherit',
            fontWeight: 'inherit',
            textDecoration: 'underline',
            color: theme.palette.action.disabled
        },
        standalone: {
            fontFamily: fontFamilyDefault,
            fontSize: fontSizeDefault,
            lineHeight: lineHeightDefault,
            wordSpacing: '0.16em',
            letterSpacing: 'unset'
        }
    };
};

/**
 * Use `TextLink` to take the user to a new page.
 * @done true
 * @updated false
 * @versionAdded v0.0.10
 * @examples
 *  TextLinkExample
 *  CustomComponent
 *  TextLinkTarget
 */
var TextLink = React.forwardRef(function (props, ref) {
    var _classNames;

    var classes = props.classes,
        className = props.className,
        Component = props.component,
        disabled = props.disabled,
        href = props.href,
        variant = props.variant,
        rest = _objectWithoutProperties(props, ['classes', 'className', 'component', 'disabled', 'href', 'variant']);

    var additionalProps = disabled ? { 'aria-disabled': 'true' } : { href: href, ref: ref };

    var classOverrides = {
        stateStyle: classNames((_classNames = {}, _defineProperty(_classNames, classes.disabled, disabled), _defineProperty(_classNames, classes.link, !disabled), _defineProperty(_classNames, classes.standalone, variant === 'standalone'), _classNames), className)
    };

    useEffect(function () {
        if (props.component === 'a' && isEmpty(href)) {
            console.warn('href prop is required for TextLink component.');
        }
    }, [props.component, href]);

    return React.createElement(Component, Object.assign({
        className: classOverrides.stateStyle
    }, rest, additionalProps));
});

TextLink.muiName = 'TextLink';
TextLink.displayName = 'TextLink';

TextLink.propTypes = {
    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object,
    /**
     * Custom className for additional styling
     */
    className: PropTypes.string,
    /**
     * The component used for the root node. We recommend using a component or element
     * that supports the `href` prop.
     */
    component: PropTypes.elementType,
    /**
     * Set to `true` to disable the link
     */
    disabled: PropTypes.bool,
    /**
     * The content of the `TextLink`.
     */
    children: PropTypes.node.isRequired,
    /**
     * The redirect URL. This prop is required if replacement `component` prop is not specified.
     */
    href: PropTypes.string,
    /**
     * **DEPRECATED**
     *
     * Style variant of the TextLink to be displayed. **Only applicable for text nodes**.
     * * `inherit` will inherit font weight, font size, and font family from surrounding text.
     * * `standalone` is to be used for a `TextLink` that is not surrounded by other text.
     */
    variant: PropTypes.oneOf(['standalone', 'inherit']),
    /**
     * Where to display the linked URL, as the name for a browsing context (a tab, window, or `<iframe>`).
     */
    target: PropTypes.string
};

TextLink.defaultProps = {
    component: 'a',
    disabled: false,
    variant: 'standalone'
};

export default withStyles(styles)(TextLink);