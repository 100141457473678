import React from 'react';
import PropTypes from 'prop-types';
import { default as MuiTreeItem } from '@material-ui/lab/TreeItem';

import { default as withStyles } from '../../styles';
import { spacing20, spacing40 } from '../../styles/tokens';

var styles = function styles(theme) {
  return {
    root: {
      '&:focus > $content $label': {
        backgroundColor: theme.palette.grey[250],
        color: theme.palette.text.primary
      },
      '&$selected > $content $label': {
        backgroundColor: theme.palette.action.active,
        color: theme.palette.text.white
      },
      '&$selected > $content $label:hover, &$selected:focus > $content $label': {
        backgroundColor: theme.palette.action.hover,
        color: theme.palette.text.white
      }
    },
    expanded: {},
    selected: {},
    group: {},
    content: {},
    iconContainer: {
      marginLeft: spacing40,
      marginRight: spacing20
    },
    label: {
      padding: spacing20,
      paddingRight: spacing40,
      '&:hover': {
        backgroundColor: theme.palette.grey[250],
        color: theme.palette.text.primary
      }
    }
  };
};

// TreeItem code @material-ui/lab@4.0.0-alpha.46:
// https://github.com/mui-org/material-ui/blob/v4.9.6/packages/material-ui-lab/src/TreeItem/TreeItem.js
function TreeItem(props) {
  return React.createElement(MuiTreeItem, props);
}

TreeItem.propTypes = {
  /**
   * The content of the component.
   */
  children: PropTypes.node,
  /**
   * Override or extend the styles applied to the component.
   */
  classes: PropTypes.object,
  /**
   * @ignore
   */
  className: PropTypes.string,
  /**
   * The icon used to collapse the node.
   */
  collapseIcon: PropTypes.node,
  /**
   * The icon displayed next to a end node.
   */
  endIcon: PropTypes.node,
  /**
   * The icon used to expand the node.
   */
  expandIcon: PropTypes.node,
  /**
   * The icon to display next to the tree node's label.
   */
  icon: PropTypes.node,
  /**
   * The tree node label.
   */
  label: PropTypes.node,
  /**
   * The id of the node.
   */
  nodeId: PropTypes.string.isRequired,
  /**
   * @ignore
   */
  onClick: PropTypes.func,
  /**
   * @ignore
   */
  onFocus: PropTypes.func,
  /**
   * @ignore
   */
  onKeyDown: PropTypes.func,
  /**
   * @ignore
   */
  onMouseDown: PropTypes.func,
  /**
   * The component used for the transition.
   */
  TransitionComponent: PropTypes.elementType,
  /**
   * Props applied to the [`Transition`](http://reactcommunity.org/react-transition-group/transition#Transition-props) element.
   */
  TransitionProps: PropTypes.object
};

TreeItem.muiName = 'TreeItem';
TreeItem.displayName = 'TreeItem';

export default withStyles(styles)(TreeItem);