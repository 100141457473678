var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import classnames from 'classnames';
import omit from 'lodash/omit';
import MaskedInput from 'react-text-mask';
import React, { forwardRef } from 'react';
import EdsTextField from '@ellucian/react-design-system/core/es/TextField';
import Tokens from '../styles/Tokens';
import { createStyles, withStyles } from '../styles/withStyles';
import Format from '../../../helpers/Format';
const styles = ((theme) => createStyles({
    adornment: {},
    cell: {
        '& > div > input': {
            width: Tokens.widthFluid
        },
        display: 'table-cell!important'
    },
    cellNumeric: {
        [theme.breakpoints.up('md')]: {
            float: 'right'
        }
    },
    colorField: {
        '& > div > input': {
            height: Tokens.sizingXLarge,
            padding: 0,
            width: Tokens.sizingXLarge
        }
    },
    fileField: {
        '& > div > input': {
            height: Tokens.sizingXLarge,
            padding: 0
        }
    },
    inputMultiline: {},
    numeric: {
        '& > div > input': {
            textAlign: 'right'
        }
    },
    root: {}
}));
const TextMask = (props) => {
    const { inputRef, mask } = props, rest = __rest(props, ["inputRef", "mask"]);
    return (React.createElement(MaskedInput, Object.assign({ mask: mask, keepCharPositions: true, ref: (ref) => {
            inputRef(ref ? ref.inputElement : null);
        } }, rest)));
};
const TextField = (props, ref) => {
    const { InputProps, cell, classes, format, numeric, type, value } = props, rest = __rest(props, ["InputProps", "cell", "classes", "format", "numeric", "type", "value"]);
    const classOverrides = omit(classes, ['cell', 'cellNumeric', 'colorField', 'fileField', 'numeric', 'smallField']);
    if (cell) {
        classOverrides.root = classnames(classOverrides.root, classes.cell);
    }
    if (numeric) {
        classOverrides.root = classnames(classOverrides.root, classes.numeric);
    }
    if (cell && numeric) {
        classOverrides.root = classnames(classOverrides.root, classes.cellNumeric);
    }
    if (type === 'color') {
        classOverrides.root = classnames(classOverrides.root, classes.colorField);
    }
    if (type === 'file') {
        classOverrides.root = classnames(classOverrides.root, classes.fileField);
    }
    const mask = typeof format === 'string' ? Format.toMask(format) : format;
    return (React.createElement(EdsTextField, Object.assign({ classes: classOverrides, inputRef: ref, type: type, InputProps: format ? {
            inputComponent: TextMask,
            inputProps: { mask: mask },
            value: value
        } : InputProps, value: value }, rest)));
};
const TextFieldFRC = forwardRef(TextField);
TextFieldFRC.displayName = 'TextFieldFRC';
TextFieldFRC.defaultProps = {
    cell: false,
    fullWidth: true,
    id: '',
    margin: 'normal',
    numeric: false
};
export default withStyles(styles, { name: 'TextFieldWS' })(TextFieldFRC);
