function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { default as MuiCardHeader } from '@material-ui/core/CardHeader';

import { default as withStyles } from '../styles';
import { CardContext } from './Card';
import Typography from './../Typography';

import getLegacySpacingModeCss from './internal/getLegacySpacingModeCss';

import { spacing60, spacing50, spacing40, spacing30 } from '../styles/tokens';

var styles = function styles(theme) {
    return {
        root: {},
        standard: {
            padding: spacing60 + ' ' + spacing60 + ' ' + spacing40
        },
        dense: {
            padding: spacing50 + ' ' + spacing50 + ' 0.75rem'
        },
        none: {
            padding: 0
        },
        action: {
            marginRight: '-' + spacing30,
            marginTop: '-.375rem',
            '& svg': {
                width: spacing40,
                height: spacing40,
                margin: '.75rem', //  No tokens available, this is one off value for spacing
                color: theme.palette.grey[500],
                fill: theme.palette.grey[500]
            }
        },
        content: {
            alignSelf: 'flex-start'
        },
        responsive: _defineProperty({}, theme.breakpoints.only('xs'), {
            padding: spacing40 + ' ' + spacing40 + ' ' + spacing30
        })
    };
};

/**
 * CardHeader component for displaying header on a Card component
 */
var CardHeader = React.forwardRef(function (props, ref) {
    var classes = props.classes,
        subheader = props.subheader,
        rest = _objectWithoutProperties(props, ['classes', 'subheader']);

    var classesOverrides = getLegacySpacingModeCss(useContext(CardContext), classes);

    // Typography's `body3` variant is exclusive to Path.
    // So subheaderTypographyProps doesn't work here.
    // Had to create a new node.
    var newSubheader = subheader && !props.subheaderTypographyProps ? React.createElement(
        Typography,
        { variant: 'body3',
            component: 'span',
            color: 'textSecondary'
        },
        subheader
    ) : React.createElement(
        React.Fragment,
        null,
        subheader || null
    );

    var classOverrides = {
        root: classNames(classes.root, classesOverrides),
        action: classes.action,
        content: classes.content
    };

    return React.createElement(MuiCardHeader, Object.assign({
        classes: classOverrides,
        titleTypographyProps: {
            variant: 'h3'
        },
        subheader: newSubheader
    }, rest, {
        ref: ref
    }));
});

CardHeader.muiName = 'CardHeader';
CardHeader.displayName = 'CardHeader';

CardHeader.propTypes = {
    /**
     * The action to display in the card header.
     */
    action: PropTypes.node,
    /**
     * The Avatar for the Card Header.
     */
    avatar: PropTypes.node,
    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object,
    /**
     * @ignore
     */
    className: PropTypes.string,
    /**
     * The component used for the root node.
     * Either a string to use a DOM element or a component.
     */
    component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    /**
     * If true, the children won't be wrapped by a Typography component.
     * This can be useful to render an alternative Typography variant by
     * wrapping the title text, and optional subheader text with the
     * Typography component.
     */
    disableTypography: PropTypes.bool,
    /**
     * The content of the Card Title.
     */
    title: PropTypes.node,
    /**
     * These props will be forwarded to the title (as
     * long as disableTypography is not `true`).
     */
    titleTypographyProps: PropTypes.object,
    /**
     * The content of the subheader.
     */
    subheader: PropTypes.node,
    /**
     * These props will be forwarded to the subheader (as
     * long as disableTypography is not true).
     */
    subheaderTypographyProps: PropTypes.object
};

// If default props are needed, they must be set here
CardHeader.defaultProps = {
    component: 'div',
    disableTypography: false
};

export default withStyles(styles)(CardHeader);