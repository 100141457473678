function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import withStyles from '../styles';
import Typography from '../Typography';
import { spacing30 } from '../styles/tokens';
import { MOBILE_STEPPER_LABEL_CLASSNAME } from './internal/constants';

var styles = function styles(theme) {
    return {
        currentStepTitle: {
            order: 0,
            marginLeft: spacing30,
            flex: '1 1 auto',
            alignSelf: 'auto'
        }
    };
};

/**
 * A component that displays the current step of the total steps
 */
var MobileStepperLabel = React.forwardRef(function (props, ref) {
    var classes = props.classes,
        rest = _objectWithoutProperties(props, ['classes']);

    return (
        /* These marker class names will be used by its parent component MobileStepper */
        React.createElement(
            Typography,
            Object.assign({ variant: 'h3',
                className: classnames(classes.currentStepTitle, MOBILE_STEPPER_LABEL_CLASSNAME),
                component: 'span'
            }, rest, {
                ref: ref
            }),
            props.children
        )
    );
});

MobileStepperLabel.propTypes = {
    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object,
    /**
     * The step title component.
     */
    children: PropTypes.node,
    /**
     * Props will be passed into the rendered `<Typography>` component.
     */
    props: PropTypes.object
};

MobileStepperLabel.displayName = 'MobileStepperLabel';

export default withStyles(styles)(MobileStepperLabel);