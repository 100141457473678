import AppDispatcher from '../AppDispatcher';
import { PasswordConfirmationStatus } from '../../types/PasswordConfirmation/PasswordConfirmationStatus';
const PasswordConfirmationActions = {
    setPasswordPolicy(passwordPolicy) {
        AppDispatcher.dispatch({
            payload: {
                passwordPolicy
            },
            type: PasswordConfirmationStatus.SuccessSetPasswordPolicy
        });
    },
    setPasswordValidation(password, accountValidations) {
        AppDispatcher.dispatch({
            payload: {
                password,
                accountValidations
            },
            type: PasswordConfirmationStatus.SuccessSetPasswordValidation
        });
    }
};
export default PasswordConfirmationActions;
