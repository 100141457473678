function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import React from 'react';
import classNames from 'classnames';
import { default as MuiBadge } from '@material-ui/core/Badge';
import { default as withStyles } from '../styles';
import PropTypes from 'prop-types';
import { borderWidthThin, spacing20, borderRadiusLarge } from '../styles/tokens';

var styles = function styles(theme) {
    return {
        badge: {
            color: theme.palette.grey[200],
            background: theme.palette.status.error.fill,
            top: '-0.02rem',
            right: '0.05rem',
            width: 'auto',
            fontSize: theme.typography.caption.fontSize,
            borderRadius: borderRadiusLarge,
            padding: '0 ' + spacing20,
            border: borderWidthThin + ' solid ' + theme.palette.grey[200]
        },
        noBadgeContent: {
            '& $badge': {
                height: '0.625rem',
                width: '0.625rem',
                display: 'inline-block',
                minWidth: 'auto',
                padding: 0
            }
        }
    };
};

/**
 * Use a `NotificationBadge` to indicate a new notification, change, or update.
 */
var NotificationBadge = React.forwardRef(function (props, ref) {
    var classes = props.classes,
        badgeContent = props.badgeContent,
        maxvalue = props.maxvalue,
        rest = _objectWithoutProperties(props, ['classes', 'badgeContent', 'maxvalue']);

    var classOverrides = {
        badge: classes.badge
    };

    return React.createElement(MuiBadge, Object.assign({}, rest, {
        badgeContent: badgeContent || ' ',
        classes: classOverrides,
        invisible: false,
        max: maxvalue,
        className: classNames(rest.className, _defineProperty({}, classes.noBadgeContent, !badgeContent)),
        ref: ref
    }));
});

NotificationBadge.muiName = 'NotificationBadge';
NotificationBadge.displayName = 'NotificationBadge';

NotificationBadge.propTypes = {
    /**
     * The content rendered within the badge.
     */
    badgeContent: PropTypes.number,
    /**
     * The badge will be added relative to this node.
     */
    children: PropTypes.node.isRequired,
    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object,
    /**
     * Max value of content rendered within the badge.
     */
    maxvalue: PropTypes.number
};

NotificationBadge.defaultProps = {
    maxvalue: 999
};

export default withStyles(styles)(NotificationBadge);