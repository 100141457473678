function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import React from 'react';
import { Tab as MuiTab } from '@material-ui/core';
import { default as withStyles } from '../styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { spacing40, fontWeightNormal, boxShadowFocusInset, spacing20, lineHeightDefault } from '../styles/tokens';

var styles = function styles(theme) {
    var _tabRoot, _cardLevelTabRoot;

    return {
        tabRoot: (_tabRoot = {
            display: 'inline-block',
            fontFamily: theme.typography.fontFamily,
            fontWeight: fontWeightNormal,
            fontSize: theme.typography.body1.fontSize,
            letterSpacing: 'unset',
            textTransform: 'none',
            minWidth: 'auto',
            padding: '0px ' + spacing20,
            margin: '0 ' + spacing40,
            lineHeight: '' + lineHeightDefault
        }, _defineProperty(_tabRoot, theme.breakpoints.up('md'), {
            minWidth: 'auto'
        }), _defineProperty(_tabRoot, '&:hover', {
            color: theme.palette.ctaColor.hover
        }), _defineProperty(_tabRoot, '&:focus', {
            boxShadow: boxShadowFocusInset,
            color: theme.palette.ctaColor.active
        }), _defineProperty(_tabRoot, '&$disabled', {
            color: theme.palette.text.disabled
        }), _tabRoot),
        cardLevelTabRoot: (_cardLevelTabRoot = {
            fontWeight: fontWeightNormal,
            display: 'inline-block',
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.typography.body1.fontSize,
            letterSpacing: 'unset',
            textTransform: 'none',
            minWidth: 'auto',
            padding: '0px ' + spacing40
        }, _defineProperty(_cardLevelTabRoot, theme.breakpoints.up('md'), {
            minWidth: 'auto'
        }), _defineProperty(_cardLevelTabRoot, '&:hover', {
            color: theme.palette.ctaColor.hover
        }), _defineProperty(_cardLevelTabRoot, '&:focus', {
            boxShadow: boxShadowFocusInset,
            color: theme.palette.ctaColor.active
        }), _defineProperty(_cardLevelTabRoot, '&$disabled', {
            color: theme.palette.text.disabled
        }), _cardLevelTabRoot),
        selected: {
            color: theme.palette.ctaColor.base
        },

        // Inherit styles
        textColorInherit: {
            color: theme.palette.text.secondary,
            opacity: '1',
            '&$selected': {
                color: theme.palette.ctaColor.base
            },
            '&$disabled': {
                opacity: 1,
                color: theme.palette.grey[400]
            }
        },
        // Primary styles
        textColorPrimary: {
            color: theme.palette.text.secondary,
            opacity: '1',
            '&$selected': {
                color: theme.palette.ctaColor.base
            },
            '&$disabled': {
                opacity: '1'
            }
        },
        // Secondary styles
        textColorSecondary: {
            color: theme.palette.text.secondary,
            opacity: '1',
            '&$selected': {
                color: theme.palette.ctaColor.base
            },
            '&$disabled': {
                opacity: '1',
                '&:hover': {
                    color: theme.palette.grey[400]
                }
            }
        },
        // label: {
        //     fontFamily: theme.typography.fontFamily,
        //     fontWeight: theme.typography.button.fontWeight,
        //     fontSize: theme.typography.body1.fontSize,
        // },
        // labelContainer: {
        //     width: '100%', // Fix an IE 11 issue
        //     boxSizing: 'border-box',
        //     padding: `0 ${spacing20}`,
        // },
        cardLevel: {
            border: '1px solid transparent', // Fix text/tab shift with border
            '&$selected': {
                borderColor: theme.palette.grey[300] + ' ' + theme.palette.grey[300] + ' #fff',
                borderTopRightRadius: 4,
                borderTopLeftRadius: 4
            }
        },
        disabled: {
            opacity: '1',
            color: theme.palette.grey[400]
        }
    };
};

var Tab = React.forwardRef(function (props, ref) {
    var _classNames;

    var classes = props.classes,
        classNameProp = props.className,
        variant = props.variant,
        rest = _objectWithoutProperties(props, ['classes', 'className', 'variant']);

    return React.createElement(MuiTab, Object.assign({}, rest, {
        classes: {
            root: variant === 'card' ? classes.cardLevelTabRoot : classes.tabRoot,
            selected: classes.selected,
            textColorInherit: classes.textColorInherit,
            textColorPrimary: classes.textColorPrimary,
            textColorSecondary: classes.textColorSecondary,
            // label: classes.label,
            // labelContainer: classes.labelContainer,
            disabled: classes.disabled
        },
        className: classNames((_classNames = {}, _defineProperty(_classNames, classes.cardLevel, variant === 'card'), _defineProperty(_classNames, 'classNameProp', classNameProp), _classNames)),
        disableRipple: true,
        ref: ref,
        'data-text': props.label
    }));
});

Tab.muiName = 'Tab';
Tab.displayName = 'Tab';

Tab.propTypes = {
    /**
     * Useful to extend the style applied to components.
     */
    classes: PropTypes.object,
    /**
     * @ignore
     */
    className: PropTypes.string,
    /**
     * If `true`, the tab will be disabled.
     */
    disabled: PropTypes.bool,
    /**
     * @ignore
     */
    fullWidth: PropTypes.bool,
    /**
     * @ignore
     * For server side rendering consideration, we let the selected tab
     * render the indicator.
     */
    indicator: PropTypes.node,
    /**
     * The label element.
     */
    label: PropTypes.node,
    /**
     * @ignore
     */
    onChange: PropTypes.func,
    /**
     * @ignore
     */
    onClick: PropTypes.func,
    /**
     * @ignore
     */
    selected: PropTypes.bool,
    /**
     * @ignore
     */
    textColor: PropTypes.oneOf(['secondary', 'primary', 'inherit']),
    /**
     * You can provide your own value. Otherwise, we fallback to the child position index.
     */
    value: PropTypes.any,
    /**
     * @ignore
     * Internal value to check if the Tab should have Card Level styling.
     */
    variant: PropTypes.oneOf(['page', 'card'])
};

export default withStyles(styles)(Tab);