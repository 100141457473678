function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import React from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid/v4';
import classNames from 'classnames';

import { default as withStyles } from '../styles';
import Paper from '../Paper';
import Typography from '../Typography';
import { spacingVariant } from '../globalSpacing';
import { spacing40, spacingInset30, spacingInset40 } from '../styles/tokens';

var styles = function styles(theme) {
    var _$responsive;

    return {
        more: {
            paddingTop: spacing40
        },
        root: {
            flexDirection: 'column',
            padding: spacingInset40,
            '&$none': {
                padding: 0
            },
            '&$responsive': (_$responsive = {}, _defineProperty(_$responsive, theme.breakpoints.up('md'), {
                padding: spacingInset40,
                '&$none': {
                    padding: 0
                }
            }), _defineProperty(_$responsive, theme.breakpoints.down('sm'), {
                padding: spacingInset30,
                '&$none': {
                    padding: 0
                }
            }), _$responsive)
        },
        none: {},
        responsive: {}
    };
};

/**
 * Use `Footer` to display copyright information.
 * @done true
 * @updated false
 * @versionAdded v0.0.7
 * @examples
 *  FooterExample
 */
var Footer = React.forwardRef(function (props, ref) {
    var _classNames;

    var classes = props.classes,
        footerText = props.footerText,
        spacingOptions = props.spacingOptions,
        rest = _objectWithoutProperties(props, ['classes', 'footerText', 'spacingOptions']);

    var none = classes.none,
        responsive = classes.responsive,
        more = classes.more;

    var firstLine = Array.isArray(footerText) ? footerText.shift() : footerText;

    var classOverrides = {
        root: classNames(classes.root, (_classNames = {}, _defineProperty(_classNames, none, spacingOptions.spacing === spacingVariant.NONE), _defineProperty(_classNames, responsive, spacingOptions.responsive), _classNames))
    };

    return React.createElement(
        Paper,
        Object.assign({
            square: true,
            component: 'footer',
            elevation: 0,
            classes: classOverrides
        }, rest, {
            ref: ref
        }),
        React.createElement(
            Typography,
            { variant: 'body2', align: 'center', color: 'textSecondary' },
            firstLine
        ),
        Array.isArray(footerText) && footerText.length > 0 && footerText.map(function (text) {
            return React.createElement(
                Typography,
                { classes: { root: more }, variant: 'body2', align: 'center', key: uuid(), color: 'textSecondary' },
                text
            );
        })
    );
});

Footer.displayName = 'Footer';

Footer.propTypes = {
    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object,
    /**
     * The text that the Footer will display. For an array of strings, each element will be displayed as a new paragraph.
     */
    footerText: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
    /**
     * Override global spacing options.
     * Available options are:
     * * `spacing: 'standard' | 'none'`
     *      * Controls the padding around the `Footer`.
     * * `responsive: boolean`
     *      * Controls whether the spacing is responsive.
     */
    spacingOptions: PropTypes.exact({
        spacing: PropTypes.oneOf([spacingVariant.STANDARD, spacingVariant.NONE]),
        responsive: PropTypes.bool
    })
};

Footer.defaultProps = {
    spacingOptions: {
        spacing: spacingVariant.STANDARD,
        responsive: true
    }
};

export default withStyles(styles)(Footer);