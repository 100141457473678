import React from 'react';
import { clearInterval, clearTimeout, setInterval, setTimeout } from 'timers';
import { IconSprite as EdsIconSprite } from '@ellucian/ds-icons/lib';
import createEDSTheme from '../../core/themes/createEDSTheme';
import Alert from '../../core/Alert';
import Application from '../../core/Application';
import Button from '../../core/Button';
import Grid from '../../core/Grid';
import Icon from '../../core/Icon';
import IconButton from '../../core/IconButton';
import Modal from '../../core/Modal';
import PageLoader from '../../core/PageLoader';
import Snackbar from '../../core/Snackbar';
import Text from '../../core/Text';
import Tooltip from '../../core/Tooltip';
import ContentPage from '../ContentPage';
import NavBar from '../NavBar';
import Tokens from '../../core/styles/Tokens';
import { CtaColors } from '../../core/styles/Tokens';
import { createStyles, withStyles } from '../../core/styles/withStyles';
import { LogType } from '../../../types/LogType';
import { ResultType } from '../../../types/ResultType';
import Constants from '../../../helpers/Constants';
import LogData from '../../../helpers/LogData';
import Format from '../../../helpers/Format';
import Redirect from '../../../helpers/Redirect';
import Resolver from '../../../helpers/Resolver';
import ThemeHelper from '../../../helpers/ThemeHelper';
import Requests from '../../../requests/LayoutRequests';
import Actions from '../../../flux/actions/LayoutActions';
import Store from '../../../flux/stores/LayoutStore';
const styles = createStyles({
    privacyLink: {
        color: Tokens.colorBrandCtaBase,
        flexBasis: '100%',
        fontFamily: Tokens.fontFamilyDefault,
        fontSize: Tokens.fontSizeDefault,
        textAlign: 'center',
        textDecoration: 'underline',
        padding: `0 ${Tokens.spacing10}`,
        '&:hover': {
            color: Tokens.colorBrandCtaHover,
            textDecoration: 'underline',
            cursor: 'pointer'
        },
        '&:active': {
            color: Tokens.colorBrandCtaActive,
            textDecoration: 'underline'
        },
        '&:focus': {
            outline: 'none',
            textDecoration: 'underline',
            borderRadius: Tokens.borderRadiusSmall,
            boxShadow: `0 0 0 ${Tokens.borderWidthThick} ${Tokens.colorBorderGlobalFocus}`
        }
    }
});
class Layout extends React.Component {
    constructor(props) {
        super(props);
        this.onCloseTimeoutNotification = () => {
            try {
                this.setState({
                    openTimeoutNotification: false
                });
            }
            catch (e) {
                this.logError(LogData.fromException(this.onCloseTimeoutNotification.name, e));
            }
        };
        this.onExtendTimeoutNotification = () => {
            try {
                this.clearTimeoutSession();
                this.setState({
                    isLoadingTimeoutNotification: true
                });
                Requests.getIsAuthenticated(this.resolveGetIsAuthenticated);
            }
            catch (e) {
                this.logError(LogData.fromException(this.onExtendTimeoutNotification.name, e));
            }
        };
        this.setMegaMenuSuffix = (element) => {
            if (element.id && !element.id.endsWith('_megamenu')) {
                element.id = `${element.id}_megamenu`;
            }
        };
        this.setMegaMenuIds = () => {
            try {
                const megaMenuElementsFirstLevel = document.querySelectorAll('.megamenu-section span.megamenu-option-first-level');
                if (megaMenuElementsFirstLevel) {
                    megaMenuElementsFirstLevel.forEach(this.setMegaMenuSuffix);
                }
                const megaMenuElementsSecondLevel = document.querySelectorAll('.megamenu-section span.megamenu-option-second-level');
                if (megaMenuElementsSecondLevel) {
                    megaMenuElementsSecondLevel.forEach(this.setMegaMenuSuffix);
                }
                this.setMegaMenuIdsMobile();
            }
            catch (e) {
                this.logError(LogData.fromException(this.setMegaMenuIds.name, e));
            }
        };
        this.setMegaMenuIdsMobile = () => {
            try {
                const megaMenuElementsMobileFirstLevel = document.querySelectorAll('.megamenu-menu-list span.megamenu-option-first-level');
                if (megaMenuElementsMobileFirstLevel) {
                    megaMenuElementsMobileFirstLevel.forEach(this.setMegaMenuSuffix);
                }
                const megaMenuElementsMobileSecondLevel = document.querySelectorAll('.megamenu-menu-list span.megamenu-option-first-level');
                if (megaMenuElementsMobileSecondLevel) {
                    megaMenuElementsMobileSecondLevel.forEach(this.setMegaMenuSuffix);
                }
            }
            catch (e) {
                this.logError(LogData.fromException(this.setMegaMenuIds.name, e));
            }
        };
        this.setPrivacyPolicy = () => {
            try {
                const { classes } = this.props;
                const { cultures } = this.state;
                let privacyLink = document.getElementById('lnkEllucianPrivacyPolicy');
                if (!privacyLink) {
                    let megamenuFooter;
                    megamenuFooter = document.querySelector('.megamenu-footer');
                    if (megamenuFooter) {
                        if (megamenuFooter.innerHTML.startsWith('<div')) {
                            megamenuFooter = document.querySelector('.megamenu-footer > div');
                        }
                        if (megamenuFooter) {
                            privacyLink = document.createElement('a');
                            privacyLink.id = 'lnkEllucianPrivacyPolicy';
                            privacyLink.href = 'https://www.ellucian.com/privacy';
                            privacyLink.innerText = 'Privacy Notice';
                            if (cultures === null || cultures === void 0 ? void 0 : cultures.uiCulture) {
                                if (cultures.uiCulture.startsWith('es')) {
                                    privacyLink.href = 'https://www.ellucian.com/es/politica-de-privacidad';
                                    privacyLink.innerText = 'Aviso de Privacidad';
                                }
                            }
                            privacyLink.target = '_blank';
                            privacyLink.className = classes.privacyLink;
                            megamenuFooter.appendChild(privacyLink);
                            megamenuFooter.style.justifyContent = 'center';
                            megamenuFooter.style.flexWrap = 'wrap';
                        }
                    }
                }
            }
            catch (e) {
                this.logError(LogData.fromException(this.setPrivacyPolicy.name, e));
            }
        };
        this.callbackObserver = (mutationRecord) => {
            if ((mutationRecord === null || mutationRecord === void 0 ? void 0 : mutationRecord.length) > 0) {
                const mutationType = mutationRecord.find(m => m.attributeName === 'style');
                if (mutationType) {
                    this.setState({
                        overModal: Boolean(mutationType.target.style) && mutationType.target.style.cssText === 'overflow: hidden;'
                    });
                }
            }
        };
        this.clearTimeoutSessionCounter = () => {
            try {
                if (this.timeoutSessionCounter !== undefined) {
                    this.timeoutSessionCounter.unref();
                    clearInterval(this.timeoutSessionCounter);
                    this.timeoutSessionCounter = undefined;
                }
            }
            catch (e) {
                this.logError(LogData.fromException(this.clearTimeoutSessionCounter.name, e));
            }
        };
        this.clearTimeoutNotification = () => {
            try {
                if (this.timeoutNotification !== undefined) {
                    this.timeoutNotification.unref();
                    clearTimeout(this.timeoutNotification);
                    this.timeoutNotification = undefined;
                }
            }
            catch (e) {
                this.logError(LogData.fromException(this.clearTimeoutNotification.name, e));
            }
        };
        this.clearTimeoutSession = () => {
            try {
                if (this.timeoutSession !== undefined) {
                    this.timeoutSession.unref();
                    clearTimeout(this.timeoutSession);
                    this.timeoutSession = undefined;
                }
            }
            catch (e) {
                this.logError(LogData.fromException(this.clearTimeoutSession.name, e));
            }
        };
        this.countTimeoutSessionCounter = () => {
            try {
                const { sessionCount } = this.state;
                if (sessionCount > 0) {
                    this.setState({
                        sessionCount: sessionCount - 1
                    });
                }
                else {
                    this.clearTimeoutSessionCounter();
                }
            }
            catch (e) {
                this.logError(LogData.fromException(this.countTimeoutSessionCounter.name, e));
            }
        };
        this.displayNotificationTimeout = () => {
            try {
                this.setState({
                    sessionCount: this.notificationTimeout
                }, () => {
                    this.setState({
                        openTimeoutNotification: true
                    });
                });
                this.clearTimeoutSession();
                const timeout = Store.getTimeout();
                if (timeout >= 0) {
                    this.timeoutSession = setTimeout(this.redirectToLogout, (this.notificationTimeout * 1000) + 1000);
                }
                this.timeoutSessionCounter = setInterval(this.countTimeoutSessionCounter, 1000);
            }
            catch (e) {
                this.logError(LogData.fromException(this.displayNotificationTimeout.name, e));
            }
        };
        this.redirectToLogout = () => {
            if (this.isADFS) {
                Redirect.refreshPage();
            }
            else if (this.isSAML) {
                Redirect.toSAMLLogin();
            }
            else {
                Redirect.toLogoutWithReturnUrl();
            }
        };
        this.resolveGetInitialLoadData = (json) => {
            try {
                const result = Resolver(json, this.resolveGetInitialLoadData.name);
                if (result === null || result === void 0 ? void 0 : result.status) {
                    const { idMainModule, idMainPage, idModule, idPage, showCart } = this.props;
                    const isAuthenticated = result.data.isAuthenticated;
                    if (isAuthenticated) {
                        if (showCart) {
                            Actions.getCountCart();
                        }
                        const sessionTimeOut = result.data.sessionTimeout !== undefined ? result.data.sessionTimeout : -1;
                        const tokenTimeout = result.data.tokenTimeout !== undefined ? result.data.tokenTimeout : -1;
                        this.isADFS = Boolean(result.data.isADFS);
                        this.isSAML = Boolean(result.data.isSAML);
                        this.notificationTimeout = result.data.notificationTimeout || 0;
                        Actions.setTimeout(sessionTimeOut, tokenTimeout, this.props.sessionTimeoutType, this.props.tokenTimeoutType);
                    }
                    const displayName = result.data.displayName;
                    const layoutPermissions = result.data.layoutPermissions;
                    let theme = result.data.theme;
                    const cultures = result.data.cultures;
                    const menu = result.data.options;
                    const resources = result.data.resources;
                    const layoutResources = result.data.layoutResources;
                    if ((resources === null || resources === void 0 ? void 0 : resources.lblPageTitle) && (layoutResources === null || layoutResources === void 0 ? void 0 : layoutResources.lblPageTitleGeneral) && layoutResources.lblPageTitleGeneral) {
                        resources.lblPageTitle = `${layoutResources.lblPageTitleGeneral}${resources.lblPageTitle}`;
                    }
                    if (menu) {
                        const moduleSelected = idMainModule ? `${idMainModule}Id` : `${idModule}Id`;
                        const pageSelected = idMainPage ? `${idMainPage}Id` : `${idPage}Id`;
                        menu.forEach(item => {
                            if (item.id === moduleSelected) {
                                if (item.options) {
                                    item.options.forEach(suboption => {
                                        if (suboption.id === pageSelected) {
                                            if (resources) {
                                                if (!resources.lblPageTitle) {
                                                    if (layoutResources === null || layoutResources === void 0 ? void 0 : layoutResources.lblPageTitleGeneral) {
                                                        resources.lblPageTitle = `${layoutResources.lblPageTitleGeneral}${suboption.text}`;
                                                    }
                                                    else {
                                                        resources.lblPageTitle = suboption.text;
                                                    }
                                                }
                                                if (!resources.lblHeaderTitle) {
                                                    resources.lblHeaderTitle = suboption.text;
                                                }
                                            }
                                        }
                                    });
                                }
                            }
                        });
                    }
                    Store.setAuthMode(result.data.authMode);
                    Store.setHasProfile(result.data.hasProfile);
                    Store.setIsAuthenticated(isAuthenticated);
                    Store.setMenuOptions(menu);
                    Store.setPermissions(result.data.permissions);
                    Store.setResources(result.data.resources);
                    Store.setResourcesLayout(layoutResources);
                    if (Boolean(cultures)) {
                        Store.setCultures(cultures);
                        this.setHTMLAttributes(cultures);
                    }
                    else {
                        this.setHTMLAttributes(this.state.cultures);
                    }
                    Store.setTheme(theme);
                    if (!Boolean(theme)) {
                        theme = ThemeHelper.getDefaultEmpty();
                    }
                    const newUITheme = this.getUITheme(theme);
                    let componentTranslations;
                    if (layoutResources === null || layoutResources === void 0 ? void 0 : layoutResources.component) {
                        const componentKeys = Object.keys(layoutResources.component);
                        if (componentKeys.length > 0) {
                            componentTranslations = {};
                            let elementKeys;
                            let componentName;
                            let propName;
                            for (const componentKey of componentKeys) {
                                elementKeys = Object.keys(layoutResources.component[componentKey]);
                                for (const elementKey of elementKeys) {
                                    componentName = `${componentKey[0].toUpperCase()}${componentKey.substr(1, componentKey.length - 1)}`;
                                    propName = `component.${componentName}.${elementKey}`;
                                    componentTranslations[propName] = layoutResources.component[componentKey][elementKey];
                                }
                            }
                        }
                    }
                    this.setState({
                        buildVersion: result.data.buildVersion,
                        buildYear: result.data.buildYear,
                        componentTranslations: componentTranslations,
                        cultures: cultures !== null && cultures !== void 0 ? cultures : this.state.cultures,
                        displayName: displayName,
                        isAuthenticated: isAuthenticated,
                        layoutPermissions: layoutPermissions,
                        layoutResources: layoutResources,
                        menuOptions: menu,
                        notifications: result.data.notifications,
                        resources: resources,
                        sessionCount: this.notificationTimeout,
                        themeEds: newUITheme,
                        themeSettings: theme
                    }, () => this.setState({
                        ready: true
                    }, () => {
                        Actions.setLayoutReady(true);
                        this.setMegaMenuIds();
                    }));
                }
            }
            catch (e) {
                this.logError(LogData.fromException(this.resolveGetInitialLoadData.name, e));
            }
        };
        this.resolveGetIsAuthenticated = (json) => {
            try {
                const result = Resolver(json, this.resolveGetIsAuthenticated.name, this.redirectToLogout);
                if ((result === null || result === void 0 ? void 0 : result.status) && result.data) {
                    this.setState({
                        isLoadingTimeoutNotification: false,
                        openTimeoutNotification: false
                    });
                }
                else {
                    this.clearTimeoutSession();
                    this.clearTimeoutSessionCounter();
                    this.clearTimeoutNotification();
                    this.onCloseTimeoutNotification();
                    this.redirectToLogout();
                }
            }
            catch (e) {
                this.logError(LogData.fromException(this.resolveGetIsAuthenticated.name, e));
            }
        };
        this.onAlertChange = () => {
            const alert = Store.getAlert();
            if (alert) {
                this.setState({
                    alert: alert,
                    loading: false,
                    showMessage: true
                });
            }
            else {
                this.setState({
                    alert: undefined,
                    loading: false,
                    showMessage: false
                });
            }
        };
        this.onCloseAlert = () => {
            this.setState({
                showMessage: false
            });
        };
        this.onCountCartChange = () => {
            this.setState({
                cartItems: Store.getCartItems(),
                countCart: Store.getCountCart()
            });
        };
        this.onError = () => {
            const error = Store.getError();
            const errorMessage = Store.getErrorMessage();
            if (error && errorMessage) {
                this.logError(LogData.fromMessage(this.onError.name, errorMessage));
            }
        };
        this.onLoadingChange = () => {
            const loading = Store.getLoading();
            this.setState({
                loading: loading
            });
        };
        this.onLogDataChange = () => {
            const { idModule, idPage } = this.props;
            const { layoutResources } = this.state;
            const alert = {
                message: 'There was an error in PowerCampus. Try again.',
                messageType: ResultType.error
            };
            const logData = Store.getLogData();
            if (logData) {
                logData.idModule = idModule;
                logData.idPage = idPage;
                switch (logData.type) {
                    case LogType.GenericError:
                        if (layoutResources === null || layoutResources === void 0 ? void 0 : layoutResources.lblError) {
                            alert.message = layoutResources.lblError;
                        }
                        break;
                    case LogType.CustomError:
                        if (logData.userMessage) {
                            alert.message = logData.userMessage;
                        }
                        break;
                }
                Requests.postError(logData);
            }
            this.setState({
                alert: alert,
                loading: false,
                logData: logData,
                showMessage: true
            });
        };
        this.onRedirectCodeChange = () => {
            const redirectCode = Store.getRedirectCode();
            if (redirectCode) {
                this.setState({
                    loading: true
                });
                switch (redirectCode) {
                    case -1:
                        Redirect.toError();
                        break;
                    case 400:
                        Redirect.toError400();
                        break;
                    case 401:
                        Redirect.toError401();
                        break;
                    case 403:
                        Redirect.toError403();
                        break;
                    case 404:
                        Redirect.toError404();
                        break;
                    case 500:
                        Redirect.toError500();
                        break;
                    case 503:
                        Redirect.toError503();
                        break;
                    default:
                        Redirect.toError500();
                        break;
                }
            }
        };
        this.onReloadThemeChange = () => {
            const theme = Store.getTheme();
            const newUITheme = this.getUITheme(theme);
            this.setState({
                themeEds: newUITheme,
                themeSettings: theme
            });
        };
        this.onSetTimeout = () => {
            this.clearTimeoutSession();
            this.clearTimeoutSessionCounter();
            this.clearTimeoutNotification();
            this.onCloseTimeoutNotification();
            const timeout = Store.getTimeout();
            if (timeout >= 0) {
                if (this.notificationTimeout && this.notificationTimeout > 0) {
                    this.timeoutNotification = setTimeout(this.displayNotificationTimeout, ((timeout - this.notificationTimeout) * 1000));
                }
                else {
                    this.timeoutSession = setTimeout(this.redirectToLogout, (timeout * 1000));
                }
            }
        };
        document.title = '';
        this.notificationTimeout = 0;
        this.isADFS = false;
        this.isSAML = false;
        this.state = this.getInitialState();
        const target = document.getElementsByTagName('body');
        if ((target === null || target === void 0 ? void 0 : target.length) > 0) {
            this.bodyStyleObserver = new MutationObserver(this.callbackObserver);
            const config = {
                attributeFilter: ['style'],
                attributeOldValue: true,
                attributes: true
            };
            this.bodyStyleObserver.observe(target[0], config);
        }
        Store.addAlertListener(this.onAlertChange);
        Store.addCountCartListener(this.onCountCartChange);
        Store.addErrorListener(this.onError);
        Store.addLoadingListener(this.onLoadingChange);
        Store.addLogDataListener(this.onLogDataChange);
        Store.addRedirectCodeListener(this.onRedirectCodeChange);
        Store.addReloadThemeListener(this.onReloadThemeChange);
        Store.addTimeoutListener(this.onSetTimeout);
        window.onresize = () => {
            this.setPrivacyPolicy();
            this.setMegaMenuIds();
        };
    }
    getInitialState() {
        const themeSettings = Store.getTheme();
        return {
            countCart: -1,
            cultures: Store.getCultures(),
            isAuthenticated: Store.getIsAuthenticated(),
            isLoadingTimeoutNotification: false,
            layoutResources: Store.getResourcesLayout(),
            loading: false,
            logData: undefined,
            menuOptions: Store.getMenuOptions(),
            openTimeoutNotification: false,
            ready: false,
            sessionCount: this.notificationTimeout,
            showMessage: false,
            themeEds: themeSettings ? this.getUITheme(themeSettings) : undefined,
            themeSettings: themeSettings
        };
    }
    logError(logData) {
        Actions.setLogData(logData);
    }
    getUITheme(theme) {
        const oldMetaTheme = document.getElementById('dynamic-theme');
        const oldLinkFavicon = document.getElementById('dynamic-favicon');
        const metaTheme = document.createElement('meta');
        metaTheme.id = 'dynamic-theme';
        metaTheme.name = 'theme-color';
        const linkFavicon = document.createElement('link');
        linkFavicon.id = 'dynamic-favicon';
        linkFavicon.rel = 'icon';
        const newUITheme = createEDSTheme('light');
        if (theme) {
            if (theme.primaryColor || theme.secondaryColor) {
                newUITheme.palette.primary.main = theme.primaryColor ? theme.primaryColor.toUpperCase() : Tokens.colorBrandPrimary;
                newUITheme.palette.secondary.main = theme.secondaryColor ? theme.secondaryColor.toUpperCase() : Tokens.colorBrandSecondary;
                metaTheme.content = newUITheme.palette.primary.main;
            }
            else {
                metaTheme.content = Tokens.colorBrandPrimary;
            }
            if (theme.actionColor) {
                newUITheme.palette.ctaColor = CtaColors[theme.actionColor];
            }
            if (theme.faviconUrl) {
                linkFavicon.href = theme.faviconUrl;
            }
            else {
                linkFavicon.href = Constants.faviconCDN;
            }
        }
        else {
            metaTheme.content = Tokens.colorBrandPrimary;
            linkFavicon.href = Constants.faviconCDN;
        }
        if (oldMetaTheme) {
            document.head.removeChild(oldMetaTheme);
        }
        if (oldLinkFavicon) {
            document.head.removeChild(oldLinkFavicon);
        }
        document.head.appendChild(metaTheme);
        document.head.appendChild(linkFavicon);
        return newUITheme;
    }
    setHTMLAttributes(cultures) {
        const htmlTag = document.getElementsByTagName('html');
        if ((htmlTag === null || htmlTag === void 0 ? void 0 : htmlTag.length) > 0 && cultures.uiCulture) {
            htmlTag[0].lang = cultures.uiCulture;
            htmlTag[0].dir = cultures.isRightToLeft ? 'rtl' : 'ltr';
        }
    }
    componentDidMount() {
        try {
            const { idModule, idPage, noServer } = this.props;
            if (!noServer) {
                Actions.showPageLoader();
                Requests.getInitialLoadData(idModule, idPage, this.resolveGetInitialLoadData);
            }
        }
        catch (e) {
            this.logError(LogData.fromException(this.componentDidMount.name, e));
        }
    }
    componentWillUnmount() {
        if (this.bodyStyleObserver) {
            this.bodyStyleObserver.disconnect();
        }
        if (this.timeoutSession !== undefined) {
            clearTimeout(this.timeoutSession);
        }
        Store.removeAlertListener(this.onAlertChange);
        Store.removeCountCartListener(this.onCountCartChange);
        Store.removeErrorListener(this.onError);
        Store.removeLoadingListener(this.onLoadingChange);
        Store.removeLogDataListener(this.onLogDataChange);
        Store.removeRedirectCodeListener(this.onRedirectCodeChange);
        Store.removeTimeoutListener(this.onSetTimeout);
    }
    render() {
        var _a;
        const { children, idMainModule, idModule, hideHeader, noPadding, noServer, showCart, showNotifications, withBackgroundImage, withFooter } = this.props;
        const { alert, buildVersion, buildYear, cartItems, componentTranslations, countCart, cultures, displayName, isAuthenticated, isLoadingTimeoutNotification, layoutPermissions, loading, menuOptions, notifications, overModal, openTimeoutNotification: openNotificationTimeout, ready, resources, layoutResources, sessionCount, showMessage, themeEds, themeSettings } = this.state;
        let pageAlert;
        if (alert) {
            if (alert.snackbar) {
                pageAlert = (React.createElement(Snackbar, { action: layoutResources ? (React.createElement(Tooltip, { title: layoutResources.lblClose },
                        React.createElement(IconButton, { color: "white", id: "btnClosePageMessage", "aria-label": layoutResources.lblClose, onClick: this.onCloseAlert },
                            React.createElement(Icon, { name: "close" })))) : undefined, ContentProps: {
                        'aria-describedby': 'pageMessage'
                    }, message: (React.createElement("span", { id: "pageMessage" }, alert.message
                        || (layoutResources === null || layoutResources === void 0 ? void 0 : layoutResources.lblError)
                        || 'There was an error in PowerCampus. Try again.')), open: showMessage, type: alert.message ? alert.messageType : ResultType.error, onClose: this.onCloseAlert }));
            }
            else {
                pageAlert = (React.createElement(Alert, { id: "pageAlert", open: showMessage, overModal: overModal, text: alert.message
                        || (layoutResources === null || layoutResources === void 0 ? void 0 : layoutResources.lblError)
                        || 'There was an error in PowerCampus. Try again.', type: alert.message ? alert.messageType : ResultType.error, userDismissable: true, variant: "page", onClose: this.onCloseAlert }));
            }
        }
        let timeoutSessionModal;
        if (layoutResources === null || layoutResources === void 0 ? void 0 : layoutResources.timeoutNotification) {
            timeoutSessionModal = (React.createElement(Modal, { disableBackdropClick: true, disableEscapeKeyDown: true, footer: (React.createElement(Button, { loading: isLoadingTimeoutNotification, id: "btnExtendSession", onClick: this.onExtendTimeoutNotification }, layoutResources.timeoutNotification.btnExtendSession)), id: "TimeoutNotification", header: layoutResources.timeoutNotification.lblNotificationTitle, open: openNotificationTimeout, showTitleBarClose: !isLoadingTimeoutNotification, onClose: this.onCloseTimeoutNotification },
                React.createElement(Grid, { container: true, spacing: 0 },
                    React.createElement(Grid, { item: true, xs: true },
                        React.createElement(Text, null, Format.toString(layoutResources.timeoutNotification.formatNotificationMessage, [sessionCount]))))));
        }
        let headerTitle;
        let navTabs;
        if (ready) {
            if (!hideHeader) {
                headerTitle = resources.lblHeaderTitle;
            }
            this.setPrivacyPolicy();
        }
        const dir = (cultures === null || cultures === void 0 ? void 0 : cultures.isRightToLeft) ? 'rtl' : 'ltr';
        return (React.createElement(Application, { dir: dir, labelOverrides: componentTranslations, theme: themeEds },
            React.createElement(EdsIconSprite, null),
            (themeEds && themeSettings) || noServer ? (React.createElement(React.Fragment, null,
                React.createElement(NavBar, { cartItems: cartItems, countCart: countCart, displayName: displayName, homeUrl: `${Constants.webUrl}/Home`, isAuthenticated: isAuthenticated, layoutPermissions: layoutPermissions, logoAlternateText: themeSettings === null || themeSettings === void 0 ? void 0 : themeSettings.logoAlternateText, logOutUrl: `${Constants.webUrl}/Home/LogOut`, logoUrl: themeSettings === null || themeSettings === void 0 ? void 0 : themeSettings.logoUrl, menuOptions: menuOptions, notifications: notifications, profileUrl: `${Constants.webUrl}/Account/Profile`, showCart: showCart, showNotifications: showNotifications, resources: (_a = layoutResources === null || layoutResources === void 0 ? void 0 : layoutResources.navBar) !== null && _a !== void 0 ? _a : undefined, valueSelected: idMainModule ? `${idMainModule}Id` : `${idModule}Id` }),
                React.createElement("div", { className: "ss-app" },
                    React.createElement(ContentPage, { backgroundImageUrl: themeSettings === null || themeSettings === void 0 ? void 0 : themeSettings.backgroundUrl, buildVersion: buildVersion, buildYear: buildYear, contentPage: children, headerTitle: headerTitle, isAuthenticated: isAuthenticated, navTabs: navTabs, noPadding: noPadding, withBackgroundImage: withBackgroundImage, withFooter: withFooter })))) : undefined,
            React.createElement(PageLoader, { id: "pageLoader", isDefault: Boolean(themeEds && themeSettings), open: loading, overModal: overModal, title: (layoutResources === null || layoutResources === void 0 ? void 0 : layoutResources.lblLoading) || '' }),
            pageAlert,
            timeoutSessionModal));
    }
}
const LayoutWithStyles = withStyles(styles)(Layout);
export default LayoutWithStyles;
