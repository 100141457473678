function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '../styles';
import { default as MuiTypography } from '@material-ui/core/Typography';

import cn from 'classnames';

var styles = function styles(theme) {
    return {
        root: {},
        colorError: {
            color: theme.palette.status.error.text
        },
        colorTextPrimary: {},
        colorTextSecondary: {},
        h1: {},
        h2: {},
        h3: {},
        h4: {},
        h5: {},
        h6: {},
        body1: {},
        body2: {},
        body3: {},
        overline: {
            fontFamily: theme.typography.body3.fontFamily,
            fontSize: theme.typography.body3.fontSize,
            fontWeight: theme.typography.body3.fontWeight,
            lineHeight: theme.typography.body3.lineHeight,
            wordSpacing: theme.typography.body3.wordSpacing,
            letterSpacing: theme.typography.body3.letterSpacing,
            textTransform: theme.typography.body3.textTransform
        },
        caption: {},
        srOnly: {}
    };
};

/**
 * Use `Typography` to create visual hierarchy, organize content, and guide users through interfaces.
 * @done true
 * @updated false
 * @versionAdded v0.0.12
 * @examples
 *  TypographyExample
 *  srOnlyExample
 */
var Typography = React.forwardRef(function (props, ref) {
    var classesLocal = props.classes,
        children = props.children,
        color = props.color,
        variant = props.variant,
        component = props.component,
        rest = _objectWithoutProperties(props, ['classes', 'children', 'color', 'variant', 'component']);

    // if we're given body3 class overrides, translate them into overrides that'll
    // apply to the underlying implementation


    if (classesLocal.body3) {
        classesLocal.overline = cn(classesLocal.overline, classesLocal.body3);
        delete classesLocal.body3;
    }

    var classOverrides = Object.assign({
        colorError: classesLocal.colorError
    }, classesLocal);

    var currentVariant = variant;
    var currentComponent = component;

    if (variant === 'body3') {

        // translate "body3" to a variant that the underlying Typography component knows
        // about (and that we aren't using)
        currentVariant = 'overline';

        // render 'body3' as paragraphy text, by default
        currentComponent = currentComponent || 'p';
    }

    return React.createElement(
        MuiTypography,
        Object.assign({}, rest, {
            color: color,
            variant: currentVariant,
            component: currentComponent,
            classes: classOverrides,
            ref: ref
        }),
        children
    );
});

Typography.muiName = 'Typography';
Typography.displayName = 'Typography';

Typography.propTypes = {
    /**
     * Set the text-align on the component.
     */
    align: PropTypes.oneOf(['inherit', 'left', 'center', 'right', 'justify']),
    /**
     * The content of the component.
     */
    children: PropTypes.node,
    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object,
    /**
     * @ignore
     */
    className: PropTypes.string,
    /**
     * The color of the component. It supports those theme colors that make sense for this component.
     */
    color: PropTypes.oneOf(['error', 'inherit', 'initial', 'textPrimary', 'textSecondary']),
    /**
     * The component used for the root node.
     * Either a string to use a DOM element or a component.
     * Overrides the behavior of the `variantMapping` prop.
     */
    component: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object]),
    /**
     * Controls the display type
     */
    display: PropTypes.oneOf(['block', 'initial', 'inline']),
    /**
     * If `true`, the text will have margin-bottom: 0.35em.
     */
    gutterBottom: PropTypes.bool,
    /**
     * We are empirically mapping the variant property to a range of different DOM element types.
     * If you wish to change that mapping, you can provide your own. Alternatively, you can use the `component` property.
     *
     * Default mapping: { h1: 'h1', h2: 'h2', h3: 'h3', h4: 'h4', h5: 'h5', h6: 'h6', body1: 'p', body2: 'p',}
     */
    variantMapping: PropTypes.object,
    /**
     * If `true`, the text will not wrap, but instead will truncate with an ellipsis.
     */
    noWrap: PropTypes.bool,
    /**
     * If `true`, the text will have margin-bottom: 16px.
     */
    paragraph: PropTypes.bool,
    /**
     * Applies the theme typography styles for the given variant.
     */
    variant: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'body1', 'body2', 'body3', 'inherit', 'caption', 'srOnly'])
};

Typography.defaultProps = {
    align: 'inherit',
    color: 'initial',
    display: 'block',
    gutterBottom: false,
    noWrap: false,
    paragraph: false,
    variant: 'body2'
};

// set local styles to be lower-precedence, so that overrides
// styles will have an effect
export default withStyles(styles, { index: 0 })(Typography);