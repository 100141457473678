var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { default as withStyles } from '../styles';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '../';

import { spacing40, widthFluid } from '../styles/tokens';

var overlayActionsMobileStyles = {
    flexFlow: 'column',
    '& button': {
        marginBottom: spacing40,
        marginRight: 0,
        textAlign: 'center',
        width: widthFluid,
        '&:last-child': {
            marginBottom: 0
        }
    }
};

var styles = function styles(theme) {
    return {
        actionRoot: _defineProperty({
            justifyContent: 'flex-start',
            '& button': {
                marginRight: spacing40,
                marginLeft: 0
            },
            '& :not(:first-child)': {
                marginLeft: 0
            }
        }, theme.breakpoints.down('sm'), overlayActionsMobileStyles),
        actionsRight: _defineProperty({
            justifyContent: 'flex-end',
            '& button': _defineProperty({
                marginLeft: spacing40,
                marginRight: 0
            }, theme.breakpoints.down('sm'), {
                marginLeft: 0
            }),
            '& :not(:first-child)': {
                marginLeft: spacing40
            }
        }, theme.breakpoints.down('sm'), {
            overlayActionsMobileStyles: overlayActionsMobileStyles,
            '& :not(:first-child)': {
                marginLeft: 0
            }
        })
    };
};

/**
 * Use `SimpleDialog` to create a dialog, without having to explicitly specify each individual aspect of it.
 * @done true
 * @updated false
 * @versionAdded v0.0.13
 * @examples
 *  SimpleDialogExample
 *  SimpleDialogOverflowExample
 *  SimpleDialogResponsiveExample
 */

var SimpleDialog = function (_React$Component) {
    _inherits(SimpleDialog, _React$Component);

    function SimpleDialog() {
        _classCallCheck(this, SimpleDialog);

        return _possibleConstructorReturn(this, (SimpleDialog.__proto__ || Object.getPrototypeOf(SimpleDialog)).apply(this, arguments));
    }

    _createClass(SimpleDialog, [{
        key: 'componentDidMount',
        value: function componentDidMount() {

            // complain if caller gave us more than 3 buttons
            if (this.props.buttonConfig.length > 3) {
                console.warn('Dialogs should not have more than three buttons');
            }
        }
    }, {
        key: 'render',
        value: function render() {
            var _this2 = this;

            var _props = this.props,
                DialogProps = _props.DialogProps,
                DialogActionsProps = _props.DialogActionsProps,
                DialogContentProps = _props.DialogContentProps,
                DialogTitleProps = _props.DialogTitleProps,
                rightAlignButtons = _props.rightAlignButtons,
                classes = _props.classes,
                maxWidth = _props.maxWidth,
                title = _props.title,
                showTitleBarClose = _props.showTitleBarClose,
                closeOnClickOut = _props.closeOnClickOut,
                fullScreen = _props.fullScreen,
                fullWidth = _props.fullWidth,
                buttonConfig = _props.buttonConfig,
                PaperProps = _props.PaperProps,
                children = _props.children,
                id = _props.id;


            var actionClassOverrides = {
                root: classes.actionRoot
            };

            return React.createElement(
                Dialog,
                Object.assign({}, DialogProps, {
                    open: this.props.open,
                    onClose: this.props.onClose,
                    disableBackdropClick: !closeOnClickOut,
                    showTitleBarClose: showTitleBarClose,
                    fullScreen: fullScreen,
                    fullWidth: fullWidth,
                    PaperProps: PaperProps,
                    maxWidth: maxWidth,
                    id: id
                }),
                title && React.createElement(
                    DialogTitle,
                    DialogTitleProps,
                    title
                ),
                React.createElement(
                    DialogContent,
                    DialogContentProps,
                    children
                ),
                React.createElement(
                    DialogActions,
                    Object.assign({}, DialogActionsProps, {
                        classes: actionClassOverrides,
                        'data-id': 'hed-simpleDialog-dialogActions',
                        className: classNames(_defineProperty({}, classes.actionsRight, rightAlignButtons)) }),
                    buttonConfig.slice(0).reverse().map(function (config, index) {
                        return React.createElement(
                            Button,
                            {
                                color: index === buttonConfig.length - 1 ? 'primary' : 'secondary',
                                key: config.id,
                                'data-key': config.id,
                                onClick: function onClick() {
                                    return _this2.props.handleButtonClick(config.id);
                                }
                            },
                            config.title
                        );
                    })
                )
            );
        }
    }]);

    return SimpleDialog;
}(React.Component);

;

SimpleDialog.muiName = 'SimpleDialog';

SimpleDialog.propTypes = {
    /**
     * The contents of the dialog.
     */
    children: PropTypes.node.isRequired,
    /**
     * Props that will be applied to the `Dialog` element.
     */
    DialogProps: PropTypes.object,
    /**
     * Props that will be applied to the `DialogActions` element.
     */
    DialogActionsProps: PropTypes.object,
    /**
     * Props that will be applied to the `DialogContent` element.
     */
    DialogContentProps: PropTypes.object,
    /**
     * Props that will be applied to the `DialogTitle` element.
     */
    DialogTitleProps: PropTypes.object,
    /**
     * Whether the dialog is open
     */
    open: PropTypes.bool.isRequired,
    /**
     * The dialog title.
     */
    title: PropTypes.string,
    /**
     * The buttons to display in the action area. An array of objects with the following properties:
     *
     * * `id`: The button's unique identifier
     * * `title`: The button title
     *
     * Dialogs should have a maximum of three buttons.
     */
    buttonConfig: PropTypes.arrayOf(PropTypes.object),
    /**
     * If true, shows an 'x' in the title bar, which closes the dialog
     */
    showTitleBarClose: PropTypes.bool,
    /**
     * If true, clicking outside the dialog will close it
     */
    closeOnClickOut: PropTypes.bool,
    /**
     * Determine the max width of the dialog.
     *
     * The dialog width grows with the size of the screen, this property is useful
     * on the desktop where you might need some different widths across your
     * application.
     *
     * Valid values:
     *
     * * `xs`
     * * `sm`
     * * `md`
     * * `lg`
     * * `false` (disables max width)
     */
    maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', false]),
    /**
     * If `true`, the dialog stretches to `maxWidth`.
     */
    fullWidth: PropTypes.bool,
    /**
     * If true, dialog will appear in full screen
     */
    fullScreen: PropTypes.bool,
    /**
     * If true, the action buttons will be aligned right
     */
    rightAlignButtons: PropTypes.bool,
    /**
     * Invoked when the dialog wants to close
     */
    onClose: PropTypes.func,
    /**
     * Properties applied to the dialog's internal Paper element.
     */
    PaperProps: PropTypes.object,
    /**
     * Invoked when the user clicks on one of the buttons. Is passed a single argument, the button id.
     */
    handleButtonClick: PropTypes.func,
    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object,
    /**
     * @ignore
     */
    id: PropTypes.string
};

// default prop values
SimpleDialog.defaultProps = {
    showTitleBarClose: true,
    closeOnClickOut: true,
    fullScreen: false,
    fullWidth: false,
    rightAlignButtons: true,
    maxWidth: false,
    buttonConfig: []
};

export default withStyles(styles)(SimpleDialog);