var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Popper from './../Popper';
import MenuList from '@material-ui/core/MenuList';
import { withEDSContext } from '../EDSContext/EDSContext';
import IconButton from '../IconButton/IconButton';
import MoreVertical from '@ellucian/ds-icons/lib/MoreVertical';
import Tooltip from '../Tooltip/Tooltip';
import { default as withStyles } from '../styles';
import { borderRadiusXSmall, borderRadiusSmall, borderWidthThick, borderWidthThin } from '../styles/tokens';

// TODO: Remove this below line after `actions` prop is removed
import createActionMenuItems from './internal/createActionMenuItems';

export var styles = function styles(theme) {
    return {
        root: {
            display: 'inline-block'
        },
        paper: {
            padding: 0,
            border: borderWidthThin + ' solid ' + theme.palette.grey[400]
        },
        iconButton: {
            borderRadius: borderRadiusSmall,
            fontSize: '1.2rem',
            '&:focus': {
                boxShadow: '0 0 0 ' + borderWidthThick + ' ' + theme.palette.focus
            },
            '&.color': {
                color: theme.palette.grey[500]
            },
            '&.color:hover': {
                background: 'none',
                color: theme.palette.ctaColor.base
            },
            '&.color:active': {
                background: 'none',
                color: theme.palette.ctaColor.base
            }
        },
        menuList: {
            maxWidth: '18.750rem',
            borderRadius: '' + borderRadiusXSmall
        }
    };
};

/**
 * Use `ActionMenu` to give users access to additional actions related to a field.
 * @done true
 * @updated false
 * @versionAdded v0.0.8
 * @examples
 *  BasicActionMenuExamples
 *  ActionMenuExamplesWIthArray
 *  ActionMenuExamplesWithDisabledItem
 *  PlacementActionMenuExamples
 *  ActionMenuWithScrollBehavior
 */

var ActionMenu = function (_React$Component) {
    _inherits(ActionMenu, _React$Component);

    function ActionMenu(props) {
        _classCallCheck(this, ActionMenu);

        var _this = _possibleConstructorReturn(this, (ActionMenu.__proto__ || Object.getPrototypeOf(ActionMenu)).call(this, props));

        _this.handleToggle = function () {
            _this.setState({ open: !_this.state.open });
        };

        _this.handleClose = function (event, item) {
            // TODO: remove this below `if` blcok after `actions` prop is removed.
            if (item) {
                item.callback(item);
            }

            _this.setState({ open: false });
            _this.focusActionMenuButton();
        };

        _this.focusActionMenuButton = function () {
            _this.buttonRef.current.focus();
        };

        _this.onKeyDown = function (event) {
            var key = event.key;

            if (key === 'Escape' || key === 'Tab') {
                event.preventDefault();
                _this.handleClose();
            }
        };

        _this.getChildren = function () {
            var children = null;

            if (_this.props.children) {
                children = React.Children.toArray(_this.props.children).map(function (child) {
                    if (!React.isValidElement(child)) {
                        return null;
                    }

                    return React.cloneElement(child, {
                        handleClose: _this.handleClose.bind(_this)
                    });
                });
            }

            return children;
        };

        _this.buttonRef = React.createRef();
        _this.placements = ['top-start', 'top-end'];

        _this.state = {
            open: props.initialOpenOverride // action menu is closed by default, unless an override is passed in
        };
        return _this;
    }

    _createClass(ActionMenu, [{
        key: 'render',
        value: function render() {
            var _this2 = this;

            var _props = this.props,
                classes = _props.classes,
                actions = _props.actions,
                placement = _props.placement,
                menuId = _props.menuId,
                id = _props.id,
                edsContext = _props.edsContext,
                icon = _props.icon,
                className = _props.className,
                IconButtonProps = _props.IconButtonProps,
                PopperProps = _props.PopperProps,
                PaperProps = _props.PaperProps;
            var open = this.state.open;


            var popperPlacement = placement ? placement : edsContext.dir === 'rtl' ? 'bottom-start' : 'bottom-end';
            var PopperPropsProps = Object.assign({}, PopperProps);
            var offset = 20; // Bring popper closer to icon

            // TODO: Do this with lodash or ES6 way.
            if (PopperPropsProps.modifiers) {
                PopperPropsProps.modifiers.offset = offset;
            } else {
                PopperPropsProps.modifiers = { offset: offset };
            }

            return React.createElement(
                'div',
                { className: classNames(classes.root, className) },
                React.createElement(
                    Tooltip,
                    { title: edsContext.formatMessage('component.ActionMenu.more'),
                        enterDelay: 1000,
                        enterNextDelay: 1000
                    },
                    React.createElement(
                        IconButton,
                        Object.assign({
                            ref: this.buttonRef,
                            disableRipple: true,
                            'aria-label': edsContext.formatMessage('component.ActionMenu.more'),
                            'aria-expanded': open,
                            'aria-haspopup': 'true',
                            'aria-controls': menuId || id,
                            onClick: this.handleToggle,
                            color: 'gray',
                            tabIndex: 0
                        }, IconButtonProps),
                        icon
                    )
                ),
                React.createElement(
                    Popper,
                    Object.assign({
                        arrow: true,
                        anchorEl: this.buttonRef.current,
                        open: open,
                        placement: popperPlacement,
                        onClickAway: function onClickAway() {
                            return _this2.setState({ open: false });
                        },
                        className: 'eds-actionmenu-popper',
                        PaperProps: Object.assign({
                            'aria-live': 'off',
                            classes: { root: classes.paper },
                            elevation: 1
                        }, PaperProps)

                    }, PopperPropsProps, {
                        onKeyDown: this.onKeyDown,
                        transition: false
                    }),
                    React.createElement(
                        'div',
                        null,
                        React.createElement(
                            MenuList,
                            { id: menuId || id, className: classes.menuList, autoFocusItem: open },
                            this.getChildren() || createActionMenuItems(actions, this.handleClose)
                        )
                    )
                )
            );
        }
    }]);

    return ActionMenu;
}(React.Component);

// TODO: Make `childrean` prop required after `action` prop is removed


ActionMenu.propTypes = {
    /**
     * @ignore
     *
     * Classes used to extend original style object.
     */
    classes: PropTypes.object,

    /**
     * The content of the component.
     */
    children: PropTypes.node,

    /**
     * **DEPRECATED** Use the below format instead.
     * ```
     * <ActionMenu>
     *  <ActionMenuItem>Add</ActionMenuItem>
     *  <ActionMenuItem>Edit</ActionMenuItem>
     *  <ActionMenuItem>Delete</ActionMenuItem>
     * </ActionMenu>
     * ```
     * Actions and label for the actions to be taken from the `ActionMenu`.
     */
    actions: PropTypes.arrayOf(PropTypes.object),

    /**
     * A CSS class that will be applied to the parent `div`.
     */
    className: PropTypes.string,

    /**
     * @ignore
     */
    edsContext: PropTypes.object,

    /**
     * Icon used in the icon button.
     *
     * Defaults to 'more-vertical' icon.
     */
    icon: PropTypes.node,

    /**
     * **DEPRECATED** Use `id` instead.
     *
     * Menu HTML ID for uniqueness.
     */
    menuId: PropTypes.string,

    /**
     * Menu HTML ID for uniqueness.
     */
    id: PropTypes.string,

    /**
     * Placement is used to place the menu at start or end of the container or screen.
     *
     * Defaults to 'bottom-end' for LTR or 'bottom-start' for RTL.
     */
    placement: PropTypes.oneOf(['bottom-end', 'bottom-start']),

    /**
     * Props applied to the IconButton that brings up the action menu
    */
    IconButtonProps: PropTypes.object,

    /**
     * Props applied to the `Paper` component
    */
    PaperProps: PropTypes.object,

    /**
     * Props applied to the `Popper` element.
     */
    PopperProps: PropTypes.object,

    /**
     * @ignore
     *
     * Externally set the initial open state of the action menu. For testing purposes only.
     */
    initialOpenOverride: PropTypes.bool

};

ActionMenu.defaultProps = {
    initialOpenOverride: false,
    icon: React.createElement(MoreVertical, null)
};

export default withEDSContext(withStyles(styles)(ActionMenu));