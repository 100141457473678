function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import React from 'react';
import { default as MuiBadge } from '@material-ui/core/Badge';
import makeStyles from '../makeStyles';
import PropTypes from 'prop-types';
import { spacing20, spacing40, sizingLarge, sizingXLarge, borderRadiusLarge, borderWidthThin, colorGlobalBorderDefault } from '../styles/tokens';

var useStyles = makeStyles(function (theme) {
    return {
        badge: {
            color: theme.palette.status.default.text,
            background: theme.palette.grey[100],
            minWidth: sizingXLarge,
            width: 'auto',
            height: sizingLarge,
            fontSize: theme.typography.body2.fontSize,
            position: 'initial',
            marginLeft: spacing40,
            marginTop: '-' + spacing20,
            padding: '0 0.75rem',
            border: borderWidthThin + ' solid ' + colorGlobalBorderDefault,
            borderRadius: borderRadiusLarge,
            transform: 'none'
        }
    };
});

/**
 * Use `Badge` to indicate a numerical value or count.
 * @done true
 * @updated false
 * @versionAdded v0.0.6
 * @examples
 *  BadgeExamples
 *  NotificationBadgeExamples
 */
var Badge = React.forwardRef(function (props, ref) {
    var maxvalue = props.maxvalue,
        rest = _objectWithoutProperties(props, ['maxvalue']);

    var classes = useStyles(props);

    var classOverrides = {
        badge: classes.badge
    };

    return React.createElement(MuiBadge, Object.assign({}, rest, {
        max: maxvalue,
        classes: classOverrides,
        ref: ref

        // if a badge has no content, the invisible flag is automatically
        // set to 'true'; we never want a badge to be invisible, so make sure it's
        // always false
        , invisible: false
    }));
});

Badge.muiName = 'Badge';

Badge.propTypes = {
    /**
     * The content rendered within the badge.
     */
    badgeContent: PropTypes.number.isRequired,
    /**
     * The badge will be added relative to this node.
     */
    children: PropTypes.node.isRequired,
    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object,
    /**
     * The component used for the root node. Either a string to use a DOM element or a component.
     */
    component: PropTypes.oneOf(['string', 'func']),
    /**
     * Max value of content rendered within the badge.
     */
    maxvalue: PropTypes.number
};

Badge.displayName = 'Badge';

Badge.defaultProps = {
    maxvalue: 999
};

export default Badge;