function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import React from 'react';
import PropTypes from 'prop-types';
import { default as withStyles } from '../styles';
import { withEDSContext } from '../EDSContext/EDSContext';
import { default as MuiPopover } from '@material-ui/core/Popover';
// Necessary design tokens are imported here
//import { } from '../styles/tokens';

var styles = {
  // Necessary style overrides are set here
  // See Material UI documentation for available CSS classes to override
  paperRoot: {}
};

/**
 * Use `Popover` to display additional content.
 * @done true
 * @updated false
 * @versionAdded v0.0.8
 * @examples
 *  PopoverExamples
 */
var Popover = React.forwardRef(function (props, ref) {
  var classes = props.classes,
      edsContext = props.edsContext,
      rest = _objectWithoutProperties(props, ['classes', 'edsContext']);

  // This component passes through to an internal paper element to style the pertinent area
  // By default it passes the paper class above, but that can be overridden by downstream
  // NOTE! There appears to be a problem preventing styling currently, see the expected behavior here: https://codesandbox.io/s/wq78039yol


  var PaperProps = {
    classes: {
      root: classes.paperRoot
    }
  };

  return React.createElement(MuiPopover, Object.assign({
    dir: edsContext.dir
  }, rest, {
    PaperProps: PaperProps,
    ref: ref
  }));
});

Popover.muiName = 'Popover';
Popover.displayName = 'Popover';

Popover.propTypes = {
  /**
   * This is callback property. It's called by the component on mount.
   * This is useful when you want to trigger an action programmatically.
   * It currently only supports updatePosition() action.
   *
   * @param {object} actions This object contains all posible actions
   * that can be triggered programmatically.
   */
  action: PropTypes.func,
  /**
   * This is the DOM element, or a function that returns the DOM element,
   * that may be used to set the position of the popover.
   */
  anchorEl: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  /**
   * This is the point on the anchor where the popover's
   * `anchorEl` will attach to. This is not used when the
   * anchorReference is 'anchorPosition'.
   *
   * Options:
   * vertical: [top, center, bottom];
   * horizontal: [left, center, right].
   */
  anchorOrigin: PropTypes.shape({
    horizontal: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['left', 'center', 'right'])]),
    vertical: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['top', 'center', 'bottom'])])
  }),
  /**
   * This is the position that may be used
   * to set the position of the popover.
   * The coordinates are relative to
   * the application's client area.
   */
  anchorPosition: PropTypes.shape({
    top: PropTypes.number,
    left: PropTypes.number
  }),
  /**
   * This determines which anchor prop to refer to to set
   * the position of the popover.
   */
  anchorReference: PropTypes.oneOf(['anchorEl', 'anchorPosition', 'none']),
  /**
   * Supported props for the Backdrop of Popover:
   *
   * children: The content of the component.
   *
   * classes: Override or extend the styles applied to the component.
   *
   * invisible: If true, the backdrop is invisible.
   *
   * open: If true, the backdrop is open.
   *
   * transitionDuration: The duration for the transition, in milliseconds. You may specify a single timeout for all transitions, or individually with an object.
   *
   * See more detailed information here: https://material-ui.com/api/backdrop/
   */
  BackdropProps: PropTypes.object,
  /**
   * The content of the component.
   */
  children: PropTypes.node,
  /**
   * Override or extend the styles applied to the component.
   */
  classes: PropTypes.object,
  /**
   * A node, component instance, or function that returns either.
   * The `container` will passed to the Modal component.
   * By default, it uses the body of the anchorEl's top-level document object,
   * so it's simply `document.body` most of the time.
   */
  container: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  /**
   * The elevation of the popover.
   */
  elevation: PropTypes.number,
  /**
   * This function is called in order to retrieve the content anchor element.
   * It's the opposite of the `anchorEl` property.
   * The content anchor element should be an element inside the popover.
   * It's used to correctly scroll and set the position of the popover.
   * The positioning strategy tries to make the content anchor element just above the
   * anchor element.
   */
  getContentAnchorEl: PropTypes.func,
  /**
   * Specifies how close to the edge of the window the popover can appear.
   */
  marginThreshold: PropTypes.number,
  /**
   * Callback fired when the component requests to be closed.
   *
   * @param {object} event The event source of the callback.
   */
  onClose: PropTypes.func,
  /**
   * Callback fired before the component is entering.
   */
  onEnter: PropTypes.func,
  /**
   * Callback fired when the component has entered.
   */
  onEntered: PropTypes.func,
  /**
   * Callback fired when the component is entering.
   */
  onEntering: PropTypes.func,
  /**
   * Callback fired before the component is exiting.
   */
  onExit: PropTypes.func,
  /**
   * Callback fired when the component has exited.
   */
  onExited: PropTypes.func,
  /**
   * Callback fired when the component is exiting.
   */
  onExiting: PropTypes.func,
  /**
   * If `true`, the popover is visible.
   */
  open: PropTypes.bool.isRequired,
  /**
   * Properties applied to the `Paper` element.
   */
  PaperProps: PropTypes.object,
  /**
   * @ignore
   */
  role: PropTypes.string,
  /**
   * This is the point on the popover which
   * will attach to the anchor's origin.
   *
   * Options:
   * vertical: [top, center, bottom, x(px)];
   * horizontal: [left, center, right, x(px)].
   */
  transformOrigin: PropTypes.shape({
    horizontal: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['left', 'center', 'right'])]),
    vertical: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['top', 'center', 'bottom'])])
  }),
  /**
   * Transition component.
   */
  TransitionComponent: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object]),
  /**
   * Set to 'auto' to automatically calculate transition time based on height.
   */
  transitionDuration: PropTypes.oneOfType([PropTypes.number, PropTypes.shape({ enter: PropTypes.number, exit: PropTypes.number }), PropTypes.oneOf(['auto'])]),
  /**
   * Properties applied to the `Transition` element.
   */
  TransitionProps: PropTypes.object,
  /**
   * @ignore
   */
  edsContext: PropTypes.object
};

// If default props are needed, they must be set here
// Popover.defaultProps = { };

export default withEDSContext(withStyles(styles)(Popover));