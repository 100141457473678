import Constants from './Constants';
const Redirect = {
    refreshPage: () => {
        window.location.assign(window.location.href);
    },
    toADFSLogin: (username) => {
        if (username) {
            window.location.assign(`${Constants.webUrl}/ADFS/Authentication?username=${username}`);
        }
        else {
            window.location.assign(`${Constants.webUrl}/ADFS/Authentication`);
        }
    },
    toError: () => {
        window.location.assign(`${Constants.webUrl}/Errors/Error`);
    },
    toError400: () => {
        window.location.assign(`${Constants.webUrl}/Errors/Error400`);
    },
    toError401: () => {
        window.location.assign(`${Constants.webUrl}/Errors/Error401`);
    },
    toError403: () => {
        window.location.assign(`${Constants.webUrl}/Errors/Error403`);
    },
    toError404: () => {
        window.location.assign(`${Constants.webUrl}/Errors/Error404`);
    },
    toError500: () => {
        window.location.assign(`${Constants.webUrl}/Errors/Error500`);
    },
    toError503: () => {
        window.location.assign(`${Constants.webUrl}/Errors/Error503`);
    },
    toHome: () => {
        window.location.assign(`${Constants.webUrl}/Home`);
    },
    toLogin: () => {
        window.location.assign(`${Constants.webUrl}/Home/LogIn`);
    },
    toLogout: () => {
        window.location.assign(`${Constants.webUrl}/Home/LogOut`);
    },
    toLogoutWithReturnUrl: () => {
        window.location.assign(`${Constants.webUrl}/Home/LogOutWithReturnUrl`);
    },
    toSAMLLogin: () => {
        window.location.assign(`${Constants.webUrl}/SSO/SAML`);
    }
};
export default Redirect;
