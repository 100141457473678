// TODO: Remove this file after `actions` prop is removed
import React from 'react';
import ActionMenuItem from './../ActionMenuItem';

var createActionMenuItems = function createActionMenuItems(actions, handleClose) {
    return actions.map(function (item, index) {
        return React.createElement(
            ActionMenuItem,
            {
                onClick: function onClick(e) {
                    return handleClose(e, item);
                },
                key: index,
                tabIndex: index === 0 ? 0 : -1
            },
            item.label
        );
    });
};

export default createActionMenuItems;