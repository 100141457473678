var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import momentPropTypes from 'react-moment-proptypes';
import omit from 'lodash/omit';
import isNil from 'lodash/isNil';
import range from 'lodash/range';
import filter from 'lodash/filter';
import head from 'lodash/head';
import last from 'lodash/last';
import merge from 'lodash/merge';

import cn from 'classnames';
import 'react-dates/initialize';
import { SingleDatePicker as ABSingleDatePicker, DayPickerSingleDateController } from 'react-dates'; // AirBnb DatePicker

import FormControl from '../FormControl/FormControl';
import FormHelperText from '../FormControl/FormHelperText';
import IconButton from '../IconButton/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';

import getPhrasePropTypes from 'react-dates/lib/utils/getPhrasePropTypes';
import { SingleDatePickerPhrases } from 'react-dates/lib/defaultPhrases';

import CalendarIcon from '@ellucian/ds-icons/lib/Calendar';
import ChevronRightIcon from '@ellucian/ds-icons/lib/ChevronRight';
import ChevronLeftIcon from '@ellucian/ds-icons/lib/ChevronLeft';
import ChevronDownIcon from '@ellucian/ds-icons/lib/ChevronDown';
import uuid from 'uuid';

import { withEDSContext } from '../EDSContext/EDSContext';
import withStyles from '../styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Tooltip from '../Tooltip/Tooltip';
import { borderRadiusCircle, borderRadiusMedium, borderWidthThick, borderWidthThin, colorTextNeutral500, fontFamilyDefault, fontFamilyHeader, fontSizeDefault, fontWeightBold, lineHeightParagraphMedium, lineHeightHeader2Small, paddingTextField, sizingMedium, spacing50, spacing40, spacing30, widthInputFields, heightInputFields, heightInputFieldsSmall, zIndexDatePicker, zIndexTextFieldLabel, widthFluid } from '../styles/tokens';

import defaultStyles from './internal/datepickerDefaultStyles';

var MONTH_YEAR_CALENDAR_WIDTH = '354px';

var AUTOPOSITION_CALENDAR_HEIGHT = 276; // (measured in pixels)
var AUTOPOSITION_CALENDAR_WIDTH = 284; // (measured in pixels)

var DEFAULT_PLACEHOLDER = 'Date';

// overrides to the global datepicker styles

// NOTE: we use a lot of raw px values in the styles below (as opposed to rem), because we're
// overriding portions of the underlying react-date styles, which use px; don't want to mix
// px and rem

var globalStyleOverrides = function globalStyleOverrides(theme) {
    return {

        // the standalone version of the month/year pickers will take up the full width
        // of its container unless you constrain it; this has something to do with the
        // fact that we're using the vertically-oriented datepicker for the month/year
        // variant of DatePicker (to get around other rendering issues)
        '.monthYearPickerFix': {

            '&.SingleDatePicker_picker': {
                width: MONTH_YEAR_CALENDAR_WIDTH
            },

            '& .DayPicker': {
                width: MONTH_YEAR_CALENDAR_WIDTH,
                '& .DayPicker_transitionContainer': {
                    // left to its own devices, the vertically-oriented date picker will
                    // allocate enough room for *two*, stacked montly calendars. constrain
                    // the height, and add a little padding at the padding, to compensate
                    height: 'auto !important',
                    paddingBottom: spacing50
                },

                // hide the "second" (offscreen) month entirely when we're showing the
                // month/year selection version of datepicker, so that it doesn't take up
                // any space on the page
                '& .CalendarMonthGrid_month__hidden': {
                    display: 'none'
                }

            }
        },

        '.DayPicker': {
            fontFamily: fontFamilyDefault,
            borderRadius: borderRadiusMedium,
            '& .DayPicker_transitionContainer': {
                borderRadius: borderRadiusMedium
            },
            '& .DayPicker_transitionContainer__horizontal': {
                transition: 'height 100ms ease-in-out',
                '& .CalendarMonthGrid': {
                    background: 'transparent'
                }
            }
        },
        '.SingleDatePicker': {
            '& .SingleDatePickerInput__disabled': {
                backgroundColor: theme.palette.grey[200],
                '&:hover': {
                    cursor: 'not-allowed'
                }
            }
        },
        '.SingleDatePicker_picker': {
            backgroundColor: 'transparent',
            zIndex: zIndexDatePicker
        },
        '.SingleDatePickerInput': {
            width: widthInputFields,
            height: heightInputFields, // 54px to match TextField & Dropdown
            borderRadius: borderRadiusMedium,
            border: borderWidthThin + ' solid ' + theme.palette.grey[400],
            '& .SingleDatePickerInput_calendarIcon': {
                fill: theme.palette.grey[500],
                borderRadius: borderRadiusMedium,
                margin: 0,
                marginLeft: '1.25rem',
                '&:focus': {
                    outline: 'none',
                    boxShadow: '0 0 0 ' + borderWidthThick + ' ' + theme.palette.focus
                },
                '&::-moz-focus-inner': {
                    border: 0
                },
                '&:disabled:hover': {
                    cursor: 'not-allowed'
                }
            },
            '& .DateInput': {
                width: 'calc(100% - 3.75rem)', // Specific to not overlap with the calendar icon button
                backgroundColor: 'transparent',
                '& .DateInput_input': {
                    padding: paddingTextField,
                    backgroundColor: 'transparent',
                    color: theme.palette.grey[600],
                    fontFamily: fontFamilyDefault,
                    fontSize: theme.typography.body1.fontSize,
                    lineHeight: lineHeightHeader2Small,
                    fontWeight: theme.typography.fontWeightRegular,
                    '&:focus': {
                        outline: 0
                    },
                    '&::placeholder': {
                        opacity: 0,
                        color: theme.palette.grey[500],
                        fontSize: theme.typography.body1.fontSize
                    },
                    '&::-webkit-input-placeholder': {
                        opacity: 0,
                        color: theme.palette.grey[500],
                        fontSize: theme.typography.body1.fontSize
                    },
                    '&::-moz-placeholder': {
                        opacity: 0,
                        color: theme.palette.grey[500],
                        fontSize: theme.typography.body1.fontSize
                    },
                    '&:-ms-input-placeholder': {
                        opacity: 0,
                        color: theme.palette.grey[500],
                        fontSize: theme.typography.body1.fontSize
                    },
                    '&:-moz-placeholder': {
                        opacity: 0,
                        color: theme.palette.grey[500],
                        fontSize: theme.typography.body1.fontSize
                    },
                    // Disable FF specific invalid input box-shadow
                    '&:not(output):-moz-ui-invalid': {
                        boxShadow: 'none'
                    }
                },
                '& .DateInput_input__focused': {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent'
                },
                '& .DateInput_input__disabled': {
                    fontStyle: 'normal',
                    '&:hover': {
                        cursor: 'not-allowed'
                    }
                }
            },
            // Hide input fang icon
            '& .DateInput_fang': {
                display: 'none'
            }
        },

        '.SingleDatePickerInput__withBorder': {
            borderRadius: borderRadiusMedium,
            border: borderWidthThin + ' solid ' + theme.palette.grey[400]
        },

        // always hide the standard navigation buttons; we'll be defining our own
        '.DayPickerNavigation_button': {
            display: 'none'
        },

        // always hide vertical navigation buttons; we don't use them
        '.DayPickerNavigation__verticalDefault': {
            display: 'none'
        },

        '.DayPicker__withBorder': {
            border: borderWidthThin + ' solid ' + theme.palette.grey[400],
            boxShadow: '0 0.125rem 0.0625rem -0.0625rem rgba(0, 0, 0, 0.12), 0 0.0625rem 0.0625rem 0 rgba(0, 0, 0, 0.14), 0 0.0625rem 0.1875rem 0 rgba(0, 0, 0, 0.21)'
        },
        '.DayPickerKeyboardShortcuts_show__bottomRight': {
            borderRightColor: theme.palette.ctaColor.base
        },
        '.CalendarMonth_caption': {
            fontFamily: fontFamilyHeader,
            fontSize: theme.typography.h3.fontSize,
            paddingTop: '13px',
            paddingBottom: '28px',
            '& > strong': {
                fontWeight: theme.typography.h3.fontWeight
            }
        },

        '.CalendarDateCell': {
            border: 'none',
            borderRadius: '50%',
            width: '75%',
            height: '75%',
            margin: 'auto',
            lineHeight: '32px'
        },

        // calendar days
        '.CalendarDay': {
            // Open days
            '&.CalendarDay__default': {
                color: theme.palette.grey[600],
                borderColor: 'transparent',
                border: 'none',
                '&:hover': {
                    background: 'none',
                    color: theme.palette.grey[600],
                    border: 'none',
                    '& .CalendarDateCell': {
                        backgroundColor: theme.palette.grey[250],
                        color: theme.palette.grey[600]
                    }
                },
                '&:focus': {
                    background: 'none',
                    outline: 'none',
                    border: 'none',
                    color: theme.palette.grey[100],
                    '& .CalendarDateCell': {
                        backgroundColor: theme.palette.grey[500],

                        '&.today': {
                            color: theme.palette.grey[100]
                        }
                    }
                },
                '& .CalendarDateCell': {
                    borderRadius: borderRadiusCircle,
                    transition: theme.transitions.create(['background-color', 'color'], {
                        duration: 50,
                        easing: theme.transitions.easing.sharp
                    }),
                    '&.today': {
                        color: theme.palette.ctaColor.base,
                        backgroundColor: theme.palette.ctaColor.tint,
                        fontWeight: fontWeightBold
                    },
                    '&:active': {
                        color: theme.palette.grey[100]
                        // backgroundColor: theme.palette.ctaColor.active,
                    }
                }
            },

            // Selected Day
            '&.CalendarDay__selected.CalendarDay__default': {
                background: 'none',
                fontWeight: fontWeightBold,
                color: theme.palette.grey[100],
                '&:hover': {
                    color: theme.palette.grey[100]
                },
                '&:active': {
                    color: theme.palette.grey[100]
                },
                '& .CalendarDateCell': {
                    backgroundColor: theme.palette.ctaColor.base,
                    '&.today': {
                        backgroundColor: theme.palette.ctaColor.base,
                        color: theme.palette.grey[100]
                    },
                    '&:hover': {
                        color: theme.palette.grey[100],
                        backgroundColor: theme.palette.ctaColor.hover
                    },
                    '&:active': {
                        color: theme.palette.grey[100],
                        backgroundColor: theme.palette.ctaColor.active
                    }
                }
            },

            // Disabled Days
            // Out of range
            '&.CalendarDay__blocked_out_of_range': {
                borderRadius: 0,
                color: theme.palette.grey[500],
                backgroundColor: theme.palette.grey[200],
                '&:hover': {
                    borderRadius: 0,
                    color: theme.palette.grey[500] + ' !important',
                    backgroundColor: theme.palette.grey[200] + ' !important',
                    cursor: 'not-allowed'
                },
                '&:focus': {
                    outline: 'none'
                }
            },
            // Days outside of current month
            '&.CalendarDay__outside': {
                color: theme.palette.grey[500],
                borderRadius: borderRadiusCircle,
                '&:hover': {
                    borderRadius: borderRadiusCircle
                },
                '&:focus': {
                    outline: 'none',
                    borderRadius: borderRadiusCircle
                }
            },
            // User defined blocked
            '&.CalendarDay__blocked_calendar': {
                borderRadius: 0,
                color: theme.palette.grey[400],
                backgroundColor: theme.palette.grey[200],
                '&:hover': {
                    borderRadius: 0,
                    color: theme.palette.grey[500] + ' !important',
                    backgroundColor: theme.palette.grey[200] + ' !important',
                    cursor: 'not-allowed'
                },
                '&:focus': {
                    outline: 'none',
                    borderRadius: 0,
                    color: theme.palette.grey[500] + ' !important',
                    backgroundColor: theme.palette.grey[200] + ' !important'
                },
                '&.CalendarDay__outside': {
                    color: theme.palette.grey[500],
                    borderRadius: 0,
                    '&:hover': {
                        borderRadius: 0,
                        color: theme.palette.grey[500] + ' !important',
                        backgroundColor: theme.palette.grey[200] + ' !important'
                    },
                    '&:focus': {
                        outline: 'none',
                        borderRadius: 0,
                        color: theme.palette.grey[500] + ' !important',
                        backgroundColor: theme.palette.grey[200] + ' !important'
                    }
                }
            }
        },
        '.DayPicker_weekHeader': {
            top: '60px'
        },
        '.DayPicker_weekHeader_ul': {
            textTransform: 'uppercase',
            fontSize: theme.typography.caption.fontSize,
            color: theme.palette.grey[500]
        },
        // DatePicker prev + nav buttons
        '.DayPickerNavigation': {
            '& div[role="button"]': {
                position: 'absolute',
                '&:hover': {
                    backgroundColor: theme.palette.ctaColor.tint
                },
                '&:focus, &:active': {
                    backgroundColor: theme.palette.ctaColor.tint
                },
                '&::-moz-focus-inner': {
                    border: 0
                }
            },
            '& div:first-of-type': {
                left: '1.375rem'
            },
            '& div:last-of-type': {
                right: '3.125rem'
            },
            '& svg': {
                border: 'none',
                height: spacing40,
                width: spacing40,
                '&:focus, &:active': {
                    outline: 'none',
                    border: 0
                },
                '&::-moz-focus-inner': {
                    border: 0
                }
            }
        },

        // detached (appendToBody) calendar are rendered with position 'absolute' by
        // default; but this doesn't work in all scenarios, since they'll still
        // be positioned absolutely relative to their parent container

        // override the default to be fixed, intead; this seems to work in all
        // situations
        '.SingleDatePicker_picker[style*="transform: translate3d"]': {
            position: 'fixed'
        },

        '.hedtech-bring-forward': {
            zIndex: theme.zIndex.modal
        },

        // Fix for DatePicker calendar positioning when appendToBody props is set
        '.SingleDatePicker_picker_small': {
            top: '2.875rem !important',
            left: '0.875rem !important'
        }

    };
};

var styles = function styles(theme) {
    return {

        // global styles that apply to all instances of DatePicker -- including the
        // detached calendar that's displayed when the appendToBody option is enabled
        '@global': merge(defaultStyles, globalStyleOverrides(theme)),

        // month picker fix for DatePicker components that are NOT appended to the body
        monthYearPickerFix: {

            '& .SingleDatePicker_picker': {
                width: MONTH_YEAR_CALENDAR_WIDTH
            },

            '& .DayPicker': {
                width: MONTH_YEAR_CALENDAR_WIDTH,
                '& .DayPicker_transitionContainer': {
                    // left to its own devices, the vertically-oriented date picker will
                    // allocate enough room for *two*, stacked montly calendars. constrain
                    // the height, and add a little padding at the padding, to compensate
                    height: 'auto !important',
                    paddingBottom: spacing50
                },

                // hide the "second" (offscreen) month entirely when we're showing the
                // month/year selection version of datepicker, so that it doesn't take up
                // any space on the page
                '& .CalendarMonthGrid_month__hidden': {
                    display: 'none'
                }

            }
        },

        standalone: {
            position: 'relative',
            left: -2,
            zIndex: zIndexDatePicker,
            '& .DayPicker__withBorder': {
                border: borderWidthThin + ' solid ' + theme.palette.grey[400],
                boxShadow: 'none'
            },
            '& .DayPicker .DayPicker_transitionContainer': {
                borderRight: 'none',
                backgroundColor: 'transparent'
            },
            '& .CalendarMonthGrid': {
                backgroundColor: 'transparent'
            }
        },

        // for STANDALONE_WEEK variant, size the calendar so it only shows a single week
        standaloneWeek: {
            '& .DayPicker .DayPicker_transitionContainer': {
                height: '130px !important'
            }
        },

        standaloneRTL: {
            '& .CalendarMonthGrid__vertical_2': {
                transform: 'translateX(0px) !important'
            }
        },
        fullWidth: {
            '& .SingleDatePicker': {
                width: widthFluid
            },
            '& .SingleDatePickerInput': {
                width: widthFluid
            }
        },

        // Apply the focused outline around the input field
        focused: {
            '& .SingleDatePickerInput': {
                boxShadow: '0 0 0 ' + borderWidthThick + ' ' + theme.palette.focus,
                '& .SingleDatePickerInput_calendarIcon': {
                    fill: theme.palette.grey[500],
                    borderRadius: borderRadiusMedium,
                    '&:focus': {
                        outline: 'none',
                        boxShadow: 'none',
                        border: 0
                    },
                    '&::-moz-focus-inner': {
                        border: 0
                    }
                }
            }
        },

        // small size variant DatePicker
        smallDatePicker: {
            '& .div_small': {
                height: heightInputFieldsSmall,
                '& .SingleDatePickerInput': {
                    height: heightInputFieldsSmall,
                    top: '-0.125rem',
                    fontSize: fontSizeDefault,
                    '& .SingleDatePickerInput_calendarIcon': {
                        height: '2.25rem',
                        width: '2.25rem',
                        padding: spacing30,
                        marginLeft: spacing50
                    },
                    '& .DateInput': {
                        height: '2.25rem',
                        '& .DateInput_input': {
                            height: '2.25rem',
                            padding: spacing30,
                            borderBottom: 'none',
                            fontSize: fontSizeDefault
                        }
                    }
                },
                // For positioning the calendar when not used in a Table
                '& .SingleDatePicker_picker': {
                    top: '2.875rem !important'
                }
            }
        },

        // Show the placeholder if no label is provided
        noLabel: {
            '& .SingleDatePickerInput': {
                '& .DateInput': {
                    '& .DateInput_input': {
                        '&::placeholder': {
                            opacity: 1
                        },
                        '&::-webkit-input-placeholder': {
                            opacity: 1
                        },
                        '&::-moz-placeholder': {
                            opacity: 1
                        },
                        '&:-ms-input-placeholder': {
                            opacity: 1
                        },
                        '&:-moz-placeholder': {
                            opacity: 1
                        }
                    }
                }
            }
        },
        noLabelMedium: {
            '& .SingleDatePickerInput': {
                '& .DateInput': {
                    '& .DateInput_input': {
                        padding: spacing40
                    }
                }
            }
        },
        noLabelSmall: {
            '& .SingleDatePickerInput': {
                '& .DateInput': {
                    '& .DateInput_input': {
                        padding: spacing30,
                        fontSize: fontSizeDefault,
                        height: heightInputFieldsSmall,
                        borderBottom: 'none'
                    }
                }
            }
        },

        // Error styling
        error: {
            '& .SingleDatePickerInput': {
                border: borderWidthThick + ' solid ' + theme.palette.status.error.fill
            }
        },
        focusedError: {
            '& .SingleDatePickerInput': {
                boxShadow: '0 0 0 ' + borderWidthThin + ' ' + theme.palette.status.error.fill,
                '-webkit-box-shadow': '0 0 0 ' + borderWidthThin + ' ' + theme.palette.status.error.fill,
                '-moz-box-shadow:': '0 0 0 ' + borderWidthThin + ' ' + theme.palette.status.error.fill
            }
        },

        // Styles to show the placeholder when input is
        // focused / DatePicker is open (with label)
        showPlaceholder: {
            '& .SingleDatePickerInput': {
                '& .DateInput': {
                    '& .DateInput_input': {
                        '&::placeholder': {
                            opacity: 1
                        },
                        '&::-webkit-input-placeholder': {
                            opacity: 1
                        },
                        '&::-moz-placeholder': {
                            opacity: 1
                        },
                        '&:-ms-input-placeholder': {
                            opacity: 1
                        },
                        '&:-moz-placeholder': {
                            opacity: 1
                        }
                    }
                }
            }
        },

        // Calendar Icon Button screen reader only styles
        srOnly: {
            position: 'absolute',
            width: 1,
            height: 1,
            padding: 0,
            margin: '-1px',
            overflow: 'hidden',
            clip: 'rect(0,0,0,0)',
            border: 0
        },

        // NOTE: The following styles could be moved into their
        // own files in the FormControl directory
        // InputLabel
        inputLabelFormControl: {
            zIndex: zIndexTextFieldLabel,
            left: spacing40,
            transform: 'translate(0, 1.125rem) scale(1)'
        },
        inputLabelShrink: {
            fontSize: fontSizeDefault,
            transform: 'translate(0, .5rem) scale(0.75)'
        },

        // styling for custom calendar header
        headerControls: {
            display: 'flex',
            justifyContent: 'space-between',

            '& svg': {
                width: spacing40,
                height: spacing40,
                fill: colorTextNeutral500
            }

        },

        alternateHeaderControls: {
            display: 'flex',
            justifyContent: 'space-between',
            paddingLeft: '0.75rem',

            '& > .utility-icons': {
                display: 'flex'
            },

            '& > .nav-area': {
                display: 'flex',
                alignItems: 'center',

                '& > .nav-date': {
                    flex: '1 0 5.5rem',
                    textAlign: 'start'
                }
            },
            '& svg': {
                width: spacing40,
                height: spacing40,
                fill: colorTextNeutral500
            }

        },

        // additional header styling for incremental calendar
        incrementalSelector: {

            paddingBottom: '1px',
            alignItems: 'center',
            margin: '0 -6px'

        },

        // additional header styling for month/year calendar
        monthYearSelector: {
            padding: '11px 10px 9px 10px'
        },

        monthYearSelectInput: {
            fontFamily: fontFamilyHeader,
            fontSize: theme.typography.h3.fontSize,
            fontWeight: theme.typography.h3.fontWeight
        },

        monthYearSelectInputInput: {
            padding: '0 1.3125rem 0 0'
        },

        monthYearSelectIcon: {
            top: 'calc(50% - 8px)'
        },

        monthYearSelectMenuItem: {
            backgroundColor: theme.palette.grey['100'],
            // note - can't use borderBottom for this because of an Edge bug that makes
            // bottom borders disappear: https://stackoverflow.com/questions/32313551/border-bottom-bug-in-microsoft-edge
            borderTop: borderWidthThin + ' solid ' + theme.palette.grey['300'],
            color: theme.palette.grey['600'],
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.typography.fontSize,
            lineHeight: lineHeightParagraphMedium,
            minHeight: sizingMedium,
            whiteSpace: 'inherit',
            '&:hover, &:focus': {
                backgroundColor: theme.palette.grey['200'],
                color: theme.palette.grey['600']
            }
        },

        monthYearSelectMenuItemSelected: {
            backgroundColor: theme.palette.ctaColor.active + ' !important',
            color: theme.palette.grey['100'] + ' !important'
        },

        monthYearSelectMenu: {
            padding: 0
        },
        inputLabelSmall: {
            top: '-0.35rem',
            left: '0.625rem',
            fontSize: fontSizeDefault,
            '&[data-shrink=true]': {
                display: 'none'
            }
        }

    };
};

// CONSTANTS
export var STANDALONE = 'standalone';
export var STANDALONE_WEEK = 'standalone-week';
export var WITH_TEXT_FIELD = 'with-text-field';

export var INCREMENTAL_SELECTION = 'incremental'; // deprecated
export var MONTH_YEAR_SELECTION = 'month-year'; // deprecated

export var INCREMENTAL_NAVIGATION = 'incremental';
export var INCREMENTAL_NAVIGATION_WITH_TOOLBAR = 'toolbar-incremental';
export var MONTH_YEAR_NAVIGATION = 'month-year';

export var ANCHOR_LEFT = 'left';
export var ANCHOR_RIGHT = 'right';

export var OPEN_DOWN = 'down';
export var OPEN_UP = 'up';

export var HORIZONTAL_ORIENTATION = 'horizontal';
export var VERTICAL_ORIENTATION = 'vertical';

export var ICON_BEFORE_POSITION = 'before';
export var ICON_AFTER_POSITION = 'after';

export var INFO_POSITION_TOP = 'top';
export var INFO_POSITION_BOTTOM = 'bottom';
export var INFO_POSITION_BEFORE = 'before';
export var INFO_POSITION_AFTER = 'after';

var DEFAULT_DISPLAY_FORMAT = 'MMM D, YYYY';
var DEFAULT_WEEK_DAY_FORMAT = 'dd';
var DEFAULT_DAY_SIZE = 44;

var LAST_WEEK_IN_MONTH = Number.MAX_SAFE_INTEGER;

var INPUT_SPECIFIC_PROPS = ['anchorDirection', 'aria-label', 'customCloseIcon', 'customInputIcon', 'disableScroll', 'displayFormat', 'id', 'inputIconPosition', 'openDirection', 'placeholder', 'screenReaderInputMessage'];

// This implementation is based on MUI's TextField
// https://github.com/mui-org/material-ui/blob/next/packages/material-ui/src/TextField/TextField.js

/**
 * Get month metadata from the given date.
 *
 * @param {moment} date  The date of the month to analyze
 */
var getDateRow = function getDateRow(date) {

    var localDate = date.clone();
    var dayOfMonth = localDate.date();

    var daysInMonth = localDate.daysInMonth();
    var monthStartDay = localDate.startOf('month').day();
    var monthEndDay = localDate.endOf('month').day();

    // this accounts for days from the previous/next month appearing in the calendar
    var firstRowOffset = monthStartDay;
    var lastRowOffset = 6 - monthEndDay;

    // the number of rows rendered by the calendar for this month
    var numRowsInMonth = Math.ceil((daysInMonth + firstRowOffset + lastRowOffset) / 7);

    // the calendar row containing the given date
    var activeMonthRow = Math.ceil((dayOfMonth + monthStartDay) / 7);

    return {
        monthStartDay: monthStartDay,
        monthEndDay: monthEndDay,
        numRowsInMonth: numRowsInMonth,
        activeMonthRow: activeMonthRow
    };
};

/**
 * Use `DatePicker` to select a single date.
 * @done true
 * @updated true
 * @versionAdded v0.0.5
 * @examples
 *  ExampleDatePicker
 *  Disabled
 *  FullWidth
 *  DateFormats
 *  DatePickerWithinAForm
 *  InADialog
 *  Standalone
 *  StandaloneWithToggle
 * @examplesInternal
 *  ExampleDatePickerRTL
 */

var DatePicker = function (_React$Component) {
    _inherits(DatePicker, _React$Component);

    function DatePicker(props) {
        _classCallCheck(this, DatePicker);

        var _this = _possibleConstructorReturn(this, (DatePicker.__proto__ || Object.getPrototypeOf(DatePicker)).call(this, props));

        _this.state = {
            shrink: false,
            currentWeekRow: 0 // tracks current week for STANDALONE_WEEK variant
        };

        _this.handleClose = function () {
            if (navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)) {
                _this.props.onFocusChange({ focused: false });
            }
        };

        _this.switchMonth = function (direction, month, onMonthSelect, onYearSelect) {

            var localMonth = month.clone();
            var monthCoefficient = void 0;

            // add or subtract a month, depending on direction
            //
            // note: we use the monthCoefficient to counteract an internal react-date offset that shifts the month
            // forward/back by three months when switching years.  ¯\_(ツ)_/¯
            if (direction === -1) {
                localMonth.subtract(1, 'month');
                monthCoefficient = 9;
            } else {
                localMonth.add(1, 'month');
                monthCoefficient = -2;
            }

            // switch year if the navigation crosses year boundries
            if (month.year() !== localMonth.year()) {
                onYearSelect(month, localMonth.year());
                onMonthSelect(localMonth, monthCoefficient);
            } else {
                onMonthSelect(month, localMonth.month());
            }

            // invoke the next/prev callbacks
            if (direction === -1 && _this.props.onPrevMonthClick) {
                _this.props.onPrevMonthClick(localMonth);
            } else if (direction === 1 && _this.props.onNextMonthClick) {
                _this.props.onNextMonthClick(localMonth);
            }
        };

        _this.handleCalendarNavigation = function (direction, month, isWeekCalendar, onMonthSelect, onYearSelect) {

            if (isWeekCalendar) {

                var monthDateInfo = getDateRow(month);
                var numWeeksInMonth = monthDateInfo.numRowsInMonth;

                // to to the next previous week
                var updatedWeek = _this.state.currentWeekRow === LAST_WEEK_IN_MONTH ? numWeeksInMonth - 1 : _this.state.currentWeekRow;

                updatedWeek += direction;

                // if we're on the first/last week of the month, check to see if we should be transitioning
                // the another month
                if (updatedWeek === 0 || updatedWeek === numWeeksInMonth - 1) {

                    var isTransitionRow = direction === 1 ? monthDateInfo.monthEndDay !== 6 : monthDateInfo.monthStartDay !== 0;

                    // if it does, consider it a week from the next/previous calendar
                    if (isTransitionRow) {
                        updatedWeek = direction === 1 ? numWeeksInMonth : -1;
                    }
                }

                // if we're moving to a new month
                if (updatedWeek < 0 || updatedWeek === numWeeksInMonth) {

                    // move to the last week of the previous month
                    if (updatedWeek < 0) {
                        updatedWeek = LAST_WEEK_IN_MONTH;
                    }

                    // move to the first week of the next month
                    else if (updatedWeek === numWeeksInMonth) {
                            updatedWeek = 0;
                        }

                    _this.switchMonth(direction, month, onMonthSelect, onYearSelect);
                }

                _this.setState({ currentWeekRow: updatedWeek });

                // this is a full calendar; just move to the appopriate month
            } else {
                _this.switchMonth(direction, month, onMonthSelect, onYearSelect);
            }
        };

        _this.handleNavigationButtonPress = function (e, direction, month, isWeekCalendar, onMonthSelect, onYearSelect) {

            // the week calendar wants to handle all nav button keybaord events itself
            if (isWeekCalendar) {
                e.stopPropagation();
            }

            // navigate backwards/forwards on enter or space
            if (e.keyCode === 32 || e.keyCode === 13) {
                _this.handleCalendarNavigation(direction, month, isWeekCalendar, onMonthSelect, onYearSelect);
            }
        };

        _this.selectorId = uuid();
        _this.datePickerRef = React.createRef();
        _this.singleDatePickerId = props.id ? props.id : uuid();
        return _this;
    }

    _createClass(DatePicker, [{
        key: 'componentDidMount',
        value: function componentDidMount() {
            var _props = this.props,
                date = _props.date,
                variant = _props.variant,
                appendToBody = _props.appendToBody;

            // if a detached calendar is enabled, remember the current overflow values of the
            // top page elements; we will need this info later

            if (appendToBody) {
                this.getOverflow();
            }

            if (variant === WITH_TEXT_FIELD) {
                this.HACK_setConditionalSizeClass(this.props);
            }

            // if this is a standlone week calendar, set the row to the one containing the
            // selected date
            if (date && variant === STANDALONE_WEEK) {
                this.setCurrentRow(date);
            }
        }

        /**
         * Set the row to display in the standalone week calendar
         *
         * @param {moment} date The date containing the row that should be selected
         */

    }, {
        key: 'setCurrentRow',
        value: function setCurrentRow(date) {
            var activeRow = getDateRow(date).activeMonthRow;
            this.setState({ currentWeekRow: activeRow - 1 });
        }

        /**
         * Week calendar keyboard event handler.
         */

    }, {
        key: 'handleWeekCalendarKeyboardNav',
        value: function handleWeekCalendarKeyboardNav(e) {

            // prevent calendar from handling keyboard navigation (we let the enter
            // through so that users can select dates)
            if (e.keyCode !== 13) {
                e.stopPropagation();
            }
        }

        /**
         * Hides rows in the calendar
         *
         * @param {number} week The week that should be visible. -1 to show all weeks
         * @param {boolean} allCalendars Whether this operation to all calendars, or just the visible one.
         */

    }, {
        key: 'setVisibleCalendarRows',
        value: function setVisibleCalendarRows(week, allCalendars) {

            // grab all the rows in the calendar
            var allCalendarViews = this.datePickerRef.current.querySelectorAll('table');
            var dateRows = void 0;

            // react-dates keeps three month calendars active in the dom, only one of which
            // is visible; if prompted, change row visibility in all of them
            if (allCalendars) {
                dateRows = this.datePickerRef.current.querySelectorAll('table tr');
            } else {
                dateRows = allCalendarViews[1].querySelectorAll('tr');
            }

            // figure out which one should be visible
            var visibleRow = week === LAST_WEEK_IN_MONTH ? dateRows.length - 1 : week;

            // show just the selected row (or all rows if directed)
            dateRows.forEach(function (row, index) {

                // show only the row for the current week
                row.style.display = visibleRow === -1 || index === visibleRow ? 'table-row' : 'none';

                // make each of the dates in that row tabstops, so that the user can keyboard
                // through them
                row.querySelectorAll('td').forEach(function (td) {
                    return td.tabIndex = index === visibleRow ? 0 : -1;
                });

                // TODO - the standard calendar uses arrows to move through dates, but I can't seem
                // to get that to work
            });
        }

        /**
         * Applies the appropriate header for the standalone week calendar.
         */

    }, {
        key: 'setWeekCalendarHeader',
        value: function setWeekCalendarHeader() {

            // the week calendar displays the range of dates in the current week;  we don't have
            // enough insight about what the range portion of this is during render time,
            // so it's applied *after* the fact

            // get the current month/year from where the render method stashed it in the dom
            var headerArea = this.datePickerRef.current.querySelector('div.CalendarMonth_1[data-visible=true]').querySelector('span.calendar-title');
            var calenderDate = moment(headerArea.getAttribute('data-current-date'), 'MMM YYYY');

            // get all the data in the visible row of the calendar
            var weekRows = this.datePickerRef.current.querySelectorAll('table')[1].querySelector('tr[style*="table-row"]');
            var days = weekRows.querySelectorAll('td');

            // filter the list of days down to the ones for the current month
            var weekData = filter(days, function (day) {
                return ![].concat(_toConsumableArray(day.classList)).includes('CalendarDay__outside');
            });

            // put together the header text for the current week
            var weekCalendarDayRange = head(weekData).textContent + '-' + last(weekData).textContent;
            var headerText = calenderDate.format('MMM') + ' ' + weekCalendarDayRange;

            headerArea.innerText = headerText;
        }
    }, {
        key: 'setMonthCalendarHeader',
        value: function setMonthCalendarHeader() {

            // get the current month/year from where the render method stashed it in the dom
            var headerArea = this.datePickerRef.current.querySelector('div.CalendarMonth_1[data-visible=true]').querySelector('span.calendar-title');
            var calenderDate = moment(headerArea.getAttribute('data-current-date'), 'MMM YYYY');

            var headerText = calenderDate.format('MMM YYYY');

            headerArea.innerText = headerText;
        }
    }, {
        key: 'componentDidUpdate',
        value: function componentDidUpdate(prevProps, prevState) {
            var _this2 = this;

            var _props2 = this.props,
                date = _props2.date,
                variant = _props2.variant,
                appendToBody = _props2.appendToBody,
                monthSelectionVariant = _props2.monthSelectionVariant,
                headerVariant = _props2.headerVariant,
                focused = _props2.focused;

            // if switching into standalone week view, show the row with the selected date

            if (variant === STANDALONE_WEEK && prevProps.variant !== STANDALONE_WEEK) {
                this.setCurrentRow(date);
            }

            // if switching out of week view, make sure all rows are visible
            else if (variant !== STANDALONE_WEEK && prevProps.variant === STANDALONE_WEEK) {
                    this.setVisibleCalendarRows(-1, true);
                    this.setState({ currentWeekRow: 0 });
                    this.setMonthCalendarHeader();
                } else {

                    // whether to use month/year header (checking both the deprecated monthSelectionVariant and headerVariant props
                    var useMonthYearHeader = monthSelectionVariant === MONTH_YEAR_SELECTION || headerVariant.headerType === MONTH_YEAR_NAVIGATION;

                    // determine whether the header type changed
                    var headerVariantChanged = monthSelectionVariant !== prevProps.monthSelectionVariant || headerVariant !== prevProps.headerVariant;

                    if (variant === WITH_TEXT_FIELD) {

                        // This accounts for DatePickers when placed on Dialogs
                        // The MUI Dialog disables the scroll when open, and we need to save that new overflow state
                        if (this.props.appendToBody) {
                            this.getOverflow();
                        }

                        // when the date picker is rendered with `appendToBody`, the date picker calendar
                        // is appended to the top of the DOM hierarchy; this makes it difficult to apply
                        // conditional styling to it, since react-dates doesn't give us a way to interact
                        // with the calendar specifically

                        // the workaround: when we want to apply the month/picker hack to it, we
                        // wait for it to appear, then apply the styles *directly* to the calendar
                        // div

                        // check if (1) something has changed that requires us to take another look at the
                        // calendar; and (2) we're dealing with an open, detached calendar
                        if ((appendToBody !== prevProps.appendToBody || focused !== prevProps.focused || headerVariantChanged) && appendToBody && focused) {

                            // jump out of the event loop, to give the calendar time to render
                            setTimeout(function () {
                                // apply the hack if the we're using the month/year selector variant;
                                // otherwise, remove it
                                _this2.handleMonthPickerHack(useMonthYearHeader);

                                // Use this hack to adjust the z-index of the DatePicker when being rendered in a Dialog
                                // when the prop appendToBody is true. This gives the DatePicker a higher z-index so it is
                                // not hidden in the background of the Dialog/the Dialog's backdrop.
                                _this2.HACK_handleZIndex();
                            }, 1);
                        }

                        // add aria info to calendar button
                        this.HACK_updateCalendarButtonA11yInfo();

                        // if an aria label was provided, apply to input field
                        this.HACK_updateAriaLabel();

                        var curShrink = this.state.shrink;

                        var htmlInput = this.datePickerRef.current.querySelector('.DateInput_input');
                        var htmlInputValueLength = htmlInput ? htmlInput.value.length : 0;

                        if (date !== null || curShrink && htmlInputValueLength > 0) {
                            return;
                        } else if (curShrink && htmlInputValueLength === 0) {
                            this.setState({ shrink: false });
                        } else if (!curShrink && htmlInputValueLength > 0) {
                            this.setState({ shrink: true });
                        }

                        this.HACK_setConditionalSizeClass();
                    }

                    // if we're in the week calendar, display only the row for the current week
                    else if (variant === STANDALONE_WEEK) {

                            this.setVisibleCalendarRows(this.state.currentWeekRow);

                            // retractively apply the week calendar header
                            if (!useMonthYearHeader) {
                                this.setWeekCalendarHeader();
                            }

                            // register an event handler to take control of all keyboard events on the
                            // calendar
                            var calendar = this.datePickerRef.current.querySelector('.DayPicker_focusRegion');
                            calendar.removeEventListener('keydown', this.handleWeekCalendarKeyboardNav);
                            calendar.addEventListener('keydown', this.handleWeekCalendarKeyboardNav);
                        }
                }
        }

        // Utility function that gets the overflow of the html and body elements

    }, {
        key: 'getOverflow',
        value: function getOverflow() {
            this.htmlOverflowY = document.querySelector('html').style.overflowY;
            this.bodyOverflowY = document.querySelector('body').style.overflowY;
        }

        /**
         * Find the instance of the calendar that's been appeneded to the document body, and
         * add (or remove) the class that patches our issues with the month picker
         *
         * @param {boolean} apply True to apply the class; false to remove it
         */

    }, {
        key: 'handleMonthPickerHack',
        value: function handleMonthPickerHack(apply) {

            // grab the calendar
            var datePickers = document.querySelectorAll('body > div > div.SingleDatePicker_picker');

            // add/remove the month picker hack
            if (datePickers.length > 0) {
                for (var i = 0; i < datePickers.length; i++) {
                    var datePicker = datePickers[i];
                    if (datePicker && datePicker.classList) {
                        if (apply) {
                            if (!datePicker.classList.contains('monthYearPickerFix')) {
                                datePicker.classList.add('monthYearPickerFix');
                            }
                        } else {
                            datePicker.classList.remove('monthYearPickerFix');
                        }
                    }
                }
            }
        }
    }, {
        key: 'HACK_handleZIndex',
        value: function HACK_handleZIndex() {
            // Get DatePicker
            var datePickers = document.querySelectorAll('body > div > div.SingleDatePicker_picker');

            // Assign DatePicker a higher z-index
            if (datePickers.length > 0) {
                for (var i = 0; i < datePickers.length; i++) {
                    var datePicker = datePickers[i];
                    if (datePicker && datePicker.classList) {
                        datePicker.classList.add('hedtech-bring-forward');
                    }
                }
            }
        }

        // add aria info to the calendar popup button

    }, {
        key: 'HACK_updateCalendarButtonA11yInfo',
        value: function HACK_updateCalendarButtonA11yInfo() {

            var calendarButton = this.datePickerRef.current.querySelector('.SingleDatePickerInput_calendarIcon');

            // add a "hasPopup" aria label to the button that pops up the date picker
            if (this.datePickerRef.current) {

                if (!calendarButton.getAttribute('aria-haspopup')) {

                    // note -- we don't have direct access to the button, so need to hack our way in there
                    calendarButton.setAttribute('aria-haspopup', 'true');
                }
            }
        }

        // Need to add specific classes for small size DatePicker

    }, {
        key: 'HACK_setConditionalSizeClass',
        value: function HACK_setConditionalSizeClass() {
            // Need to add a class to this basic <div> so we can target it specifically
            var htmlInput = this.datePickerRef.current.querySelector('.DateInput_input');

            if (this.props.size === 'small' && htmlInput && htmlInput.classList && htmlInput.parentElement.parentElement.parentElement) {
                htmlInput.parentElement.parentElement.parentElement.classList.add('div_small');
            }

            // DatePicker with appendToBody doesn't respect calendar's top positioning because of where it's rendered
            // Need to be more aggressive setting it
            var datePickers = document.querySelectorAll('body > div > div.SingleDatePicker_picker');

            if (datePickers.length > 0) {
                for (var i = 0; i < datePickers.length; i++) {
                    var datePicker = datePickers[i];
                    if (datePicker && datePicker.classList && !datePicker.classList.contains('SingleDatePicker_picker_small')) {
                        datePicker.classList.add('SingleDatePicker_picker_small');
                    }
                }
            }
        }

        // add the correct aria-label to the datepicker input field

    }, {
        key: 'HACK_updateAriaLabel',
        value: function HACK_updateAriaLabel() {

            // add a "hasPopup" aria attribute to the button that pops up the date picker
            if (this.props['aria-label']) {

                var htmlInput = this.datePickerRef.current.querySelector('.DateInput_input');

                if (htmlInput.getAttribute('aria-label') !== this.props['aria-label']) {

                    // note -- we need to hack this in, because the placeholder text always overrides
                    // whatever you pass in for aria-label, effectively making it impossible to have
                    // different placeholder and aria-label text
                    htmlInput.setAttribute('aria-label', this.props['aria-label']);
                }
            }
        }

        // builds a month selection dropdown

    }, {
        key: 'buildSelector',
        value: function buildSelector(menuItems, callback, value, type) {
            var classes = this.props.classes;
            var dirString = this.props.isRTL ? 'rtl' : this.props.edsContext.dir;

            return React.createElement(
                Select,
                {
                    IconComponent: ChevronDownIcon,
                    onChange: callback,
                    MenuProps: {
                        id: this.selectorId,
                        MenuListProps: {
                            classes: {
                                root: classes.monthYearSelectMenu
                            },
                            'aria-label': type === 'month' ? this.props.edsContext.formatMessage('component.DatePicker.monthPicker') : this.props.edsContext.formatMessage('component.DatePicker.yearPicker')
                        },
                        dir: dirString
                    },
                    classes: {
                        icon: classes.monthYearSelectIcon
                    },
                    value: value,
                    input: React.createElement(Input, {
                        classes: {
                            root: classes.monthYearSelectInput,
                            input: classes.monthYearSelectInputInput },
                        disableUnderline: true
                    })
                },
                menuItems
            );
        }

        // Method will close the calender if user clicks outside of the calendar container.


        /**
         * Switch to the next/previous month.
         *
         * @param {number}   direction     The direction to navigation; 1 == forward, -1 == backward
         * @param {moment}   month         Current month
         * @param {function} onMonthSelect Invoke to choose a different month
         * @param {function} onYearSelect  Invoke to choose a different year
         */


        /**
         * Handles incremental calendar navigation
         *
         * @param {number}   direction      The direction to navigation; 1 == forward, -1 == backward
         * @param {moment}   month          Current month
         * @param {boolean}  isWeekCalendar True if this is a week clendar
         * @param {function} onMonthSelect  Invoke to choose a different month
         * @param {function} onYearSelect   Invoke to choose a different year
         */


        /**
         * Handle incremental navigation via keybaord.
         */

    }, {
        key: 'render',
        value: function render() {
            var _this3 = this,
                _cn,
                _cn2;

            var _props3 = this.props,
                classes = _props3.classes,
                error = _props3.error,
                variant = _props3.variant,
                className = _props3.className,
                helperText = _props3.helperText,
                fullWidth = _props3.fullWidth,
                edsContext = _props3.edsContext,
                FormControlProps = _props3.FormControlProps,
                FormHelperTextProps = _props3.FormHelperTextProps,
                InputLabelProps = _props3.InputLabelProps,
                isRTLProp = _props3.isRTL,
                labelProp = _props3.label,
                startYear = _props3.startYear,
                endYear = _props3.endYear,
                autoPosition = _props3.autoPosition,
                screenReaderInputMessageProp = _props3.screenReaderInputMessage,
                placeholderProp = _props3.placeholder,
                onFocusChange = _props3.onFocusChange,
                monthSelectionVariant = _props3.monthSelectionVariant,
                isOutsideRange = _props3.isOutsideRange,
                size = _props3.size,
                daySize = _props3.daySize,
                headerVariant = _props3.headerVariant,
                highlightToday = _props3.highlightToday,
                date = _props3.date,
                props = _objectWithoutProperties(_props3, ['classes', 'error', 'variant', 'className', 'helperText', 'fullWidth', 'edsContext', 'FormControlProps', 'FormHelperTextProps', 'InputLabelProps', 'isRTL', 'label', 'startYear', 'endYear', 'autoPosition', 'screenReaderInputMessage', 'placeholder', 'onFocusChange', 'monthSelectionVariant', 'isOutsideRange', 'size', 'daySize', 'headerVariant', 'highlightToday', 'date']);

            var shrink = this.state.shrink;

            var isRTL = isRTLProp ? isRTLProp : edsContext.dir === 'rtl';
            var dirString = isRTLProp ? 'rtl' : edsContext.dir;
            var hasLabel = !isNil(labelProp);
            var helperTextId = helperText && props.id ? props.id + '-helper-text' : undefined;
            var shouldShrink = props.focused || date !== null || shrink;

            var label = labelProp;
            // translate placeholder text if we're displaying the default
            var placeholder = placeholderProp === DEFAULT_PLACEHOLDER ? edsContext.formatMessage('component.DatePicker.dateLabel') : placeholderProp;

            // Add asterisk to label or placeholder if required
            if (props.required) {
                if (hasLabel) {
                    label = labelProp + ' *';
                } else {
                    placeholder = placeholder + ' *';
                }
            }

            // set up the default screen reader input message
            var screenReaderInputMessage = screenReaderInputMessageProp || edsContext.formatMessage('component.DatePicker.a11y.inputDescription', {
                dateFormat: this.props.displayFormat
            });

            // whether to use month/year header (checking both the deprecated monthSelectionVariant and headerVariant props
            var useMonthYearHeader = monthSelectionVariant === MONTH_YEAR_SELECTION || headerVariant.headerType === MONTH_YEAR_NAVIGATION;

            // the custom header for incremental navigation
            var incrementalNavHeader = function incrementalNavHeader(_ref) {
                var month = _ref.month,
                    onMonthSelect = _ref.onMonthSelect,
                    onYearSelect = _ref.onYearSelect;


                var previousTooltip = variant === STANDALONE_WEEK ? edsContext.formatMessage('component.DatePicker.jumpToPrevWeek') : edsContext.formatMessage('component.DatePicker.jumpToPrevMonth');

                var nextTooltip = variant === STANDALONE_WEEK ? edsContext.formatMessage('component.DatePicker.jumpToNextWeek') : edsContext.formatMessage('component.DatePicker.jumpToNextMonth');

                var headerText = month.format('MMMM YYYY');

                return React.createElement(
                    'div',
                    { className: cn(classes.headerControls, classes.incrementalSelector) },
                    React.createElement(
                        'div',
                        null,
                        React.createElement(
                            Tooltip,
                            { title: previousTooltip, enterDelay: 1000, enterNextDelay: 1000 },
                            React.createElement(
                                IconButton,
                                {
                                    color: 'gray',
                                    onMouseUp: function onMouseUp() {
                                        return _this3.handleCalendarNavigation(-1, month, variant === STANDALONE_WEEK, onMonthSelect, onYearSelect);
                                    },
                                    onKeyDown: function onKeyDown(e) {
                                        return _this3.handleNavigationButtonPress(e, -1, month, variant === STANDALONE_WEEK, onMonthSelect, onYearSelect);
                                    }
                                },
                                isRTL ? React.createElement(ChevronRightIcon, null) : React.createElement(ChevronLeftIcon, null)
                            )
                        )
                    ),
                    React.createElement(
                        'strong',
                        null,
                        React.createElement(
                            'span',
                            { 'data-current-date': month.format('MMM YYYY'), className: 'calendar-title' },
                            headerText
                        )
                    ),
                    React.createElement(
                        'div',
                        null,
                        React.createElement(
                            Tooltip,
                            { title: nextTooltip, enterDelay: 1000, enterNextDelay: 1000 },
                            React.createElement(
                                IconButton,
                                {
                                    color: 'gray',
                                    onMouseUp: function onMouseUp() {
                                        return _this3.handleCalendarNavigation(1, month, variant === STANDALONE_WEEK, onMonthSelect, onYearSelect);
                                    },
                                    onKeyDown: function onKeyDown(e) {
                                        return _this3.handleNavigationButtonPress(e, 1, month, variant === STANDALONE_WEEK, onMonthSelect, onYearSelect);
                                    }
                                },
                                isRTL ? React.createElement(ChevronLeftIcon, null) : React.createElement(ChevronRightIcon, null)
                            )
                        )
                    )
                );
            };

            // the custom header for incremental navigation
            var toolbarIncrementalNavHeader = function toolbarIncrementalNavHeader(_ref2) {
                var month = _ref2.month,
                    onMonthSelect = _ref2.onMonthSelect,
                    onYearSelect = _ref2.onYearSelect;


                var previousTooltip = variant === STANDALONE_WEEK ? edsContext.formatMessage('component.DatePicker.jumpToPrevWeek') : edsContext.formatMessage('component.DatePicker.jumpToPrevMonth');

                var nextTooltip = variant === STANDALONE_WEEK ? edsContext.formatMessage('component.DatePicker.jumpToNextWeek') : edsContext.formatMessage('component.DatePicker.jumpToNextMonth');

                var headerText = month.format('MMM YYYY');

                return React.createElement(
                    'div',
                    { role: 'toolbar', className: cn(classes.alternateHeaderControls), onKeyDown: function onKeyDown(e) {
                            return e.stopPropagation();
                        } },
                    React.createElement(
                        'div',
                        { className: 'nav-area' },
                        React.createElement(
                            'div',
                            { className: 'nav-date' },
                            React.createElement(
                                'strong',
                                null,
                                React.createElement(
                                    'span',
                                    { 'data-current-date': month.format('MMM YYYY'), className: 'calendar-title' },
                                    headerText
                                )
                            )
                        ),
                        React.createElement(
                            'div',
                            null,
                            React.createElement(
                                Tooltip,
                                { title: previousTooltip, enterDelay: 1000, enterNextDelay: 1000 },
                                React.createElement(
                                    IconButton,
                                    {
                                        color: 'gray',
                                        onMouseUp: function onMouseUp() {
                                            return _this3.handleCalendarNavigation(-1, month, variant === STANDALONE_WEEK, onMonthSelect, onYearSelect);
                                        },
                                        onKeyDown: function onKeyDown(e) {
                                            return _this3.handleNavigationButtonPress(e, -1, month, variant === STANDALONE_WEEK, onMonthSelect, onYearSelect);
                                        }
                                    },
                                    isRTL ? React.createElement(ChevronRightIcon, null) : React.createElement(ChevronLeftIcon, null)
                                )
                            )
                        ),
                        React.createElement(
                            'div',
                            null,
                            React.createElement(
                                Tooltip,
                                { title: nextTooltip, enterDelay: 1000, enterNextDelay: 1000 },
                                React.createElement(
                                    IconButton,
                                    {
                                        color: 'gray',
                                        onMouseUp: function onMouseUp() {
                                            return _this3.handleCalendarNavigation(1, month, variant === STANDALONE_WEEK, onMonthSelect, onYearSelect);
                                        },
                                        onKeyDown: function onKeyDown(e) {
                                            return _this3.handleNavigationButtonPress(e, 1, month, variant === STANDALONE_WEEK, onMonthSelect, onYearSelect);
                                        }
                                    },
                                    isRTL ? React.createElement(ChevronLeftIcon, null) : React.createElement(ChevronRightIcon, null)
                                )
                            )
                        )
                    ),
                    _this3.props.headerVariant.toolbarItems && React.createElement(
                        'div',
                        { className: 'utility-icons' },
                        _this3.props.headerVariant.toolbarItems.map(function (item, index) {
                            return React.createElement(
                                React.Fragment,
                                { key: index },
                                item
                            );
                        })
                    )
                );
            };

            // the custom header for month/year navigation
            var monthYearSelectionHeader = function monthYearSelectionHeader(_ref3) {
                var month = _ref3.month,
                    onMonthSelect = _ref3.onMonthSelect,
                    onYearSelect = _ref3.onYearSelect;
                return (

                    // Month/Year Calendar Hack
                    //
                    // The month/year variant of the calendar has an issue where the transitions from one month
                    // to another gets 'stuck' when using the month selector; you wind up with the calendar
                    // half-shifted out of the viewport. This only happens when you put a MUI select in the month
                    // header; something about it throws off the fragile timing of the transition in the bowels
                    // of react-dates.
                    //
                    // The most consistent/least intrusive way we've found to solve this is by making the month/
                    // year calendar *vertically*-oriented. This appears to satisfy the transition that is
                    // causing us so many problems, without any visual impact to the component (since we only
                    // show one calendar at a time).
                    //
                    // We had to add a few CSS tweaks to make this work (see the monthYearPickerFix class, above).
                    // They are only applied for the month/year version of the calendar.

                    React.createElement(
                        'div',
                        { className: cn(classes.headerControls, classes.monthYearSelector) },
                        React.createElement(
                            'div',
                            null,
                            _this3.buildSelector(moment.months().map(function (monthLabel, value) {
                                return React.createElement(
                                    MenuItem,
                                    {
                                        classes: {
                                            root: classes.monthYearSelectMenuItem,
                                            selected: classes.monthYearSelectMenuItemSelected
                                        },
                                        key: value,
                                        value: value,
                                        disableRipple: true,
                                        disableTouchRipple: true
                                    },
                                    monthLabel
                                );
                            }), function (e) {
                                onMonthSelect(month, e.target.value);
                            }, month.month(), 'month')
                        ),
                        React.createElement(
                            'div',
                            null,
                            _this3.buildSelector(range(startYear, endYear + 1, 1).map(function (year) {
                                return React.createElement(
                                    MenuItem,
                                    {
                                        classes: {
                                            root: classes.monthYearSelectMenuItem,
                                            selected: classes.monthYearSelectMenuItemSelected
                                        },
                                        key: year,
                                        value: year,
                                        disableRipple: true,
                                        disableTouchRipple: true
                                    },
                                    moment(year, 'YYYY').format('YYYY')
                                );
                            }), function (e) {
                                onYearSelect(month, e.target.value);
                            }, month.year(), 'year')
                        )
                    )
                );
            };

            // figure out what kind of header to use
            var header = null;

            var headerType = this.props.monthSelectionVariant ? this.props.monthSelectionVariant : this.props.headerVariant.headerType;

            switch (headerType) {

                case INCREMENTAL_SELECTION:
                case INCREMENTAL_NAVIGATION:
                    header = incrementalNavHeader;
                    break;

                case INCREMENTAL_NAVIGATION_WITH_TOOLBAR:
                    header = toolbarIncrementalNavHeader;
                    break;

                case MONTH_YEAR_SELECTION:
                case MONTH_YEAR_NAVIGATION:
                    header = monthYearSelectionHeader;
                    break;

                default:
                    header = incrementalNavHeader;
                    console.error('Unknown headerVariant: ' + this.props.headerVariant.headerType);
                    break;

            }

            var today = moment();

            var DatePickerPropOverrides = {
                isRTL: isRTL,
                inputIconPosition: ICON_AFTER_POSITION,
                orientation: useMonthYearHeader ? VERTICAL_ORIENTATION : HORIZONTAL_ORIENTATION,
                customInputIcon: React.createElement(
                    React.Fragment,
                    null,
                    React.createElement(
                        'span',
                        { className: classes.srOnly },
                        edsContext.formatMessage('component.DatePicker.inputCalendarIconButton')
                    ),
                    React.createElement(CalendarIcon, null)
                ),
                numberOfMonths: 1,
                displayFormat: DEFAULT_DISPLAY_FORMAT,
                weekDayFormat: DEFAULT_WEEK_DAY_FORMAT,
                anchorDirection: isRTL ? ANCHOR_RIGHT : ANCHOR_LEFT,
                screenReaderInputMessage: screenReaderInputMessage,
                placeholder: placeholder,

                // override the date cell render so we can apply our own styles and highlight today's date
                renderDayContents: function renderDayContents(day) {
                    var dayClasses = 'CalendarDateCell ' + (highlightToday && day.isSame(today, 'day') ? ' today' : '');
                    return React.createElement(
                        'div',
                        { className: dayClasses },
                        day.date()
                    );
                },

                onFocusChange: function onFocusChange(focused) {

                    // this is to get past react-dates' stubborn insistence on closing whenever you click
                    // outside the calendar; selecting a dropdown item is considered an outside click, so
                    // without this code the calendar would close (in non-standalone mode) whenever you
                    // select a new date/year
                    if (focused.focused || !useMonthYearHeader || variant === STANDALONE || variant === STANDALONE_WEEK) {
                        _this3.props.onFocusChange(focused);
                    } else {

                        // if the month/year selection menu is visible, abort the close
                        if (document.getElementById(_this3.selectorId)) {
                            _this3.props.onFocusChange({ focused: true });
                        } else {
                            _this3.props.onFocusChange(focused);
                        }
                    }
                },

                onClose: function onClose() {

                    // if the appendToBody prop is specified, users might run afoul of a react-date bug
                    // where, if you invoke a date picker calendar while another one is already open,
                    // react-date's scroll-disabling code gets confused, and turns off scrolling forever
                    // (or until the page refreshes, whichever comes first)

                    // work around this by explicitly setting overflow-y back to its previous value,
                    // whenever a calendar closes

                    if (_this3.props.appendToBody) {

                        setTimeout(function () {

                            // both html & body tags might have their overflows disabled (it appears to be
                            // browser-dependent); so check both, and reset them if necessary
                            var html = document.querySelector('html');
                            var body = document.querySelector('body');

                            if (html.style.overflowY === 'hidden' && _this3.htmlOverflowY !== 'hidden') {
                                html.style.overflowY = _this3.htmlOverflowY;
                            }

                            if (body.style.overflowY === 'hidden' && _this3.bodyOverflowY !== 'hidden') {
                                body.style.overflowY = _this3.bodyOverflowY;
                            }
                        }, 1);
                    }

                    // if the caller provided their own onClose, call it
                    if (_this3.props.onClose) {
                        _this3.props.onClose();
                    }
                },

                // if we're showing month selectors, don't allow selections of years outside of the
                // given year range
                isOutsideRange: this.props.isOutsideRange ? this.props.isOutsideRange : useMonthYearHeader ? function (day) {
                    return day.year() < startYear || day.year() > endYear;
                } : function () {
                    return false;
                },

                // introduce our own custom header
                renderMonthElement: header,

                enableOutsideDays: true,
                daySize: daySize,
                hideKeyboardShortcutsPanel: true,
                transitionDuration: 0
            };

            var autoPositionOverrides = {};

            if (autoPosition && this.datePickerRef.current && this.props.focused) {

                var rect = this.datePickerRef.current.getBoundingClientRect();
                var anchorDirection = void 0;
                // determine if the datepicker will fit in the available vertical space, and if not
                // if it makes more sense to open it *above* the field
                var verticalFit = rect.bottom + AUTOPOSITION_CALENDAR_HEIGHT <= document.documentElement.clientHeight;
                var bottomDelta = document.documentElement.clientHeight - rect.bottom;
                var topDelta = rect.top;
                var openDirection = !verticalFit && bottomDelta <= topDelta ? OPEN_UP : OPEN_DOWN;

                // determine if the datepicker will fit in the available horizontal space, and if not
                // if it makes more sense to anchor it to the *right* of the field
                if (isRTL) {
                    var horizontalFit = rect.right + AUTOPOSITION_CALENDAR_WIDTH <= document.documentElement.clientWidth;
                    var rightDelta = document.documentElement.clientWidth - rect.right;
                    var leftDelta = rect.left;
                    anchorDirection = !horizontalFit && rightDelta <= leftDelta ? ANCHOR_LEFT : ANCHOR_RIGHT;
                } else {
                    var _horizontalFit = rect.left + AUTOPOSITION_CALENDAR_WIDTH <= document.documentElement.clientWidth;
                    var _leftDelta = document.documentElement.clientWidth - rect.left;
                    var _rightDelta = rect.right;
                    anchorDirection = !_horizontalFit && _leftDelta <= _rightDelta ? ANCHOR_RIGHT : ANCHOR_LEFT;
                }

                // add positioning overrides
                autoPositionOverrides = {
                    openDirection: openDirection,
                    anchorDirection: anchorDirection
                };
            }

            // make sure the incoming date is a valid moment object
            var isDateValid = isNil(date) || date.isValid && date.isValid();

            if (!isDateValid) {
                console.warn('Invalid date supplied to DatePicker');
            }

            // make sure the date we were given is a valid moment object; if it's not,
            // clear it out (react-dates throws a TypeError when it's given an invalid
            // moment object)
            var currentDate = isDateValid ? date : null;

            var datePicker = variant === STANDALONE || variant === STANDALONE_WEEK ?
            // Standalone
            React.createElement(
                'div',
                {
                    dir: dirString,
                    className: cn(classes.root, classes.standalone, (_cn = {}, _defineProperty(_cn, classes.monthYearPickerFix, useMonthYearHeader), _defineProperty(_cn, classes.standaloneRTL, isRTL && useMonthYearHeader), _defineProperty(_cn, classes.standaloneWeek, variant === STANDALONE_WEEK), _cn), className)
                },
                React.createElement(DayPickerSingleDateController, Object.assign({}, omit(DatePickerPropOverrides, INPUT_SPECIFIC_PROPS), omit(props, INPUT_SPECIFIC_PROPS), {
                    date: currentDate
                }))
            ) :
            // With input
            React.createElement(
                FormControl,
                Object.assign({
                    className: cn(classes.root, (_cn2 = {}, _defineProperty(_cn2, classes.focused, props.focused), _defineProperty(_cn2, classes.monthYearPickerFix, useMonthYearHeader), _defineProperty(_cn2, classes.showPlaceholder, props.focused), _defineProperty(_cn2, classes.fullWidth, fullWidth), _defineProperty(_cn2, cn(classes.noLabel, classes.noLabelMedium), !hasLabel && size !== 'small'), _defineProperty(_cn2, cn(classes.noLabel, classes.noLabelSmall), !hasLabel && size === 'small'), _defineProperty(_cn2, classes.error, error), _defineProperty(_cn2, classes.focusedError, error && props.focused), _defineProperty(_cn2, classes.smallDatePicker, size === 'small'), _cn2), className),
                    fullWidth: fullWidth,
                    error: error
                }, FormControlProps),
                hasLabel && React.createElement(
                    InputLabel,
                    Object.assign({
                        htmlFor: props.id,
                        classes: {
                            formControl: classes.inputLabelFormControl,
                            shrink: classes.inputLabelShrink
                        },
                        className: cn(_defineProperty({}, classes.inputLabelSmall, size === 'small')),
                        shrink: shouldShrink,
                        error: error
                    }, InputLabelProps),
                    label
                ),
                React.createElement(
                    ClickAwayListener,
                    { onClickAway: this.handleClose },
                    React.createElement(ABSingleDatePicker, Object.assign({
                        id: this.singleDatePickerId
                    }, DatePickerPropOverrides, props, autoPositionOverrides, {
                        date: currentDate
                    }))
                ),
                helperText && React.createElement(
                    FormHelperText,
                    Object.assign({ id: helperTextId }, FormHelperTextProps),
                    helperText
                )
            );

            // if we're autopositioning, wrap the datepicker in a span so that
            // it can be referenced by the autopositioning algorithm
            datePicker = React.createElement(
                'span',
                { ref: this.datePickerRef },
                datePicker
            );

            return datePicker;
        }
    }]);

    return DatePicker;
}(React.Component);

DatePicker.defaultProps = {
    placeholder: DEFAULT_PLACEHOLDER,
    disableScroll: false,
    displayFormat: DEFAULT_DISPLAY_FORMAT,
    enableOutsideDays: true,
    firstDayOfWeek: 0,
    inputIconPosition: ICON_AFTER_POSITION,
    isDayBlocked: function isDayBlocked() {
        return false;
    },
    isDayHighlighted: function isDayHighlighted() {
        return false;
    },
    isOutsideRange: null,
    isRTL: false,
    keepOpenOnDateSelect: false,
    openDirection: OPEN_DOWN,
    variant: WITH_TEXT_FIELD,
    weekDayFormat: DEFAULT_WEEK_DAY_FORMAT,
    phrases: SingleDatePickerPhrases,
    headerVariant: { headerType: INCREMENTAL_NAVIGATION },
    startYear: moment().year() - 3,
    endYear: moment().year() + 3,
    autoPosition: false,
    size: 'medium',
    daySize: DEFAULT_DAY_SIZE,
    highlightToday: false
};

DatePicker.propTypes = {
    /**
     * @ignore
     * Added to allow for global JSS styling (and theme usage).
     */
    classes: PropTypes.object,
    /**
     * @ignore
     */
    edsContext: PropTypes.object,
    /**
     * @ignore
     */
    'aria-label': PropTypes.string,

    /****
     * FormControl, Input
     ****/
    /**
     * Custom class name to be applied on the `div` when `variant="standalone"` or the `FormControl`
     * when `variant="with-text-field"` (default).
     */
    className: PropTypes.string,
    /**
     * Mark the `FormControl`, `FormHelperText` and `Input` in an error state.
     */
    error: PropTypes.bool,
    /**
     * The helper text content.
     */
    helperText: PropTypes.node,
    /**
     * Input label text. This is rendered as the child within the `InputLabel` component.
     */
    label: PropTypes.string,
    /**
     * Properties applied to the [`FormControl`](#/components/FormControl) element.
     */
    FormControlProps: PropTypes.object,
    /**
     * Properties applied to the [`FormHelperText`](#/components/FormControl) element.
     */
    FormHelperTextProps: PropTypes.object,
    /**
     * Render the input field full width of the parent container.
     */
    fullWidth: PropTypes.bool,
    /**
     * Properties applied to the [`InputLabel`](#/components/FormControl) element.
     */
    InputLabelProps: PropTypes.object,
    /**
     * Render the `DatePicker` with input field or as a standalone calendar UI.
     *
     * Options:
     *
     * * `WITH_TEXT_FIELD` ('with-text-field')
     * * `STANDALONE` ('standalone')
     * * `STANDALONE_WEEK` ('standalone_week')
     *
     * These contstants can be imported from `@ellucian/react-design-system/core/DatePicker`.
     */
    variant: PropTypes.oneOf([WITH_TEXT_FIELD, STANDALONE, STANDALONE_WEEK]),

    /****
     * Required props for a function interactive SingleDatePicker
     ****/
    /**
     * The selected date to display in the `DatePicker` input field.
     *
     * This prop expects a `moment` object.
     */
    date: momentPropTypes.momentObj,
    /**
     * Function that handles setting the date.
     * This function receives a `date` parameter.
     *
     * i.e. `date => this.setState({ date })`
     */
    onDateChange: PropTypes.func.isRequired,
    /**
     * Handles the focused state of the component.
     */
    focused: PropTypes.bool,
    /**
     * Function to set whether the `DatePicker` has a focused state or not.
     *
     * i.e. `({ focused }) => this.setState({ focused })`
     */
    onFocusChange: PropTypes.func.isRequired,

    /**
     * **DEPRECATED**
     *
     * How months are selected; incrementally, or by month/year:
     *   * `INCREMENTAL_SELECTION`: Shows next/previous navigation arrows on the date picker
     *   * `MONTH_YEAR_SELECTION`: Shows month/year selection dropdowns on the date picker
     *
     * Note: If month/year selection is enabled, the calendar will not allow selection of dates
     * before the start year, or after the end year.
     *
     */
    monthSelectionVariant: PropTypes.oneOf([INCREMENTAL_SELECTION, MONTH_YEAR_SELECTION]),

    /**
     * The first year in the year selection dropdown. Only applies when
     * `monthSelectionVariant` === `MONTH_YEAR_SELECTION` or `headerVariant.headerType` === `MONTH_YEAR_NAVIGATION`.
     *
     * Defaults to three years before current year.
     */
    startYear: PropTypes.number,

    /**
     * The last year in the year selection dropdown. Only applies when
     * `monthSelectionVariant` === `MONTH_YEAR_SELECTION` or `headerVariant.headerType` === `MONTH_YEAR_NAVIGATION`.
     *
     * Defaults to three years after current year.
     */
    endYear: PropTypes.number,

    /****
     * Input related props
     ****/
    /**
     * A unique identifier of the `DatePicker` to assist with ARIA attribute.
     */
    id: PropTypes.string,
    /**
     * Placeholder text in the input field.
     */
    placeholder: PropTypes.string,
    /**
     * Set the input field to disabled.
     */
    disabled: PropTypes.bool,
    /**
     * Set the input field to required.
     */
    required: PropTypes.bool,
    /**
     * Set the input to `readOnly`. This prevents the users from being able to type into the input field. The date can only be selected from the `DatePicker` UI.
     */
    readOnly: PropTypes.bool,
    /**
     * Inform screen reader users of the date format, minimum days, blocked out dates, etc.
     */
    screenReaderInputMessage: PropTypes.string,

    // NOTE: Hidden until needed
    // /**
    //  * If a date is selected, the Calendar icon will be swapped out with a Clear icon.
    //  */
    // showClearDate: PropTypes.bool,
    // NOTE: Hidden until needed
    // /**
    //  * Render a custom close icon. `showClearDate` has to be `true`.
    //  */
    // customCloseIcon: PropTypes.node,
    // NOTE: Hidden until needed
    // /**
    //  * Show the default input icon.
    //  */
    // showDefaultInputIcon: PropTypes.bool,

    /**
     * Render the icon before the input, or after.
     *
     * Options are `ICON_BEFORE_POSITION` ('before') or `ICON_AFTER_POSITION` ('after') and must be imported from `@ellucian/react-design-system/core/DatePicker`.
     */
    inputIconPosition: PropTypes.oneOf([ICON_BEFORE_POSITION, ICON_AFTER_POSITION]),

    // NOTE: Hidden until needed
    // /**
    //  * Render a custom input icon. `showDefaultInputIcon` has to be `true`.
    //  */
    // customInputIcon: PropTypes.node,
    // NOTE: Hidden until needed
    // /**
    //  * Render the `DatePicker` with no border.
    //  */
    // noBorder: PropTypes.bool,

    /**
     * Render the `DatePicker` with `display` set to `'block'`.
     */
    block: PropTypes.bool,

    // NOTE: Hidden until needed
    // /**
    //  * Render `DatePicker` with size small.
    //  */
    // small: PropTypes.bool,
    // NOTE: Hidden until needed
    // /**
    //  * Render `DatePicker` with size regular.
    //  */
    // regular: PropTypes.bool,
    // NOTE: Hidden until needed
    // /**
    //  * Set the vertical spacing between the input field and the calendar UI.
    //  */
    // verticalSpacing: PropTypes.number,
    // NOTE: Hidden until needed
    // /**
    //  * Keep focus on the `input` after selecting a date.
    //  */
    // keepFocusOnInput: PropTypes.bool,

    /****
     * Calendar presentation and interaction related props
     ****/
    /**
     * Customize the month text rendered in the `DatePicker` UI.
     *
     * i.e. month => momentJalaali(month).format('jMMMM jYYYY') or 'MM YY'
     */
    renderMonthText: PropTypes.oneOfType([PropTypes.string, PropTypes.func]), // (month) => PropTypes.string,

    // NOTE: Hidden until needed
    // /**
    //  * Render custom month calendar.
    //  *
    //  * See the airbnb 'single month, custom caption' DayPickerSingleDateController example.
    //  *
    //  * i.e. `({ month, onMonthSelect, onYearSelect }) => <CustomMonthComponent />`
    //  */
    // renderMonthElement: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    // NOTE: Hidden until needed
    // /**
    //  * Define the orientation of the `DatePicker` calendar UI.
    //  */
    // orientation: PropTypes.oneOf([HORIZONTAL_ORIENTATION, VERTICAL_ORIENTATION]),

    /**
     * Set the direction of the calendar UI to be anchored to the left or the right of the input.
     *
     * Options are `ANCHOR_LEFT` ('left') or `ANCHOR_RIGHT` ('right') and must be imported from `@elluciann/react-design-system/core/DatePicker`.
     */
    anchorDirection: PropTypes.oneOf([ANCHOR_LEFT, ANCHOR_RIGHT]),
    /**
     * Open the `DatePicker` from the top or bottom of the input. This is useful to use when the `DatePicker` selection is near the bottom of the screen.
     *
     * Options are `OPEN_DOWN` ('down') or `OPEN_UP` ('up') and must be imported from `@ellucian/react-design-system/core/DatePicker`.
     */
    openDirection: PropTypes.oneOf([OPEN_DOWN, OPEN_UP]),

    // NOTE: Hidden until needed
    // horizontalMargin: PropTypes.number,
    // withPortal: PropTypes.bool,
    // withFullScreenPortal: PropTypes.bool,

    /**
     * Render the `DatePicker` as the last child in the HTML `body` element.
     *
     * This is particularly useful when rendering a `DatePicker` in a `Dialog`.
     */
    appendToBody: PropTypes.bool,
    /**
     * Disable the scrolling behavior of the parent container when the `DatePicker` is open.
     */
    disableScroll: PropTypes.bool,
    /**
     * Function to set whether the initial month shown.
     *
     * i.e. This function will force the `DatePicker` to open 10 months from today's month: `() => moment().add(10, 'months')`
     */
    initialVisibleMonth: PropTypes.func,
    /**
     * Set the first day of the week.
     *
     * Options are `0`, `1`, `2`, `3`, `4`, `5`, or `6`.
     */
    firstDayOfWeek: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6]),
    /**
     * Positions the DatePicker so that it doesn't go off the screen.
     *
     * If this is specified, the `openDirection` and `anchorDirection` props are ignored.
     */
    autoPosition: PropTypes.bool,
    // NOTE: Hidden until needed
    // /**
    //  * Set the number of months visible when the DatePicker is open.
    //  */
    // numberOfMonths: PropTypes.number,

    /**
     * Keep the `DatePicker` open after selecting a date.
     */
    keepOpenOnDateSelect: PropTypes.bool,

    // NOTE: Hidden until needed
    // /**
    //  * Open the `DatePicker` back up when the date is cleared.
    //  */
    // reopenPickerOnClearDate: PropTypes.bool,
    // NOTE: Hidden until needed
    // /**
    //  * Render custom calendar information.
    //  */
    // renderCalendarInfo: PropTypes.func,
    // NOTE: Hidden until needed
    // /**
    //  * Define where to render the additional calendar info. Use with `renderCalendarInfo`.
    //  */
    // calendarInfoPosition: PropTypes.oneOf([
    //     INFO_POSITION_TOP,
    //     INFO_POSITION_BOTTOM,
    //     INFO_POSITION_BEFORE,
    //     INFO_POSITION_AFTER,
    // ]),
    // NOTE: Hidden until needed
    // /**
    //  * Hide the keyboard shortcuts panel.
    //  */
    // hideKeyboardShortcutsPanel: PropTypes.bool,

    /**
     * @ignore
     *
     * **BETA**
     *
     * Customize the size of the days. This number should be a non-negative integer.
     *
     * Note: changing this value may cause display issues if the `monthSelectionVariant` prop is set to `MONTH_YEAR_SELECTION`.
     */
    daySize: PropTypes.number,

    /**
     * **DEPRECATED** Will be determined by the props passed to [`EDSApplication`](#/components/EDSApplication).
     *
     * Change the reading direction of the `DatePicker`.
     */
    isRTL: PropTypes.bool,

    // NOTE: Hidden until needed
    // /**
    //  * Change the duration of the month transition, measured in `milliseconds`.
    //  */
    // verticalHeight: PropTypes.number,
    // /**
    //  * Change the duration of the month transition, measured in `milliseconds`.
    //  */
    // transitionDuration: PropTypes.number,
    // /**
    //  * Change the horizontal month padding.
    //  */
    // horizontalMonthPadding: PropTypes.number,

    /****
     * Navigation related props
     ****/
    // NOTE: Hidden until needed
    // /**
    //  * Render a custom element in place of the `previous` chevron button.
    //  */
    // navPrev: PropTypes.node,
    // /**
    //  * Render a custom element in place of the `next` chevron button.
    //  */
    // navNext: PropTypes.node,

    /**
     * Event handler called when the `previous` chevron button is clicked.
     */
    onPrevMonthClick: PropTypes.func,
    /**
     * Event handler called when the `next` chevron button is clicked.
     */
    onNextMonthClick: PropTypes.func,
    /**
     * Event handler called when the `DatePicker` is closed.
     */
    onClose: PropTypes.func,

    /****
     * Day presentation and interaction props
     ****/
    // NOTE: Hidden until needed
    // /**
    //  * Customize the contents of the Calendar day elements.
    //  *
    //  * See https://github.com/airbnb/react-dates/blob/master/stories/DateRangePicker_day.js#L136 and
    //  * https://github.com/airbnb/react-dates/blob/master/src/components/CustomizableCalendarDay.jsx.
    //  */
    // renderCalendarDay: PropTypes.func,

    // NOTE: We do not want consumers to customize this prop
    // it should be removed in an upcoming release.
    /**
     * Toggles if days outside of the shown month are visible.
     *
     * i.e. When March 2019 is shown, the overlapping dates in February and April will show.
     */
    enableOutsideDays: PropTypes.bool,
    /**
     * A function that blocks specific days.
     * This function receives a `day` (a `moment` object) parameter.
     *
     * i.e. This function disables all Fridays: `day => moment.weekdays(day.weekday()) === 'Friday'`
     */
    isDayBlocked: PropTypes.func,
    /**
     * A function that blocks a range of dates.
     * This function receives a `day` (a `moment` object) parameter.
     *
     * Note: `isInclusivelyAfterDay` is a utility function provided by `react-dates/utils`.
     *
     * i.e. This function allows for the next two weeks to be available: `day => !isInclusivelyAfterDay(day, moment()) || isInclusivelyAfterDay(day, moment().add(2, 'weeks'))`
     */
    isOutsideRange: PropTypes.func,
    /**
     * A function that highlights a specific dates.
     * This function receives a `day` (a `moment` object) parameter.
     *
     * Note: `isSameDay` is a utility function provided by `react-dates/utils`.
     *
     * i.e. This function highlights dates that match a `moment` date stored in an `Array`: `day1 => datesList.some(day2 => isSameDay(day1, day2))`
     */
    isDayHighlighted: PropTypes.func,

    /****
     * Internationalization props
     ****/

    /**
     * Date format of the date displayed in the input.
     *
     * See https://momentjs.com/docs/#/displaying/format/ for more information.
     *
     * i.e. `() => moment.localeData().longDateFormat('L')` or `'MM DD, YYYY'`
     */
    displayFormat: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    /**
     * Date format of the month displayed.
     *
     * See https://momentjs.com/docs/#/displaying/format/ for more information.
     *
     * i.e. `'MMM'`
     */
    monthFormat: PropTypes.string,
    /**
     * Date format of weekday headers.
     *
     * See https://momentjs.com/docs/#/displaying/format/ for more information.
     *
     * i.e. `'dd'`
     */
    weekDayFormat: PropTypes.string,
    /**
     * Override default component phrases.
     *
     * See https://github.com/airbnb/react-dates/blob/master/src/defaultPhrases.js for more information.
     */
    phrases: PropTypes.shape(getPhrasePropTypes(SingleDatePickerPhrases)),
    /**
     * ARIA label given to the focus-able days.
     */
    dayAriaLabelFormat: PropTypes.string,
    /**
     * The size of the `DatePicker`. Small size is recommended to be used within the `TableEditableCell` component.
     */
    size: PropTypes.oneOf(['small', 'medium']),

    /**
     * Highlight today's date in the calendar.
     */
    highlightToday: PropTypes.bool,

    /**
     * How months are selected; incrementally, or by month/year:
     *
     * Use `headerType` to specify the type of header. Valid values are:
     *
     *   * `INCREMENTAL_NAVIGATION`: A header with next/previous navigation arrows
     *   * `INCREMENTAL_NAVIGATION_WITH_TOOLBAR`: A header with next/previous navigation arrows and a toolbar
     *   * `MONTH_YEAR_NAVIGATION`: Shows month/year selection dropdowns on the date picker
     *
     * Use `toolbarItems` to specify which items should appear in the toolbar. It accepts an array of
     * nodes. It is only valid with the `INCREMENTAL_NAVIGATION_WiTH_TOOLBAR` header type.
     *
     * **Note**: If month/year selection is enabled, the calendar will not allow selection of dates
     * before the start year, or after the end year.
     */
    headerVariant: PropTypes.shape({
        headerType: PropTypes.oneOf([INCREMENTAL_NAVIGATION, INCREMENTAL_NAVIGATION_WITH_TOOLBAR, MONTH_YEAR_NAVIGATION]),
        toolbarItems: PropTypes.arrayOf(PropTypes.node)
    })

};

export default withEDSContext(withStyles(styles)(DatePicker));