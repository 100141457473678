function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import makeStyles from './../makeStyles';
import MenuItem from '@material-ui/core/MenuItem';

import { spacing40, lineHeightParagraphMedium } from '../styles/tokens';

var useStyles = makeStyles(function (theme) {
    return {
        root: {
            // Since menu list item is pulling from MUI, have to override to match recommended list
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.typography.fontSize,
            fontWeight: theme.typography.fontWeightRegular,
            lineHeight: lineHeightParagraphMedium,
            textOverflow: 'ellipsis',
            '&.padding': {
                padding: spacing40 + ' 1.25rem',
                display: 'block'
            },
            '&:hover, &:focus': {
                color: '' + theme.palette.grey[600],
                backgroundColor: theme.palette.grey[250]
            },
            '&:active': {
                backgroundColor: theme.palette.ctaColor.active,
                color: theme.palette.grey['100']
            }
        }
    };
});

/**
 * An item in an ActionMenu.
 */
var ActionMenuItem = React.forwardRef(function (props, ref) {
    var localClasses = useStyles();

    var className = props.className,
        classes = props.classes,
        disabled = props.disabled,
        _onClick = props.onClick,
        handleClose = props.handleClose,
        rest = _objectWithoutProperties(props, ['className', 'classes', 'disabled', 'onClick', 'handleClose']);

    return React.createElement(MenuItem, Object.assign({}, rest, {
        className: cn(localClasses.root, className, 'padding'),
        disabled: disabled,
        onClick: function onClick(e) {
            _onClick && _onClick(e);
            handleClose && handleClose(e);
        },
        classes: classes,
        ref: ref
    }));
});

ActionMenuItem.muiName = 'ActionMenuItem';
ActionMenuItem.displayName = 'ActionMenuItem';

ActionMenuItem.propTypes = {
    /**
     * The content of the component.
     */
    children: PropTypes.node.isRequired,
    /**
     * @ignore
     *
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object,
    /**
     * If `true`, the action menu item will be disabled.
     */
    disabled: PropTypes.bool,
    /**
     * Callback triggered when action menu item is clicked.
     */
    onClick: PropTypes.func
};

ActionMenuItem.defaultProps = {
    disabled: false
};

export default ActionMenuItem;