// Ellucian's Default theme
import { colorBackgroundAlertDefault, colorBackgroundAlertError, colorBackgroundAlertNeutral, colorBackgroundAlertSuccess, colorBackgroundAlertWarning, colorBackgroundDefault, colorBorderGlobalFocus, colorBrandNeutral100, colorBrandNeutral400, colorBrandNeutral600, colorBrandPrimary, colorBrandSecondary, colorCtaBlueActive, colorCtaBlueBase, colorCtaBlueHover, colorCtaBlueTint, colorFillAlertError, colorFillAlertNeutral, colorFillAlertSuccess, colorFillAlertWarning, colorTextAlertDefault, colorTextAlertError, colorTextAlertNeutral, colorTextAlertSuccess, colorTextAlertWarning, colorTextDisabled, colorTextNeutral100, colorTextPrimary, colorTextSecondary } from '@ellucian/react-design-system/core/styles/tokens';

var palette = {
    // Iris
    primary: {
        main: colorBrandPrimary,
        contrastText: colorTextNeutral100
    },
    // Fountain
    secondary: {
        main: colorBrandSecondary,
        contrastText: colorTextNeutral100
    },
    // CTA Blue
    ctaColor: {
        base: colorCtaBlueBase,
        hover: colorCtaBlueHover,
        active: colorCtaBlueActive,
        tint: colorCtaBlueTint
    },
    // Disabled
    disabled: {
        background: colorBrandNeutral400
    },
    // Global Focus
    focus: colorBorderGlobalFocus,
    // Status: Error, Warning, Pending, Success
    status: {
        default: {
            text: colorTextAlertDefault,
            background: colorBackgroundAlertDefault
        },
        error: {
            text: colorTextAlertError,
            background: colorBackgroundAlertError,
            fill: colorFillAlertError
        },
        success: {
            text: colorTextAlertSuccess,
            background: colorBackgroundAlertSuccess,
            fill: colorFillAlertSuccess
        },
        neutral: {
            text: colorTextAlertNeutral,
            background: colorBackgroundAlertNeutral,
            fill: colorFillAlertNeutral
        },
        warning: {
            text: colorTextAlertWarning,
            background: colorBackgroundAlertWarning,
            fill: colorFillAlertWarning
        }
    },
    common: {
        black: colorBrandNeutral600,
        white: colorBrandNeutral100
    },
    text: {
        primary: colorTextPrimary,
        secondary: colorTextSecondary,
        disabled: colorTextDisabled,
        white: colorTextNeutral100
    },
    background: {
        default: colorBackgroundDefault
    },
    tertiary: {
        purple: {
            100: '#F8EEF6',
            200: '#ECCCE6',
            300: '#DFAAD6',
            400: '#D389C6',
            500: '#C667B6',
            600: '#BA46A6'
        },
        iris: {
            100: '#EEEEFA',
            200: '#CFCFF2',
            300: '#B0B0EA',
            400: '#9191E2',
            500: '#7272D9',
            600: '#5353D1'
        },
        fountain: {
            100: '#EFF7FF',
            200: '#CEE7FF',
            300: '#AFD8FF',
            400: '#90C9FF',
            500: '#70BAFF',
            600: '#51ABFF'
        },
        meadow: {
            100: '#E8F9F5',
            200: '#BFEDE2',
            300: '#96E2CF',
            400: '#6CD6BC',
            500: '#43CBA9',
            600: '#1ABF96'
        },
        kiwi: {
            100: '#F4FAEF',
            200: '#E1F2D3',
            300: '#CEEAB7',
            400: '#BBE29B',
            500: '#A8D97E',
            600: '#95D162'
        },
        saffron: {
            100: '#FDF9EA',
            200: '#FBEFC3',
            300: '#F8E59C',
            400: '#F5DB75',
            500: '#F2D14F',
            600: '#EFC728'
        },
        tangerine: {
            100: '#FFF4EB',
            200: '#FFDFC8',
            300: '#FFCAA4',
            400: '#FFB581',
            500: '#FFA15D',
            600: '#FF8C3A'
        }
    }
};

export default palette;