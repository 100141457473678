var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import RcInputNumber from 'rc-input-number';

import FormControl from '../../FormControl/FormControl';
import FormHelperText from '../../FormControl/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';

import withStyles from '../../styles';

import { borderRadiusMedium, borderWidthThick, borderWidthThin, boxShadowFocus, fontFamilyDefault, fontSizeDefault, fontSizeLarge, heightInputFieldsSmall, spacing40, spacing30, widthInputFields, zIndexTextFieldLabel } from '../../styles/tokens';

var styles = function styles(theme) {
    return {

        root: {
            '& .hedtech-number': {
                backgroundColor: '' + theme.palette.grey[100],
                width: widthInputFields,
                margin: 0,
                padding: 0,
                fontSize: fontSizeLarge,
                height: '3.25rem', // Needs to be 52px in order to match text field, accounting for border
                display: 'inline-block',
                verticalAlign: 'middle',
                border: borderWidthThin + ' solid ' + theme.palette.grey[400],
                borderRadius: borderRadiusMedium
            },

            '& .hedtech-number-focused': {
                boxShadow: boxShadowFocus
            },
            '& .hedtech-number-handler': {
                textAlign: 'center',
                height: 'calc(50% - 1px)',
                overflow: 'hidden',
                display: 'block',
                touchAction: 'none',
                fill: theme.palette.grey[500]
            },
            '& .hedtech-number-handler-active': {
                fill: theme.palette.ctaColor.active
            },
            '& .hedtech-number-handler--up-inner, .hedtech-number-handler-down-inner': {
                color: theme.palette.grey[600],
                userSelect: 'none'
            },
            '& .hedtech-number.hedtech-number-disabled': {
                // borderColor: '#d9d9d9',
                borderColor: theme.palette.grey[400],
                backgroundColor: theme.palette.grey[200],
                '&:hover': {
                    borderColor: theme.palette.grey[400]
                }
            },

            '& .hedtech-number-input-wrap': {
                overflow: 'hidden',
                height: '100%'
            },

            '& .hedtech-number-input': {
                display: 'flex',
                width: '100%',
                fontSize: fontSizeLarge,
                fontFamily: fontFamilyDefault,
                outline: 0,
                MozAppearance: 'textfield',
                border: 0,
                borderRadius: '0.75rem',
                background: 'none',
                padding: '1.375rem 1rem .625rem 1rem',
                '&::placeholder': {
                    opacity: 0,
                    color: theme.palette.grey[500]
                },
                '&::-webkit-input-placeholder': {
                    opacity: 0,
                    color: theme.palette.grey[500]
                },
                '&::-moz-placeholder': {
                    opacity: 0,
                    color: theme.palette.grey[500]
                },
                '&:-ms-input-placeholder': {
                    opacity: 0,
                    color: theme.palette.grey[500]
                },
                '&:-moz-placeholder': {
                    opacity: 0,
                    color: theme.palette.grey[500]
                },
                // Disable FF specific invalid input box-shadow
                '&:not(output):-moz-ui-invalid': {
                    boxShadow: 'none'
                }
            },

            // Handler containers
            '& .hedtech-number-disabled .hedtech-number-input ': {
                cursor: 'not-allowed',
                color: theme.palette.grey[500],
                backgroundColor: theme.palette.grey[200]
            },
            '& .hedtech-number-disabled .hedtech-number-handler': {
                backgroundColor: theme.palette.grey[200],
                '&:hover': {
                    fill: theme.palette.grey[500]
                }
            },
            '& .hedtech-number-disabled .hedtech-number-handler:hover': {
                color: theme.palette.grey[400],
                borderColor: theme.palette.grey[400]
            }
        },

        small: {

            '& .hedtech-number': {
                height: heightInputFieldsSmall
            },
            '& .hedtech-number input, &  .hedtech-number input:focus': {
                lineHeight: 'unset',
                padding: spacing30,
                fontSize: fontSizeDefault
            },
            '& $inputLabelFormControl': {
                top: '-0.30rem',
                left: '0.625rem',
                fontSize: fontSizeDefault
            },
            '& $inputLabelShrink': {
                display: 'none'
            }
        },

        // If focused, show placeholder text
        focused: {
            '& .hedtech-number-input': {
                '&::placeholder': {
                    opacity: 1
                },
                '&::-webkit-input-placeholder': {
                    opacity: 1
                },
                '&::-moz-placeholder': {
                    opacity: 1
                },
                '&:-ms-input-placeholder': {
                    opacity: 1
                },
                '&:-moz-placeholder': {
                    opacity: 1
                }
            }
        },

        // If there is no label
        // -- show placeholder text in middle of input
        noLabel: {
            '& .hedtech-number-input': {
                padding: spacing40,
                '&::placeholder': {
                    opacity: 1
                },
                '&::-webkit-input-placeholder': {
                    opacity: 1
                },
                '&::-moz-placeholder': {
                    opacity: 1
                },
                '&:-ms-input-placeholder': {
                    opacity: 1
                },
                '&:-moz-placeholder': {
                    opacity: 1
                }
            }
        },

        // Error styling
        error: {
            '& .hedtech-number': {
                border: borderWidthThick + ' solid ' + theme.palette.status.error.fill,
                '&:hover': {
                    border: borderWidthThick + ' solid ' + theme.palette.status.error.fill
                }
            }
        },
        focusedError: {
            '& .hedtech-number-focused': {
                boxShadow: '0 0 0 ' + borderWidthThin + ' ' + theme.palette.status.error.fill
            }
        },

        // Full width
        fullWidth: {
            '& .hedtech-number': {
                width: '100%'
            },
            '& .hedtech-number-input': {
                width: 'calc(100% - 33px)' // Keep room for the buttons on the right (32px width + 1px border-left)
            }
        },

        // InputLabel
        inputLabelRoot: {
            '&:hover': {
                cursor: 'text'
            }
        },
        inputLabelFormControl: {
            zIndex: zIndexTextFieldLabel,
            left: spacing40,
            transform: 'translate(0, 1.125rem) scale(1)' // 18px to match TextField
        },
        inputLabelShrink: {
            fontSize: 14,
            transform: 'translate(0, .625rem) scale(0.75)' // 10px to match TextField
        }
    };
};

// https://github.com/react-component/input-number

var InputNumber = function (_Component) {
    _inherits(InputNumber, _Component);

    function InputNumber(props) {
        _classCallCheck(this, InputNumber);

        var _this = _possibleConstructorReturn(this, (InputNumber.__proto__ || Object.getPrototypeOf(InputNumber)).call(this, props));

        _this.onFocus = function (e) {
            _this.setState({ shrink: true, focused: true });
            if (_this.props.onFocus) {
                _this.props.onFocus(e);
            }
        };

        _this.onBlur = function (e) {
            if (e.target.value.length !== 0) {
                _this.setState({ shrink: true, focused: false });
            } else if (e.target.value.length === 0) {
                _this.setState({ shrink: false, focused: false });
            }
            if (_this.props.onBlur) {
                _this.props.onBlur(e);
            }
        };

        var shouldShrink = false;

        if (props.value !== null) {
            shouldShrink = props.defaultValue >= 0 || props.value !== undefined || props.defaultValue !== undefined;
        }

        _this.state = {
            shrink: shouldShrink,
            focused: _this.props.autoFocus
        };
        return _this;
    }

    _createClass(InputNumber, [{
        key: 'render',
        value: function render() {
            var _cn,
                _this2 = this;

            var _props = this.props,
                classes = _props.classes,
                placeholderProp = _props.placeholder,
                readOnly = _props.readOnly,
                id = _props.id,
                size = _props.size,
                FormControlProps = _props.FormControlProps,
                fullWidth = _props.fullWidth,
                error = _props.error,
                labelProp = _props.label,
                InputLabelProps = _props.InputLabelProps,
                inputProps = _props.inputProps,
                inputRef = _props.inputRef,
                helperText = _props.helperText,
                FormHelperTextProps = _props.FormHelperTextProps,
                rest = _objectWithoutProperties(_props, ['classes', 'placeholder', 'readOnly', 'id', 'size', 'FormControlProps', 'fullWidth', 'error', 'label', 'InputLabelProps', 'inputProps', 'inputRef', 'helperText', 'FormHelperTextProps']);

            var label = labelProp,
                placeholder = placeholderProp;

            // Add asterisk to label or placeholder if required
            if (this.props.required) {
                if (label) {
                    label = labelProp + ' *';
                } else {
                    placeholder = placeholderProp + ' *';
                }
            }

            return React.createElement(
                FormControl,
                Object.assign({
                    className: cn(classes.root, (_cn = {}, _defineProperty(_cn, classes.small, size === 'small'), _defineProperty(_cn, classes.noLabel, !label), _defineProperty(_cn, classes.error, error), _defineProperty(_cn, classes.focusedError, error && this.state.focused), _defineProperty(_cn, classes.focused, !error && this.state.focused), _defineProperty(_cn, classes.fullWidth, fullWidth), _cn)),
                    fullWidth: fullWidth,
                    error: error
                }, FormControlProps),
                label && React.createElement(
                    InputLabel,
                    Object.assign({
                        id: this.props.id + '-label-text',
                        htmlFor: id,
                        shrink: this.state.shrink,
                        classes: {
                            root: classes.inputLabelRoot,
                            formControl: classes.inputLabelFormControl,
                            shrink: classes.inputLabelShrink
                        }
                    }, InputLabelProps),
                    label
                ),
                React.createElement(RcInputNumber, Object.assign({
                    'aria-labelledby': label ? this.props.id + '-label-text' : null,
                    'aria-describedby': helperText ? this.props.id + '-helper-text' : null,
                    placeholder: placeholder,
                    readOnly: readOnly,
                    ref: inputRef,
                    id: id
                }, inputProps, rest, {
                    onFocus: function onFocus(e) {
                        return _this2.onFocus(e);
                    },
                    onBlur: function onBlur(e) {
                        return _this2.onBlur(e);
                    },
                    prefixCls: 'hedtech-number'
                })),
                helperText && React.createElement(
                    FormHelperText,
                    Object.assign({
                        id: this.props.id + '-helper-text'
                    }, FormHelperTextProps),
                    helperText
                )
            );
        }
    }]);

    return InputNumber;
}(Component);

InputNumber.propTypes = {
    /**
     * @ignore
     */
    classes: PropTypes.object,
    /**
     * @ignore
     */
    onBlur: PropTypes.func,

    // FormControl
    /**
     * Properties applied to the `FormControl` element.
     */
    FormControlProps: PropTypes.object,
    /**
     * If `true`, the element will take up the full width of its container.
     */
    fullWidth: PropTypes.bool,
    /**
     * If `true`, display the FormControl in an error state
     */
    error: PropTypes.bool,
    /**
     * The size of the number field.
     */
    size: PropTypes.oneOf(['small', 'medium']),

    // InputLabel
    /**
     * The label content.
     */
    label: PropTypes.node,
    /**
     * Properties applied to the `InputLabel` element.
     */
    InputLabelProps: PropTypes.object,

    // InputProps
    /**
     * Properties applied to the `InputLabel` element.
     */
    InputProps: PropTypes.object,

    // input (HTML)
    /**
     * Properties applied to the native `input` element.
     */
    inputProps: PropTypes.object,
    /**
     * Use that property to pass a ref callback to the native input component.
     */
    inputRef: PropTypes.func,

    // FormHelperText
    /**
     * The helper text content.
     */
    helperText: PropTypes.node,
    /**
     * Properties applied to the `FormHelperText` element.
     */
    FormHelperTextProps: PropTypes.object,

    // RcInputNumber
    /**
     * Specifies the minimum value
     */
    min: PropTypes.number,
    /**
     * @ignore
     */
    onClick: PropTypes.func,
    /**
     * HTML input placeholder
     */
    placeholder: PropTypes.string,
    /**
     * Specifies the maximum value
     */
    max: PropTypes.number,
    /**
     * Specifies the valid number intervals
     */
    step: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /**
     * Specifies the precision length of value e.g. `precision={2} => 0.00`
     */
    precision: PropTypes.number,
    /**
     * Specifies that an InputNumber should be disabled
     */
    disabled: PropTypes.bool,
    /**
     * Whether focus input when click up or down button
     */
    focusOnUpDown: PropTypes.bool,
    /**
     * Specifies that the value is required
     */
    required: PropTypes.bool,
    /**
     * Specifies that an InputNumber should automatically get focus when the page loads
     */
    autoFocus: PropTypes.bool,
    /**
     * Specifies that an InputNumber is read only
     */
    readOnly: PropTypes.bool,
    /**
     * Specifies the name of an InputNumber
     */
    name: PropTypes.string,
    /**
     * Specifies the id of an InputNumber
     */
    id: PropTypes.string,
    /**
     * Specifies the value of an InputNumber
     */
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    /**
     * Specifies the defaultValue of an InputNumber
     */
    defaultValue: PropTypes.number,
    /**
     * Called when value of an InputNumber changed
     */
    onChange: PropTypes.func,
    /**
     * Called when an element gets focus
     */
    onFocus: PropTypes.func,
    /**
     * Root container style. such as {width:100}
     */
    style: PropTypes.object,
    /**
     * Specifies the format of the value presented
     * `(@param: value: number|string, @param displayValue: string)`
     */
    formatter: PropTypes.func,
    /**
     * Specifies the value extracted from formatter
     */
    parser: PropTypes.func,
    /**
     * Specifies a regex pattern to be added to the input number element - useful for forcing iOS to open the number pad instead of the normal keyboard (supply a regex of "\d*" to do this) or form validation
     */
    pattern: PropTypes.string,
    /**
     * Specifies the decimal separator
     */
    decimalSeparator: PropTypes.string
};

export default withStyles(styles)(InputNumber);