function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles/';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { StylesProvider, jssPreset } from '@material-ui/styles';
import { createEDSTheme } from '@ellucian/react-design-system/core/themes';
import { classNameGenerator } from '../styles/';
import { EDSContext } from '../EDSContext/EDSContext';
import CssBaseline from './internal/CssBaseline';
import { CssGlobalSpacing } from './../globalSpacing';
import config from './internal/edsconfig.js';
import cdn from './internal/urls';
import moment from 'moment';

var DEFAULT_LOCALE = 'en_US';
var DEFAULT_DIR = 'ltr';

/**
 * `EDSApplication` is the top-level component of an EDS application. All apps must be wrapped in this component.
 *
 * @done true
 * @updated false
 * @versionAdded v0.0.13
 * @examples
 *  EDSApplicationExample
 */
var EDSApplication = function EDSApplication(props) {
    // Configure JSS
    var jss = create({ plugins: [].concat(_toConsumableArray(jssPreset().plugins), [rtl({ enabled: props.dir === 'rtl' })]) });

    useEffect(function () {
        // if the caller wants us to automatically set the global moment locale, do so
        if (props.locale && props.autosetMomentLocale) {
            moment.locale(props.locale);
        }
    }, [props.locale]); // eslint-disable-line react-hooks/exhaustive-deps

    // Set direction prop in the theme
    props.theme.direction = props.dir;

    return React.createElement(
        EDSContext.Provider,
        { value: {
                dir: props.dir,
                locale: props.locale,
                labelOverrides: props.labelOverrides
            } },
        React.createElement(
            StylesProvider,
            { generateClassName: classNameGenerator, jss: jss },
            React.createElement(
                MuiThemeProvider,
                { theme: props.theme },
                React.createElement(CssBaseline, null),
                React.createElement(CssGlobalSpacing, null),
                props.children
            )
        )
    );
};

EDSApplication.muiName = 'EDSApplication';
EDSApplication.displayName = 'EDSApplication';

EDSApplication.propTypes = {
    /**
     * Whether to set the global moment context to the locale specified by the `locale` prop.
     */
    autosetMomentLocale: PropTypes.bool,
    /**
     * The nodes comprising the wrapped application.
     */
    children: PropTypes.node.isRequired,
    /**
     * **BETA** Several components are not yet RTL-compliant
     *
     * Directionality of the application.
     */
    dir: PropTypes.oneOf(['ltr', 'rtl']),
    /**
     * Overrides for the translated labels.
     */
    labelOverrides: PropTypes.object,
    /**
     * Locale to use for translations.
     */
    locale: PropTypes.string,
    /**
     * The application's theme.
     */
    theme: PropTypes.object
};

// If default props are needed, they must be set here
EDSApplication.defaultProps = {
    autosetMomentLocale: true,
    dir: DEFAULT_DIR,
    labelOverrides: {},
    locale: DEFAULT_LOCALE,
    theme: createEDSTheme('light')
};

export { config, cdn };
export default EDSApplication;