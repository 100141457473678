export var ChangePasswordError;
(function (ChangePasswordError) {
    ChangePasswordError[ChangePasswordError["Digits"] = 0] = "Digits";
    ChangePasswordError[ChangePasswordError["IsEmpty"] = 1] = "IsEmpty";
    ChangePasswordError[ChangePasswordError["LowerCase"] = 2] = "LowerCase";
    ChangePasswordError[ChangePasswordError["MinLength"] = 3] = "MinLength";
    ChangePasswordError[ChangePasswordError["SpecialCharacters"] = 4] = "SpecialCharacters";
    ChangePasswordError[ChangePasswordError["UpperCase"] = 5] = "UpperCase";
    ChangePasswordError[ChangePasswordError["NewPasswordValid"] = 6] = "NewPasswordValid";
    ChangePasswordError[ChangePasswordError["CurrentPasswordInvalid"] = 7] = "CurrentPasswordInvalid";
    ChangePasswordError[ChangePasswordError["Success"] = 8] = "Success";
    ChangePasswordError[ChangePasswordError["AlreadyUsed"] = 9] = "AlreadyUsed";
    ChangePasswordError[ChangePasswordError["ProviderError"] = 10] = "ProviderError";
    ChangePasswordError[ChangePasswordError["UnableToReset"] = 11] = "UnableToReset";
    ChangePasswordError[ChangePasswordError["UnableToChange"] = 12] = "UnableToChange";
})(ChangePasswordError || (ChangePasswordError = {}));
