function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { default as withStyles } from '../styles';
import { default as MuiTableCell } from '@material-ui/core/TableCell';
import { colorBackgroundAlertError, spacing20 } from '../styles/tokens';

var styles = {
  root: {
    paddingTop: spacing20,
    paddingBottom: spacing20
  },
  head: {},
  body: {},
  error: {
    backgroundColor: colorBackgroundAlertError
  }
};

/**
 * Table cell
 */
var TableEditableCell = React.forwardRef(function (props, ref) {
  var classes = props.classes,
      columnName = props.columnName,
      edit = props.edit,
      content = props.content,
      editableComponent = props.editableComponent,
      error = props.error,
      rest = _objectWithoutProperties(props, ['classes', 'columnName', 'edit', 'content', 'editableComponent', 'error']);

  var classOverrides = {
    root: classes.root,
    head: classes.head,
    body: classes.body
  };

  return React.createElement(
    MuiTableCell,
    Object.assign({}, rest, {
      'data-label': columnName || '',
      classes: classOverrides,
      className: classnames(_defineProperty({}, classes.error, error)),
      ref: ref
    }),
    edit ? editableComponent : content
  );
});

TableEditableCell.muiName = 'TableEditableCell';
TableEditableCell.displayName = 'TableEditableCell';

TableEditableCell.propTypes = {
  /**
   * Set the text-align on the table cell content. Monetary or generally number fields should
   * be right aligned as that allows you to add them up quickly in your head without having
   * to worry about decimals.
   */
  align: PropTypes.oneOf(['inherit', 'left', 'right', 'center', 'justify']),
  /**
   * Override or extend the styles applied to the component.
   */
  classes: PropTypes.object,
  /**
   * @ignore
   */
  className: PropTypes.string,
  /**
   * The component used for the root node. Either a string to use a DOM element or a component.
   */
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object]),
  /**
   * Sets the padding applied to the cell.
   */
  padding: PropTypes.oneOf(['default', 'checkbox', 'dense', 'none']),
  /**
   * Set scope attribute.
   */
  scope: PropTypes.string,
  /**
   * Set aria-sort direction.
   */
  sortDirection: PropTypes.oneOf(['asc', 'desc', false]),
  /**
   * Specify the cell type. By default, the `TableHead`, `TableBody` or `TableFooter` parent component set the value.
   */
  variant: PropTypes.oneOf(['head', 'body', 'footer']),
  /**
   * Set this if you want to render a responsive Table
   */
  columnName: PropTypes.string,
  /**
   * If true, the cells will turn to edit mode. Defaults to `true`
   */
  edit: PropTypes.bool,
  /**
   * Content to be shown when table is in read-only mode
   */
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.object]),
  /**
   * This will be rendered when table is in editable mode.
   */
  editableComponent: PropTypes.node.isRequired,
  /**
   * If true, the cell will be displayed in an error state.
   */
  error: PropTypes.bool
};

// default props
TableEditableCell.defaultProps = {
  align: 'inherit',
  padding: 'default',
  edit: true
};

export default withStyles(styles)(TableEditableCell);