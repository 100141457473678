var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import React, { cloneElement, Component } from 'react';
import { default as MuiTextField } from '@material-ui/core/TextField';
import { default as withStyles } from '../styles';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import omit from 'lodash/omit';
import Close from '@ellucian/ds-icons/lib/Close';
import Search from '@ellucian/ds-icons/lib/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import { withEDSContext } from '../EDSContext/EDSContext';
import { borderRadiusMedium, colorBorderGlobalFocus, colorBorderAlertError, fontSizeDefault, fontSizeSmall, heightInputFieldsSmall, lineHeightHeader2Small, paddingTextField, spacing40, spacing30, spacing80, widthInputFields, borderWidthThin, borderWidthThick, spacing50, boxShadowFocus, boxShadowError } from '../styles/tokens';
import uuid from 'uuid/v4';
import isNumber from 'lodash/isNumber';
import isObject from 'lodash/isObject';
import isNil from 'lodash/isNil';
import Tooltip from '../Tooltip';
import Typography from '../Typography';
import InputNumber from './internal/InputNumber';

// This object holds all of the props that are specific to our TextField and should not be included for the numeric TextField
var MUI_TEXTFIELD_PROPS = ['classes', 'margin', 'maxCharacters', 'multiline', 'onAdornmentClick', 'onClearClick', 'onEnterPress', 'passwordToggle', 'rows', 'rowsMax', 'select', 'SelectProps', 'type'];

var adornmentStyles = {
    margin: 0,
    maxHeight: 'none',
    padding: spacing40,
    border: 'none',
    backgroundColor: 'transparent',
    borderRadius: '0 0.19rem 0.19rem 0',
    '&:focus': {
        color: colorBorderGlobalFocus,
        boxShadow: '0 0 0 ' + borderWidthThick + ' ' + colorBorderGlobalFocus,
        outline: 0,
        marginRight: '.1rem'
    }
};

var styles = function styles(theme) {
    var _inputSearch;

    return {
        root: {
            margin: 0
        },
        focused: {
            boxShadow: boxShadowFocus
        },
        focusedError: {
            boxShadow: boxShadowError,
            '-webkit-box-shadow': boxShadowError,
            '-moz-box-shadow:': boxShadowError
        },
        adornment: Object.assign({
            fill: theme.palette.grey[500],
            color: theme.palette.grey[500],
            cursor: 'pointer',
            height: 'auto'
        }, adornmentStyles),
        searchErrorAdornmentError: {
            '&:focus': {
                boxShadow: '0 0 0 ' + borderWidthThick + ' ' + colorBorderAlertError
            }
        },
        passwordAdornmentRoot: Object.assign({
            color: theme.palette.grey[500],
            fontSize: theme.typography.caption.fontSize,
            fontWeight: theme.typography.button.fontWeight,
            height: 'auto',
            textDecoration: 'none'
        }, adornmentStyles, {
            '&:hover': {
                color: theme.palette.ctaColor.hover,
                cursor: 'pointer'
            },
            '&:active': {
                color: theme.palette.ctaColor.active
            }
        }),
        adornmentDisabled: {
            fill: theme.palette.grey[400],
            color: theme.palette.grey[400],
            cursor: 'not-allowed',
            outline: 'none',
            '&:hover': {
                cursor: 'not-allowed',
                fill: theme.palette.grey[400],
                color: theme.palette.grey[400]
            },
            '&:active': {
                fill: theme.palette.grey[400],
                color: theme.palette.grey[400]
            }
        },
        adornmentButton: {
            '&:hover': {
                color: theme.palette.ctaColor.hover
            },
            '&:active': {
                color: theme.palette.ctaColor.active
            },
            '&:focus': {
                border: borderWidthThick + ' solid ' + colorBorderGlobalFocus,
                outline: 'none',
                top: '0.3125rem'
            },
            '&:focus:hover': {
                border: 'none',
                outline: 'none',
                top: '0.375rem'
            },
            '&:disabled': {
                color: theme.palette.ctaColor.disabled
            },
            '&:disabled:hover': {
                backgroundColor: 'transparent',
                color: theme.palette.ctaColor.disabled
            }
        },
        inputRoot: {
            backgroundColor: theme.palette.grey[100],
            borderRadius: borderRadiusMedium,
            height: 'auto',
            padding: 0,
            '&$disabled': {
                backgroundColor: theme.palette.grey[200],
                '&:hover': {
                    cursor: 'not-allowed'
                }
            },
            '& $input:disabled': {
                '&:hover': {
                    cursor: 'not-allowed'
                }
            }
        },
        rowsMaxRoot: {
            '& > div': {
                display: 'inline-flex'
            }
        },
        disabled: {},
        error: {},
        inputBorderDefault: {
            border: borderWidthThin + ' solid ' + theme.palette.grey[400]
        },
        inputBorderError: {
            border: borderWidthThick + ' solid ' + theme.palette.status.error.fill
        },
        input: {
            color: theme.palette.grey[600],
            flex: 1,
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.typography.body1.fontSize,
            height: '1.25rem',
            lineHeight: lineHeightHeader2Small,
            padding: paddingTextField,
            width: 'auto',
            '&::placeholder': {
                color: theme.palette.grey[500],
                fontSize: theme.typography.body1.fontSize,
                opacity: '1'
            },
            '&::-ms-reveal': {
                display: 'none'
            },
            '&::-ms-input-placeholder': {
                color: theme.palette.grey[500],
                fontSize: theme.typography.body1.fontSize,
                opacity: '1'
            },
            '&$startInputAdornment': {
                paddingLeft: spacing30
            }
        },
        startInputAdornment: {},
        constrainedWidth: {
            width: widthInputFields
        },
        inputMultilineRoot: {
            paddingTop: '1.375rem',
            paddingBottom: '.625rem'
        },
        inputMultiline: {
            height: 'auto',
            paddingRight: spacing40,
            paddingLeft: spacing40,
            paddingTop: 0
        },
        inputSearch: (_inputSearch = {
            padding: spacing40
        }, _defineProperty(_inputSearch, '&[type="search"]::-webkit-search-decoration,\n        &[type="search"]::-webkit-search-cancel-button,\n        &[type="search"]::-webkit-search-results-button,\n        &[type="search"]::-webkit-search-results-decoration', {
            display: 'none'
        }), _defineProperty(_inputSearch, '&[type="search"]', {
            '-webkit-appearance': 'none'
        }), _defineProperty(_inputSearch, '&[type=search]::-ms-clear', {
            display: 'none',
            width: 0,
            height: 0
        }), _inputSearch),
        inputSearchWithLabel: {
            padding: paddingTextField
        },
        inputLabel: {
            cursor: 'text',
            fontSize: theme.typography.body1.fontSize,
            fontWeight: theme.typography.fontWeightRegular,
            left: spacing40,
            top: -6, // used to center label in middle of input
            zIndex: theme.zIndex.textFieldLabel,
            '&[data-shrink="true"]': {
                fontSize: theme.typography.body2.fontSize,
                lineHeight: 'normal',
                top: 6, // specific for inputs
                width: 'calc(100% - 1rem)', // needs to be assigned for ellipsis to work, 100% causes horizontal scrollbar to flash when opening
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
            }
        },
        inputLabelWrapForAdornment: {
            marginRight: spacing80
        },
        inputFormLabel: {
            color: theme.palette.status.error.text + ' !important'
        },
        svgSize: {
            height: spacing40,
            width: spacing40
        },
        small: {
            '& $inputRoot': {
                height: heightInputFieldsSmall
            },
            '& $input, & $input:focus': {
                lineHeight: 'unset',
                padding: spacing30,
                fontSize: fontSizeDefault
            },
            '& $inputLabel': {
                top: '-0.75rem',
                left: '0.625rem',
                fontSize: fontSizeDefault
            },
            '& $inputLabel[data-shrink=true]': {
                display: 'none'
            }
        },
        helperTextContainer: {
            display: 'flex'
        },
        helperTextAlignment: {
            justifyContent: 'space-between'
        },
        counterTextAlignment: {
            justifyContent: 'flex-end'
        },
        charCounterContainer: {
            marginLeft: spacing50,
            whiteSpace: 'nowrap'
        },
        formHelperTextRoot: {
            fontFamily: theme.typography.fontFamily,
            fontSize: fontSizeSmall,
            color: theme.palette.text.secondary,
            '&$error': {
                color: theme.palette.status.error.text
            },
            '&$disabled': {
                color: theme.palette.text.disabled
            },
            marginTop: spacing30,
            textTransform: 'none',
            letterSpacing: 'unset'
        }
    };
};

/**
 * Use `TextField` to allow users to enter and edit data.
 * @done true
 * @updated false
 * @versionAdded v0.0.3
 * @examples
 *  Label
 *  LabelAndPlaceholder
 *  FullWidth
 *  Required
 *  Disabled
 *  Multiline
 *  MultilineLimit
 *  MultilineRequired
 *  Search
 *  Password
 *  Uncontrolled
 *  CharacterCounter
 *  Number
 *  InputAdornments
 */

var TextField = function (_Component) {
    _inherits(TextField, _Component);

    function TextField(props) {
        _classCallCheck(this, TextField);

        var _this = _possibleConstructorReturn(this, (TextField.__proto__ || Object.getPrototypeOf(TextField)).call(this, props));

        _this.cloneAdornment = function (element, classes) {
            var id = _this.props.id || '';
            var isButtonElement = element.props.component === 'button';
            return cloneElement(element, {
                classes: {
                    root: classNames(classes.adornment, _defineProperty({}, classes.adornmentButton, isButtonElement))
                },
                id: id + '_Adornment',
                onClick: isButtonElement ? _this.handleAdornmentClick : null,
                size: _this.props.size
            });
        };

        _this.handleAdornmentClick = function (event) {
            if (_this.props.onAdornmentClick) {
                // Prevents triggering onEnterPress event of TextField when user press enter key on search icon.
                event.preventDefault();
                _this.props.onAdornmentClick();
            }
        };

        _this.handleBlur = function (event) {
            if (_this.props.onBlur) {
                _this.props.onBlur(event);
            }
        };

        _this.handleChange = function (event) {
            if (event.target.value.length > 0) {
                _this.setState({
                    hasText: true,
                    count: event.target.value.length
                });
            } else {
                _this.setState({
                    hasText: false,
                    count: 0
                });
            }

            if (_this.props.onChange) {
                _this.props.onChange(event);
            }
        };

        _this.handleEnterPress = function (event) {
            var key = event.key;

            if (key === 'Enter') {
                if (_this.props.onEnterPress) {
                    _this.props.onEnterPress(event);
                }
            }
        };

        _this.handleFocus = function (event) {
            if (_this.props.onFocus) {
                _this.props.onFocus(event);
            }
        };

        _this.handleClearClick = function (event) {
            _this.setState({ hasText: false });
            if (_this.props.onClearClick) {
                _this.props.onClearClick(event);
            }
        };

        _this.handleSearchAdornment = function (event) {
            var onAdornmentClick = _this.props.onAdornmentClick;

            if (_this.state.hasText) {
                _this.handleClearClick(event);
            } else if (onAdornmentClick) {
                _this.handleAdornmentClick(event);
            }
        };

        _this.toggleVisibilityClick = function (e) {
            if (!_this.props.disabled) {
                _this.setState(function (prevState) {
                    return {
                        isPasswordVisible: !prevState.isPasswordVisible
                    };
                });
            }
        };

        _this.helperTextNode = function () {
            var _classNames3;

            var _this$props = _this.props,
                helperText = _this$props.helperText,
                maxCharacters = _this$props.maxCharacters,
                classes = _this$props.classes;


            var charCounterNode = void 0;
            var a11yCharCounterNode = void 0;

            var count = _this.state.count;

            // if we're dispalaying a character counter
            if (isObject(maxCharacters)) {

                // build the counter screen reader text
                var a11yCharCounterText = _this.props.edsContext.formatMessage('component.TextField.characterCounter', {
                    count: count,
                    total: maxCharacters.max
                });

                var countErrorState = count > maxCharacters.max;

                // build the counter text node
                var nodeText = React.createElement(
                    'span',
                    {
                        'aria-hidden': 'true',
                        className: classNames(classes.charCounterContainer, _defineProperty({}, classes.inputFormLabel, countErrorState)) },
                    count,
                    ' / ',
                    maxCharacters.max
                );

                // build the final counter node, optionally encasing it in a tooltip
                charCounterNode = maxCharacters.tooltipText ? React.createElement(
                    Tooltip,
                    {
                        title: maxCharacters.tooltipText,
                        placement: _this.props.edsContext.dir === 'rtl' ? 'left' : 'right' },
                    nodeText
                ) : nodeText;
                a11yCharCounterNode = React.createElement(
                    Typography,
                    { variant: 'srOnly' },
                    a11yCharCounterText
                );
            }

            return (helperText || charCounterNode) && React.createElement(
                'span',
                { className: classNames(classes.helperTextContainer, (_classNames3 = {}, _defineProperty(_classNames3, classes.helperTextAlignment, helperText), _defineProperty(_classNames3, classes.counterTextAlignment, !helperText), _classNames3)) },
                helperText && React.createElement(
                    'span',
                    null,
                    helperText
                ),
                charCounterNode !== undefined && charCounterNode,
                a11yCharCounterNode
            );
        };

        _this.state = {
            isPasswordVisible: false,
            hasText: _this.props.value && _this.props.value.length > 0 || _this.props.defaultValue && _this.props.defaultValue.length > 0,
            count: _this.props.value && _this.props.value.length ? _this.props.value.length : 0
        };

        return _this;
    }

    _createClass(TextField, [{
        key: 'componentDidUpdate',
        value: function componentDidUpdate(prevProps) {

            // if the value changed, determine whether to show the search/clear icon
            if (this.props.type === 'search' && prevProps.value !== this.props.value) {
                this.setState({
                    hasText: this.props.value && this.props.value.length > 0
                });
            }

            // if (1) this is a controlled component; (2) chracter counts are active; and (3) the value has changed,
            // update the character count
            if (!isNil(this.props.value) && isObject(this.props.maxCharacters) && prevProps.value !== this.props.value) {
                this.setState({
                    count: this.props.value ? this.props.value.length : 0
                });
            }
        }
    }, {
        key: 'render',
        value: function render() {
            var _classNames4,
                _this2 = this,
                _classNames8,
                _classNames9;

            var _props = this.props,
                classes = _props.classes,
                classNameProp = _props.className,
                disabled = _props.disabled,
                error = _props.error,
                edsContext = _props.edsContext,
                FormHelperTextPropsProp = _props.FormHelperTextProps,
                helperTextProp = _props.helperText,
                id = _props.id,
                InputLabelPropsProp = _props.InputLabelProps,
                InputPropsProp = _props.InputProps,
                inputPropsProp = _props.inputProps,
                label = _props.label,
                multiline = _props.multiline,
                maxCharacters = _props.maxCharacters,
                onAdornmentClick = _props.onAdornmentClick,
                onClearClick = _props.onClearClick,
                onEnterPress = _props.onEnterPress,
                onKeyDown = _props.onKeyDown,
                onKeyUp = _props.onKeyUp,
                passwordToggle = _props.passwordToggle,
                required = _props.required,
                type = _props.type,
                value = _props.value,
                size = _props.size,
                rest = _objectWithoutProperties(_props, ['classes', 'className', 'disabled', 'error', 'edsContext', 'FormHelperTextProps', 'helperText', 'id', 'InputLabelProps', 'InputProps', 'inputProps', 'label', 'multiline', 'maxCharacters', 'onAdornmentClick', 'onClearClick', 'onEnterPress', 'onKeyDown', 'onKeyUp', 'passwordToggle', 'required', 'type', 'value', 'size']);

            var _state = this.state,
                isPasswordVisible = _state.isPasswordVisible,
                hasText = _state.hasText;

            var cleanedId = id || uuid();

            var numberAriaLabel = '';
            var numberAriaLabelledby = '';

            // HTML input element props
            var inputProps = Object.assign({}, inputPropsProp);

            if (inputProps && inputProps['aria-label']) {
                numberAriaLabel = inputProps['aria-label'];
            } else {
                numberAriaLabel = null;
            }

            if (inputProps && inputProps['aria-labelledby']) {
                numberAriaLabelledby = inputProps['aria-labelledby'];
            } else {
                numberAriaLabelledby = null;
            }

            // If type number, handle render in InputNumber
            if (type === 'number') {
                return React.createElement(InputNumber, Object.assign({}, omit(this.props, MUI_TEXTFIELD_PROPS), { // Send all props, except MUI TextField props
                    'aria-label': numberAriaLabel // Re-assign to correct React prop name
                    , 'aria-labelledby': numberAriaLabelledby // Re-assign to correct React prop name
                    , id: cleanedId // Assign id to new cleanedId
                }));
            }

            var rootClassName = classNames(classes.root, (_classNames4 = {}, _defineProperty(_classNames4, classes.small, size === 'small'), _defineProperty(_classNames4, classes.constrainedWidth, !this.props.fullWidth), _classNames4), classNameProp);

            // Search specific logic
            var searchAdornment = React.createElement(
                InputAdornment,
                {
                    component: 'button',
                    type: 'button',
                    disabled: this.props.disabled,
                    className: classNames(_defineProperty({}, classes.adornmentDisabled, disabled)),
                    'aria-label': hasText ? edsContext.formatMessage('component.TextField.clear') : edsContext.formatMessage('component.TextField.search'),
                    classes: {
                        root: classNames(classes.adornment, _defineProperty({}, classes.searchErrorAdornmentError, error))
                    },
                    id: id ? id + '_SearchIcon' : '',
                    onClick: function onClick(event) {
                        _this2.handleSearchAdornment(event);
                    },
                    tabIndex: '0'
                },
                !hasText || disabled ? React.createElement(
                    Tooltip,
                    {
                        disableFocusListener: true,
                        disableHoverListener: this.props.disabled,
                        title: edsContext.formatMessage('component.TextField.search') },
                    React.createElement(Search, { role: 'img', className: classes.svgSize })
                ) : React.createElement(
                    Tooltip,
                    {
                        disableFocusListener: true,
                        disableHoverListener: this.props.disabled,
                        title: edsContext.formatMessage('component.TextField.clear') },
                    React.createElement(Close, { role: 'img', className: classes.svgSize })
                )
            );

            // Password logic
            var passwordAdornment = React.createElement(
                InputAdornment,
                {
                    component: 'button',
                    type: 'button',
                    disabled: this.props.disabled,
                    classes: { root: classes.passwordAdornmentRoot },
                    className: classNames(_defineProperty({}, classes.adornmentDisabled, disabled)),
                    id: id ? id + '_PasswordToggle' : '',
                    onClick: function onClick(event) {
                        _this2.toggleVisibilityClick(event);
                    },
                    tabIndex: disabled ? '-1' : '0'
                },
                React.createElement(
                    'span',
                    null,
                    isPasswordVisible ? edsContext.formatMessage('component.TextField.hide') : edsContext.formatMessage('component.TextField.show')
                )
            );

            // MUI InputProps logic
            var InputProps = Object.assign({}, InputPropsProp);
            var startAdornment = InputProps.startAdornment,
                endAdornment = InputProps.endAdornment;

            // Send size prop to startAdornment because our custom TextField styles
            // messes up MUI's default styles for TextField + startAdornments

            if (startAdornment) {
                InputProps.startAdornment = cloneElement(startAdornment, { size: size });
            }

            if (endAdornment) {
                InputProps.endAdornment = this.cloneAdornment(endAdornment, classes);
            }

            if (type === 'search') {
                InputProps.endAdornment = searchAdornment;
            }

            if (type === 'password' && passwordToggle) {
                InputProps.endAdornment = passwordAdornment;
            }

            InputProps.classes = {
                root: classNames(classes.inputRoot, (_classNames8 = {}, _defineProperty(_classNames8, classes.inputBorderDefault, !error), _defineProperty(_classNames8, classes.inputBorderError, error), _defineProperty(_classNames8, classes.inputMultilineRoot, multiline), _defineProperty(_classNames8, classes.rowsMaxRoot, multiline), _classNames8)),
                disabled: classes.disabled,
                focused: classNames((_classNames9 = {}, _defineProperty(_classNames9, classes.focused, !error), _defineProperty(_classNames9, classes.focusedError, error), _classNames9)),
                input: classNames(classes.input, _defineProperty({}, classes.startInputAdornment, startAdornment)),
                inputMultiline: classes.inputMultiline,
                inputTypeSearch: classNames(classes.inputSearch, _defineProperty({}, classes.inputSearchWithLabel, !!label))
            };
            InputProps.disableUnderline = true;
            InputProps.onBlur = this.handleBlur;
            InputProps.onFocus = this.handleFocus;
            InputProps.onKeyDown = onKeyDown;
            InputProps.onKeyPress = this.handleEnterPress;
            InputProps.onKeyUp = onKeyUp;
            InputProps.onChange = this.handleChange;

            // if the caller asked for a max length, just set it
            if (maxCharacters && isNumber(maxCharacters)) {
                inputProps.maxLength = maxCharacters;
            }

            // if the caller asked for a character counter, only set the max length if overflow is disallowed
            else if (maxCharacters && isObject(maxCharacters) && !maxCharacters.allowOverflow) {
                    inputProps.maxLength = maxCharacters.max;
                }

            // MUI InputLabel props
            var InputLabelProps = Object.assign({}, InputLabelPropsProp);
            InputLabelProps.classes = {
                root: classNames(classes.inputLabel, _defineProperty({}, classes.inputLabelWrapForAdornment, !isNil(InputProps.endAdornment)))
            };
            InputLabelProps.disabled = disabled;

            // MUI FormHelper props
            var FormHelperTextProps = Object.assign({}, FormHelperTextPropsProp);
            FormHelperTextProps.classes = {
                root: classes.formHelperTextRoot,
                error: classes.inputFormLabel
            };
            FormHelperTextProps.error = error;

            return React.createElement(MuiTextField, Object.assign({
                className: rootClassName,
                disabled: disabled,
                error: error,
                FormHelperTextProps: FormHelperTextProps,
                helperText: this.helperTextNode(),
                id: cleanedId,
                InputProps: InputProps,
                InputLabelProps: InputLabelProps,
                inputProps: inputProps,
                label: label,
                margin: 'normal',
                multiline: multiline,
                onChange: this.handleChange,
                required: required,
                type: isPasswordVisible ? 'text' : type // use this to tell if you should hard render a 'text' or the props.type
                , value: value
            }, rest));
        }
    }]);

    return TextField;
}(Component);

TextField.muiName = 'TextField';

TextField.propTypes = {
    /**
     * This property helps users to fill forms faster, especially on mobile devices.
     * The name can be confusing, as it's more like an autofill.
     * You can learn more about it here:
     * https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#autofill
     */
    autoComplete: PropTypes.string,
    /**
     * If `true`, the input will be focused during the first mount.
     */
    autoFocus: PropTypes.bool,
    /**
     * @ignore
     */
    children: PropTypes.node,
    /**
     * @ignore
     */
    classes: PropTypes.object,
    /**
     * @ignore
     */
    className: PropTypes.string,
    /**
     * The default value of the `Input` element.
     */
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /**
     * If `true`, the input will be disabled.
     */
    disabled: PropTypes.bool,
    /**
     * If `true`, the label will be displayed in an error state.
     */
    error: PropTypes.bool,
    /**
     * Properties applied to the `FormHelperText` element.
     */
    FormHelperTextProps: PropTypes.object,
    /**
     * If `true`, the input will take up the full width of its container.
     */
    fullWidth: PropTypes.bool,
    /**
     * The helper text content.
     */
    helperText: PropTypes.node,
    /**
     * The id of the `input` element.
     * Use that property to make `label` and `helperText` accessible for screen readers.
     */
    id: PropTypes.string,
    /**
     * Properties applied to the `InputAdornment` element.
     */
    InputAdornment: PropTypes.object,
    /**
     * Properties applied to the `InputLabel` element.
     */
    InputLabelProps: PropTypes.object,
    /**
     * Properties applied to the `Input` element.
     *
     * When `type='number'`, adornments will not render.
     */
    InputProps: PropTypes.object,
    /**
     * Properties applied to the native `input` element.
     */
    inputProps: PropTypes.object,
    /**
     * Use that property to pass a ref callback to the native input component.
     */
    inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    /**
     * @ignore
     */
    edsContext: PropTypes.object,
    /**
     * The label content.
     */
    label: PropTypes.node,
    /**
     * If `dense` or `normal`, will adjust vertical spacing of this and contained components.
     */
    margin: PropTypes.oneOf(['none', 'dense', 'normal']),
    /**
     * The maximum number of characters that can be entered into the TextField. There are two variants.
     *
     * With Counter: Textbox or Textarea can have counter at the bottom of the field.  allowOverflow: true will allow more that number of specified characters but counter text will be in red color.
     *
     * e.g.
     * - `{ max: number, allowOverflow: boolean, [tooltipText: string]  }`
     *
     * Without Counter: Will not display counter but number of character restriction will be applied.
     *
     * e.g.
     * - `{number}`
     *
     * **Does not apply to number fields (`type="number"`)**
     */
    maxCharacters: PropTypes.oneOfType([PropTypes.number, PropTypes.shape({
        max: PropTypes.number.isRequired,
        allowOverflow: PropTypes.bool
    })]),
    /**
     * If `true`, a textarea element will be rendered instead of an input.
     */
    multiline: PropTypes.bool,
    /**
     * Name attribute of the `input` element.
     */
    name: PropTypes.string,
    /**
     * Callback fired when an [`InputAdornment`](#/components/InputAdornment) is clicked.
     * The value is passed into the provided function.
     * **Applicable for end adornments when the element rendered is a `button` or when `type="search"` is used.**
     */
    onAdornmentClick: PropTypes.func,
    /**
     * @ignore
     */
    onBlur: PropTypes.func,
    /**
     * Callback fired when the value is changed.
     *
     * @param {object} event The event source of the callback.
     * You can pull out the new value by accessing `event.target.value`.
     */
    onChange: PropTypes.func,
    /**
     * Callback fired when the clear icon is clicked.
     * @param {object} event The event source of the callback.
     */
    onClearClick: PropTypes.func,
    /**
     * Callback fired when the enter key is pressed.
     *
     * @param {object} event The event source of the callback.
     * You can pull out the new value by accessing `event.target.value`.
     */
    onEnterPress: PropTypes.func,
    /**
     * Callback fired when the a key is pressed.
     *
     * @param {object} event The event source of the callback.
     * You can pull out the key by accessing `event.key`.
     */
    onKeyDown: PropTypes.func,
    /**
     * Callback fired when the a key is pressed.
     *
     * @param {object} event The event source of the callback.
     * You can pull out the key by accessing `event.key`.
     */
    onKeyUp: PropTypes.func,
    /**
     * @ignore
     */
    onFocus: PropTypes.func,
    /**
     * Set to true to render the Show/Hide text for password TextFields. This will only affect TextField components with the type="password".
     */
    passwordToggle: PropTypes.bool,
    /**
     * The short hint displayed in the input before the user enters a value.
     */
    placeholder: PropTypes.string,
    /**
     * If `true`, the label is displayed as required.
     */
    required: PropTypes.bool,
    /**
     * Number of rows to display when multiline option is set to true.
     */
    rows: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /**
     * Maximum number of rows to display when multiline option is set to true.
     */
    rowsMax: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /**
     * Render a `Select` element while passing the `Input` element to `Select` as `input` parameter.
     * If this option is set you must pass the options of the select as children.
     */
    select: PropTypes.bool,
    /**
     * Properties applied to the `Select` element.
     */
    SelectProps: PropTypes.object,
    /**
     * Type attribute of the `Input` element. It should be a valid HTML5 input type.
     *
     * Type `search` has been deprecated, use the `Search` component instead.
     */
    type: PropTypes.string,
    /**
     * The value of the `Input` element, required for a controlled component.
     */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))]),
    /**
     * The size of the `TextField`. Small size is recommended to be used within the `TableEditableCell` component.
     */
    size: PropTypes.oneOf(['small', 'medium']),

    // InputNumber specific
    /**
     * **Only valid for number fields (`type="number"`)**
     *
     * Specifies the valid number intervals
     */
    step: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /**
     * **Only valid for number fields (`type="number"`)**
     *
     * Specifies the minimum value. If a value less than the minimum is entered, the field will reset to the
     * minimum value.
     */
    min: PropTypes.number,
    /**
     * **Only valid for number fields (`type="number"`)**
     *
     * Specifies the maximum value. If a value larger than the maximum is entered, the field will reset to the
     * maximum value.
     */
    max: PropTypes.number,
    /**
     * **Only valid for number fields (`type="number"`)**
     *
     * Specifies the precision length of value e.g. `precision={2} => 0.00`
     */
    precision: PropTypes.number,
    /**
     * @ignore
     * **Only valid for number fields (`type="number"`)**
     *
     * Whether focus input when click up or down button
     */
    focusOnUpDown: PropTypes.bool,
    /**
     * @ignore
     * **Only valid for number fields (`type="number"`)**
     *
     * Specifies the format of the value presented
     * `(@param: value: number|string, @param displayValue: string)`
     */
    formatter: PropTypes.func,
    /**
     * @ignore
     * **Only valid for number fields (`type="number"`)**
     *
     * Specifies a regex pattern to be added to the input number element - useful for forcing iOS to open the number pad instead of the normal keyboard (supply a regex of "\d*" to do this) or form validation
     */
    pattern: PropTypes.string,
    /**
     * @ignore
     * **Only valid for number fields (`type="number"`)**
     *
     * Specifies the decimal separator
     */
    decimalSeparator: PropTypes.string
};

TextField.displayName = 'TextField';

TextField.defaultProps = {
    required: false,
    type: 'text',
    rows: 4,
    size: 'medium',
    fullWidth: false,
    step: 1
};

export default withEDSContext(withStyles(styles)(TextField));