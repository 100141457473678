'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.lightThemeOptions = exports.default = undefined;

var _toolbar;

var _styles = require('@material-ui/core/styles');

var _createBreakpoints = require('@material-ui/core/styles/createBreakpoints');

var _createBreakpoints2 = _interopRequireDefault(_createBreakpoints);

var _palette = require('../common/palette');

var _palette2 = _interopRequireDefault(_palette);

var _tokens = require('@ellucian/react-design-system/core/styles/tokens');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

// Breakpoints must be defined in ascending order of viewport size. These breakpoints are used to calculate the size of the viewport in useWidth() hook.
// If values are "NOT" in ascending order then useWidth() hook will fail to return correct value.
var breakpoints = (0, _createBreakpoints2.default)({
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: {
        xs: 0,
        sm: 480,
        md: 768,
        lg: 992,
        xl: 1920
    }
});

var lightThemeOptions = {
    props: {
        MuiButtonBase: {
            disableRipple: true // Disable ripple effect on components
        }
    },
    breakpoints: breakpoints,
    mixins: {
        toolbar: (_toolbar = {
            minHeight: _tokens.sizingXxLarge1
        }, _defineProperty(_toolbar, breakpoints.up('xs') + ' and (orientation: landscape)', {
            minHeight: _tokens.sizingXxLarge1
        }), _defineProperty(_toolbar, breakpoints.up('sm'), {
            minHeight: _tokens.sizingXxLarge1
        }), _toolbar)
    },
    overrides: {
        MuiFormLabel: {
            root: {
                color: _tokens.colorBrandNeutral500,
                '&$disabled:hover': {
                    cursor: 'not-allowed'
                },
                '&$error': {
                    color: _tokens.colorBrandNeutral500
                },
                '&$focused': {
                    color: _tokens.colorBrandNeutral500
                }
            },
            asterisk: {
                '&$error': {
                    color: _tokens.colorBrandNeutral500
                }
            }
        },
        MuiInput: {
            formControl: {
                'label + &': {
                    marginTop: 0,
                    width: '100%'
                }
            }
        },
        // Hover and selected states are same across
        MuiMenuItem: {
            root: {
                '&:hover': {
                    color: _tokens.colorBrandNeutral100
                },
                '&$selected': {
                    color: _tokens.colorBrandNeutral100
                }
            }
        }
    },
    palette: Object.assign({
        action: {
            active: _tokens.colorBrandCtaActive, // cta blue 800
            hover: _tokens.colorBrandCtaHover, // cta blue 700
            base: _tokens.colorBrandCtaBase, // cta blue 600
            disabled: _tokens.colorBrandNeutral400
        },
        grey: {
            100: _tokens.colorBrandNeutral100,
            200: _tokens.colorBrandNeutral200,
            250: _tokens.colorBrandNeutral250,
            300: _tokens.colorBrandNeutral300,
            400: _tokens.colorBrandNeutral400,
            500: _tokens.colorBrandNeutral500,
            600: _tokens.colorBrandNeutral600
        }
    }, _palette2.default),

    spacing: 4,

    typography: {
        fontFamily: _tokens.fontFamilyDefault,
        fontSize: 14, // fontSizeDefault, MUI converts to rem for us
        fontWeightLight: _tokens.fontWeightLight,
        fontWeightRegular: _tokens.fontWeightNormal,
        fontWeightBold: _tokens.fontWeightBold,

        h1: _defineProperty({
            fontFamily: _tokens.fontFamilyHeader,
            fontSize: _tokens.fontSizeHeader1,
            fontWeight: _tokens.fontWeightBold,
            lineHeight: _tokens.lineHeightDefault,
            wordSpacing: '0.16em',
            letterSpacing: '0.03125rem'
        }, breakpoints.down('sm'), {
            fontSize: _tokens.fontSizeHeader1Small
        }),
        h2: _defineProperty({
            fontFamily: _tokens.fontFamilyHeader,
            fontSize: _tokens.fontSizeHeader2,
            fontWeight: _tokens.fontWeightBold,
            lineHeight: _tokens.lineHeightDefault,
            wordSpacing: '0.16em',
            letterSpacing: '0.03125rem'
        }, breakpoints.down('sm'), {
            fontSize: _tokens.fontSizeHeader2Small
        }),
        h3: _defineProperty({
            fontFamily: _tokens.fontFamilyHeader,
            fontSize: _tokens.fontSizeHeader3,
            fontWeight: _tokens.fontWeightBold,
            lineHeight: _tokens.lineHeightDefault,
            letterSpacing: '0.03125rem',
            wordSpacing: '0.16em'
        }, breakpoints.down('sm'), {
            fontSize: _tokens.fontSizeHeader3Small
        }),
        h4: _defineProperty({
            fontFamily: _tokens.fontFamilyDefault,
            fontSize: _tokens.fontSizeHeader4,
            fontWeight: _tokens.fontWeightBold,
            lineHeight: _tokens.lineHeightDefault,
            wordSpacing: '0.16em'
        }, breakpoints.down('sm'), {
            fontSize: _tokens.fontSizeHeader4Small
        }),
        h5: _defineProperty({
            fontFamily: _tokens.fontFamilyDefault,
            fontWeight: _tokens.fontWeightBold,
            fontSize: _tokens.fontSizeHeader5,
            lineHeight: _tokens.lineHeightDefault,
            wordSpacing: '0.16em'
        }, breakpoints.down('sm'), {
            fontSize: _tokens.fontSizeHeader5Small
        }),
        h6: {
            fontFamily: _tokens.fontFamilyDefault,
            fontSize: _tokens.fontSizeHeader6,
            fontWeight: _tokens.fontWeightBold,
            lineHeight: _tokens.lineHeightDefault,
            wordSpacing: '0.16em'
        },
        subtitle1: { // set to h5 styles in case anyone uses this
            fontFamily: _tokens.fontFamilyDefault,
            fontSize: _tokens.fontSizeHeader5,
            fontWeight: _tokens.fontWeightBold,
            lineHeight: _tokens.lineHeightDefault,
            wordSpacing: '0.16em'
        },
        subtitle2: { // set to h6 styles in case anyone uses this
            fontFamily: _tokens.fontFamilyDefault,
            fontSize: _tokens.fontSizeHeader6,
            fontWeight: _tokens.fontWeightBold,
            lineHeight: _tokens.lineHeightDefault,
            wordSpacing: '0.16em'
        },
        body1: {
            fontFamily: _tokens.fontFamilyDefault,
            fontSize: _tokens.fontSizeLarge,
            fontWeight: _tokens.fontWeightNormal,
            lineHeight: _tokens.lineHeightDefault,
            wordSpacing: '0.16em'
        },
        body2: {
            fontFamily: _tokens.fontFamilyDefault,
            fontSize: _tokens.fontSizeDefault,
            fontWeight: _tokens.fontWeightNormal,
            lineHeight: _tokens.lineHeightDefault,
            wordSpacing: '0.16em'
        },
        body3: {
            fontFamily: _tokens.fontFamilyDefault,
            fontSize: _tokens.fontSizeSmall,
            fontWeight: _tokens.fontWeightNormal,
            lineHeight: _tokens.lineHeightDefault,
            wordSpacing: '0.16em',
            letterSpacing: 'initial',
            textTransform: 'initial'
        },
        button: {
            fontFamily: _tokens.fontFamilyCondensed,
            fontSize: _tokens.fontSizeLarge,
            fontWeight: _tokens.fontWeightBold,
            lineHeight: _tokens.lineHeightDefault,
            wordSpacing: '0.16em',
            letterSpacing: '0.0625rem',
            textTransform: 'uppercase'
        },
        caption: {
            fontFamily: _tokens.fontFamilyCondensed,
            fontSize: _tokens.fontSizeDefault,
            fontWeight: _tokens.fontWeightNormal,
            lineHeight: _tokens.lineHeightDefault,
            wordSpacing: '0.16em',
            letterSpacing: '0.0625rem',
            textTransform: 'uppercase'
        },
        overline: { // set to caption styles in case anyone uses this
            fontFamily: _tokens.fontFamilyCondensed,
            fontSize: _tokens.fontSizeDefault,
            fontWeight: _tokens.fontWeightNormal,
            lineHeight: _tokens.lineHeightDefault,
            wordSpacing: '0.16em',
            letterSpacing: '0.0625rem',
            textTransform: 'uppercase'
        }
    },
    zIndex: {
        textFieldLabel: _tokens.zIndexTextFieldLabel,
        dropdown: _tokens.zIndexDropdown,
        headerBarDropdown: _tokens.zIndexHeaderBarDropdown,
        actionMenu: _tokens.zIndexActionMenu,
        megaMenu: _tokens.zIndexMegaMenu,
        inlineAlert: _tokens.zIndexInlineAlert,
        pageAlert: _tokens.zIndexPageAlert,
        headerBar: _tokens.zIndexHeaderBar,
        modal: _tokens.zIndexModal,
        drawer: _tokens.zIndexDrawer,
        backdrop: _tokens.zIndexBackdrop
    }
};

var lightTheme = (0, _styles.createMuiTheme)(lightThemeOptions);

exports.default = lightTheme;
exports.lightThemeOptions = lightThemeOptions;