function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { withStyles } from '../styles';
import { withEDSContext } from '../EDSContext/EDSContext';

// Medium
import Contact from './internal/medium/Contact';
import EmptyCalendar from './internal/medium/EmptyCalendar';
import NoComments from './internal/medium/NoComments';
import NoMessages from './internal/medium/NoMessages';
import NoSearchResults from './internal/medium/NoSearchResults';
import UnderMaintenance from './internal/medium/UnderMaintenance';

// Small
import Book from './internal/small/Book';
import Bookmark from './internal/small/Bookmark';
import Calendar from './internal/small/Calendar';
import ChartBars from './internal/small/ChartBars';
import Checkmark from './internal/small/Checkmark';
import Crown from './internal/small/Crown';
import EmptySet from './internal/small/EmptySet';
import ErrorSmall from './internal/small/Error';
import EyeHide from './internal/small/EyeHide';
import GraduationCap from './internal/small/GraduationCap';
import Heart from './internal/small/Heart';
import IdBadge from './internal/small/IdBadge';
import Lightbulb from './internal/small/Lightbulb';
import Link from './internal/small/Link';
import News from './internal/small/News';
import NoCourses from './internal/small/NoCourses';
import NoHistory from './internal/small/NoHistory';
import NoSearchResultsSm from './internal/small/NoSearchResults';
import NoTasks from './internal/small/NoTasks';
import Pencil from './internal/small/Pencil';
import Search from './internal/small/Search';
import Server from './internal/small/Server';
import Tasks from './internal/small/Tasks';
import UserGroup from './internal/small/UserGroup';

// Consumer focused const to help with auto-predict in text editors
// Consumer doesn't need the component, only the name of the illustration
export var IMAGES = {
    // Medium
    CONTACT: 'contact',
    EMPTY_CALENDAR: 'empty-calendar',
    NO_COMMENTS: 'no-comments',
    NO_MESSAGES: 'no-messages',
    NO_SEARCH_RESULTS: 'no-search-results',
    UNDER_MAINTENANCE: 'under-maintenance',

    // Small
    BOOK: 'book',
    BOOKMARK: 'bookmark',
    CALENDAR: 'calendar',
    CHART_BARS: 'chart-bars',
    CHECKMARK: 'checkmark',
    CROWN: 'crown',
    EMPTY_SET: 'empty-set',
    ERROR: 'error',
    EYE_HIDE: 'eye-hide',
    GRADUATION_CAP: 'graduation-cap',
    HEART: 'heart',
    ID_BADGE: 'id-badge',
    LIGHTBULB: 'lightbulb',
    LINK: 'link',
    NEWS: 'news',
    NO_COURSES: 'no-courses',
    NO_HISTROY: 'no-history',
    NO_SEARCH_RESULTS_SM: 'no-search-results-sm',
    NO_TASKS: 'no-tasks',
    PENCIL: 'pencil',
    SEARCH: 'search',
    SERVER: 'server',
    TASKS: 'tasks',
    USER_GROUP: 'user-group'
};

// Internal object to return the correct illustration-component to render
var ILLUSTRATIONS = {
    // Medium
    contact: Contact,
    'empty-calendar': EmptyCalendar,
    'no-comments': NoComments,
    'no-messages': NoMessages,
    'no-search-results': NoSearchResults,
    'under-maintenance': UnderMaintenance,

    // Small
    book: Book,
    bookmark: Bookmark,
    calendar: Calendar,
    'chart-bars': ChartBars,
    checkmark: Checkmark,
    crown: Crown,
    'empty-set': EmptySet,
    'error': ErrorSmall,
    'eye-hide': EyeHide,
    'graduation-cap': GraduationCap,
    heart: Heart,
    'id-badge': IdBadge,
    lightbulb: Lightbulb,
    link: Link,
    news: News,
    'no-courses': NoCourses,
    'no-history': NoHistory,
    'no-search-results-sm': NoSearchResultsSm,
    'no-tasks': NoTasks,
    pencil: Pencil,
    search: Search,
    server: Server,
    tasks: Tasks,
    'user-group': UserGroup
};

var RTL_MIRRORABLE_ILLUSTRATIONS = ['book', 'chart-bars', 'pencil', 'no-comments'];

var styles = function styles(theme) {
    return {
        root: {},
        primary: { color: theme.palette.primary.main },
        secondary: { color: theme.palette.secondary.main },
        rtl: {
            transform: 'scale(-1, 1)'
        }
    };
};

/**
 * Use `Illustration` to soften content-heavy pages and to communicate important moments in a user's journey.
 * @done true
 * @updated false
 * @versionAdded v2.4.0
 * @examples
 *  IllustrationExample
 *  ThemedIllustration
 *  EmptyState
 */
var Illustration = React.forwardRef(function (props, ref) {
    var _cn;

    var classes = props.classes,
        color = props.color,
        className = props.className,
        edsContext = props.edsContext,
        name = props.name,
        SvgProps = props.SvgProps,
        rest = _objectWithoutProperties(props, ['classes', 'color', 'className', 'edsContext', 'name', 'SvgProps']);

    var IllustrationComponent = ILLUSTRATIONS[name];

    return React.createElement(
        'span',
        Object.assign({
            className: cn(classes.root, (_cn = {}, _defineProperty(_cn, classes.rtl, edsContext.dir === 'rtl' && RTL_MIRRORABLE_ILLUSTRATIONS.includes(name)), _defineProperty(_cn, classes.primary, color === 'primary'), _defineProperty(_cn, classes.secondary, color === 'secondary'), _cn), className)
        }, rest, {
            ref: ref
        }),
        React.createElement(IllustrationComponent, SvgProps)
    );
});

Illustration.propTypes = {
    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object,
    /**
     * @ignore
     */
    className: PropTypes.string,
    /**
     * The name of the illustration to render.
     *
     * Use the const `IMAGES` located in `@ellucian/react-design-system/core/Illustration`.
     */
    name: PropTypes.oneOf(['contact', 'empty-calendar', 'no-comments', 'no-messages', 'no-search-results', 'under-maintenance', 'book', 'bookmark', 'calendar', 'chart-bars', 'checkmark', 'crown', 'empty-set', 'error', 'eye-hide', 'graduation-cap', 'heart', 'id-badge', 'lightbulb', 'link', 'news', 'no-courses', 'no-history', 'no-search-results-sm', 'no-tasks', 'pencil', 'search', 'server', 'tasks', 'user-group']).isRequired,
    /**
     * The color the illustration will render in.
     */
    color: PropTypes.oneOf(['primary', 'secondary']),
    /**
     * Prop object to pass to the Illustration SVG component.
     */
    SvgProps: PropTypes.object
};

Illustration.displayName = 'Illustration';

Illustration.defaultProps = {
    color: 'primary'
};

export default withEDSContext(withStyles(styles)(Illustration));