function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import React from 'react';
import PropTypes from 'prop-types';
import { default as withStyles } from '../styles';
import Typography from '../Typography';
import { spacing50, spacing60 } from '../styles/tokens';

var styles = function styles(theme) {
    return {
        h1: {
            marginTop: spacing60,
            marginBottom: spacing50
        }
    };
};

/**
 * Use `PageHeader` to display a page title at the top of a page.
 * @done true
 * @updated false
 * @versionAdded v0.0.8
 * @examples
 *  PageHeaderExample
 */
var PageHeader = React.forwardRef(function (props, ref) {
    var classes = props.classes,
        text = props.text,
        rest = _objectWithoutProperties(props, ['classes', 'text']);

    var classOverrides = Object.assign({
        h1: classes.h1
    }, classes);

    return React.createElement(
        Typography,
        Object.assign({}, rest, { classes: classOverrides, variant: 'h1', ref: ref }),
        text
    );
});

PageHeader.muiName = 'PageHeader';
PageHeader.displayName = 'PageHeader';

PageHeader.propTypes = {
    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object,
    /**
     * The content to be displayed as the page header.
     */
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};

export default withStyles(styles)(PageHeader);