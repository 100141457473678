var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '../styles';
import { withEDSContext } from '../EDSContext/EDSContext';
import IconButton from '../IconButton/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '../Tooltip/Tooltip';
import ChevronUp from '@ellucian/ds-icons/lib/ChevronUp';
import ChevronDown from '@ellucian/ds-icons/lib/ChevronDown';
import classnames from 'classnames';
import { spacing40, spacing50, spacing20, sizingSmall } from '../styles/tokens';

var styles = function styles(theme) {
    return {
        root: _defineProperty({
            height: '3rem'
        }, theme.breakpoints.down('sm'), {
            '&.table-row-expanded': {
                backgroundColor: theme.palette.grey[200]
            }
        }),
        tableRowExpandedBackground: {
            backgroundColor: theme.palette.grey[200]
        },
        tableRowExpand: {
            padding: spacing40 + ' ' + spacing50
        },
        muiTableCellRoot: {
            '&:last-child': {
                paddingRight: spacing40,
                textAlign: 'right'
            }
        },
        iconSize: {
            height: sizingSmall,
            width: sizingSmall
        },
        expandPadding: {
            paddingTop: spacing20,
            paddingBottom: spacing20
        }
    };
};

var TableExpandableRow = function (_React$Component) {
    _inherits(TableExpandableRow, _React$Component);

    function TableExpandableRow(props) {
        _classCallCheck(this, TableExpandableRow);

        var _this = _possibleConstructorReturn(this, (TableExpandableRow.__proto__ || Object.getPrototypeOf(TableExpandableRow)).call(this, props));

        _this.toggleExpand = function (e) {
            if (e.type === 'keypress' && (e.key === 'Enter' || e.key === ' ')) {
                e.preventDefault();
                _this.updateState();
            } else if (e.type === 'click') {
                _this.updateState();
            }
        };

        _this.state = {
            expanded: false
        };
        return _this;
    }

    _createClass(TableExpandableRow, [{
        key: 'componentDidUpdate',
        value: function componentDidUpdate(prevProps, prevState) {
            var _this2 = this;

            if (this.props.expand !== prevProps.expand) {
                this.setState(function (state) {
                    return { expanded: _this2.props.expand };
                });
            }
        }
    }, {
        key: 'updateState',
        value: function updateState() {
            this.setState(function (state) {
                return {
                    expanded: !state.expanded
                };
            });
        }
    }, {
        key: 'render',
        value: function render() {
            var _classnames,
                _this3 = this;

            var _props = this.props,
                classes = _props.classes,
                children = _props.children,
                expandedRowClassName = _props.expandedRowClassName,
                expandedRowContent = _props.expandedRowContent,
                TableRowProps = _props.TableRowProps,
                ExpandedTableRowProps = _props.ExpandedTableRowProps,
                edsContext = _props.edsContext,
                expand = _props.expand,
                rest = _objectWithoutProperties(_props, ['classes', 'children', 'expandedRowClassName', 'expandedRowContent', 'TableRowProps', 'ExpandedTableRowProps', 'edsContext', 'expand']);

            var expanded = this.state.expanded;


            var expandLabel = expanded ? edsContext.formatMessage('component.Table.hide') : edsContext.formatMessage('component.Table.showMore');

            return React.createElement(
                React.Fragment,
                null,
                React.createElement(
                    TableRow,
                    Object.assign({
                        className: classnames(classes.root, (_classnames = {}, _defineProperty(_classnames, classes.tableRowExpandedBackground, expanded && expandedRowContent), _defineProperty(_classnames, 'table-row-expanded', expanded), _defineProperty(_classnames, 'not-table-row-expanded', !expanded && expandedRowContent), _defineProperty(_classnames, 'table-expandable-row', expandedRowContent), _classnames))
                    }, TableRowProps, rest),
                    children,
                    React.createElement(
                        TableCell,
                        {
                            className: classnames(classes.expandPadding, 'expand-row-button', { 'no-expanded-row-content': !expandedRowContent }),
                            classes: { root: classes.muiTableCellRoot }
                        },
                        React.createElement(
                            Tooltip,
                            { title: expandLabel, placement: edsContext.dir === 'rtl' ? 'right' : 'left' },
                            React.createElement(
                                IconButton,
                                {
                                    className: 'expand-icon-container',
                                    color: 'gray',
                                    'aria-expanded': expanded,
                                    'aria-label': expandLabel,
                                    onClick: function onClick(e) {
                                        return _this3.toggleExpand(e);
                                    },
                                    tabIndex: 0
                                },
                                expanded ? React.createElement(ChevronUp, { className: classnames(classes.iconSize, 'expand-icon') }) : React.createElement(ChevronDown, { className: classnames(classes.iconSize, 'expand-icon') })
                            )
                        )
                    )
                ),
                expandedRowContent && expanded && React.createElement(
                    TableRow,
                    Object.assign({ className: classnames(classes.tableRowExpandedBackground, expandedRowClassName, 'table-row-expanded-content')
                    }, ExpandedTableRowProps),
                    React.createElement(
                        TableCell,
                        { className: classes.tableRowExpand, colSpan: children.length + 1 },
                        expandedRowContent
                    )
                )
            );
        }
    }]);

    return TableExpandableRow;
}(React.Component);

TableExpandableRow.propTypes = {
    /**
     * Common children are the TableCells with information you want to show on the TableRow.
     */
    children: PropTypes.node.isRequired,
    /**
     * @ignore
     */
    classes: PropTypes.object,
    /**
     * This is the custom prop for developers to control the expand collapse.
     * Useful when you want to expand / collapse all section in one click.
     */
    expand: PropTypes.bool,
    /**
     * This is the actual content that should be shown when the TableExpandableRow is expanded.
     * When used for responsive table, you can choose not to supply any content.
     */
    expandedRowContent: PropTypes.node,
    /**
     * Custom string name to send to the TableRow that is shown when expanded.
     */
    expandedRowClassName: PropTypes.string,
    /**
     * @ignore
     */
    edsContext: PropTypes.object,
    /**
     * Object of props to send to the TableRow with the toggle arrow.
     */
    TableRowProps: PropTypes.object,
    /**
     * Object of props to send to the TableRow that is shown when expanded.
     */
    ExpandedTableRowProps: PropTypes.object
};

export default withEDSContext(withStyles(styles)(TableExpandableRow));