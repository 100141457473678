function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import React from 'react';
import PropTypes from 'prop-types';
import { default as withStyles } from '../styles';
import { default as MuiSlider } from '@material-ui/core/Slider';
import { withEDSContext } from '../EDSContext/EDSContext';
import Circle from '@ellucian/ds-icons/lib/Circle';

// Necessary design tokens are imported here
import { boxShadowFocus, colorTextNeutral500, fontSizeSmall } from '../styles/tokens';

export var styles = function styles(theme) {
    return {
        // Necessary style overrides are set here
        // See Material UI documentation for available CSS classes to override
        root: {
            color: theme.palette.ctaColor.base
        },

        disabled: {
            '& $thumb': {
                width: fontSizeSmall,
                height: fontSizeSmall,
                marginTop: '-5px',
                marginLeft: '-6px'
            },

            '&$vertical $thumb': {
                marginBottom: '-6px',
                marginLeft: '-5px'
            }

        },

        rail: {
            color: colorTextNeutral500
        },

        thumb: {
            '&:focus, &:hover': {
                boxShadow: boxShadowFocus
            }
        },

        vertical: {}

    };
};

/**
 * A tool to select a value within a range.
 * @done true
 * @updated false
 * @versionAdded v0.0.8
 * @examples
 *  SliderExample
 */
var Slider = React.forwardRef(function (props, ref) {
    var classes = props.classes,
        edsContext = props.edsContext,
        rest = _objectWithoutProperties(props, ['classes', 'edsContext']);

    var classOverrides = {
        // Necessary style overrides are set here
        // See Material UI documentation for available CSS classes to override
        root: classes.root,
        disabled: classes.disabled,
        rail: classes.rail,
        thumb: classes.thumb,
        vertical: classes.vertical

    };

    return React.createElement(MuiSlider, Object.assign({}, rest, {
        thumb: React.createElement(Circle, { 'aria-label': edsContext.formatMessage('component.Slider.thumb') }),
        classes: classOverrides,
        ref: ref
    }));
});

Slider.muiName = 'Slider';
Slider.displayName = 'Slider';

Slider.propTypes = {
    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object,
    /**
     * @ignore
     */
    className: PropTypes.string,
    /**
     * The component used for the root node.
     * Either a string to use a DOM element or a component.
     */
    component: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object]),
    /**
     * If `true`, the slider will be disabled.
     */
    disabled: PropTypes.bool,
    /**
     * @ignore
     */
    edsContext: PropTypes.object,
    /**
     * The maximum allowed value of the slider.
     * Should not be equal to min.
     */
    max: PropTypes.number,
    /**
     * The minimum allowed value of the slider.
     * Should not be equal to max.
     */
    min: PropTypes.number,
    /**
     * Callback function that is fired when the slider's value changed.
     */
    onChange: PropTypes.func,
    /**
     * The granularity the slider can step through values.
     */
    step: PropTypes.number,
    /**
     * @ignore
     */
    theme: PropTypes.object,
    /**
     * The value of the slider.
     */
    value: PropTypes.number.isRequired,
    /**
     * The slider orientation.
     */
    orientation: PropTypes.oneOf(['horizontal', 'vertical'])
};

// If default props are needed, they must be set here
Slider.defaultProps = {
    orientation: 'horizontal'
};

export default withEDSContext(withStyles(styles)(Slider));