import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import defaultClassNames from 'react-day-picker/lib/src/classNames';
import { withStyles } from '@ellucian/react-design-system/core/styles';
import { withEDSContext } from '../../EDSContext/EDSContext';
import IconButton from '../../IconButton';
import Tooltip from '../../Tooltip';

import ChevronRight from '@ellucian/ds-icons/lib/ChevronRight';
import ChevronLeft from '@ellucian/ds-icons/lib/ChevronLeft';

import { sizingMedium, spacing40 } from '../../styles/tokens';

var iconButtonAlignmentSpace = '0.375rem';

var styles = function styles(theme) {
    return {
        root: {
            display: 'flex',
            alignItems: 'center',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0
        },
        left: {
            marginRight: 'auto',
            marginLeft: iconButtonAlignmentSpace,
            width: sizingMedium,
            height: sizingMedium,
            '& svg': {
                height: spacing40,
                width: spacing40
            }
        },
        right: {
            marginRight: iconButtonAlignmentSpace,
            marginLeft: 'auto',
            width: sizingMedium,
            height: sizingMedium,
            '& svg': {
                height: spacing40,
                width: spacing40
            }
        }
    };
};

function Navbar(props) {
    var onPreviousClick = props.onPreviousClick,
        onNextClick = props.onNextClick,
        className = props.className,
        edsContext = props.edsContext,
        labels = props.labels,
        classes = props.classes,
        showPreviousButton = props.showPreviousButton,
        showNextButton = props.showNextButton;


    var previousButton = React.createElement(
        Tooltip,
        { key: 0, title: edsContext.formatMessage('component.DateRangePicker.previousMonth'), enterDelay: 1000, enterNextDelay: 1000 },
        React.createElement(
            IconButton,
            {
                key: 'hedtech-datepicker-prev-btn',
                color: 'gray',
                className: classes.left,
                'aria-label': labels.previousMonth,
                onClick: function onClick() {
                    return onPreviousClick();
                },
                disabled: !showPreviousButton
            },
            edsContext.dir === 'rtl' ? React.createElement(ChevronRight, null) : React.createElement(ChevronLeft, null)
        )
    );

    var nextButton = React.createElement(
        Tooltip,
        { key: 1, title: edsContext.formatMessage('component.DateRangePicker.nextMonth'), enterDelay: 1000, enterNextDelay: 1000 },
        React.createElement(
            IconButton,
            {
                key: 'hedtech-datepicker-next-btn',
                color: 'gray',
                className: classes.right,
                'aria-label': labels.nextMonth,
                onClick: function onClick() {
                    return onNextClick();
                },
                disabled: !showNextButton
            },
            edsContext.dir === 'rtl' ? React.createElement(ChevronLeft, null) : React.createElement(ChevronRight, null)
        )
    );

    return React.createElement(
        'div',
        { className: cn(className, classes.root) },
        [previousButton, nextButton]
    );
}

Navbar.propTypes = {
    classNames: PropTypes.shape({
        navBar: PropTypes.string.isRequired,
        navButtonPrev: PropTypes.string.isRequired,
        navButtonNext: PropTypes.string.isRequired
    }),
    className: PropTypes.string,
    edsContext: PropTypes.object,
    showPreviousButton: PropTypes.bool,
    showNextButton: PropTypes.bool,
    onPreviousClick: PropTypes.func,
    onNextClick: PropTypes.func,
    labels: PropTypes.shape({
        previousMonth: PropTypes.string,
        nextMonth: PropTypes.string
    }),
    classes: PropTypes.object
};

Navbar.defaultProps = {
    classNames: defaultClassNames,
    labels: {},
    showPreviousButton: true,
    showNextButton: true
};

export default withEDSContext(withStyles(styles)(Navbar));