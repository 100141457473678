const hdnBaseAddress = document.getElementById('hdnBaseAddress');
const webUrl = hdnBaseAddress ? hdnBaseAddress.value : '';
const siteConfigurationMissing = document.getElementById('siteConfigurationMissing');
const Constants = {
    defaultActionColor: 'blue',
    faviconCDN: 'https://cdn.elluciancloud.com/assets/EDS2/0.0.8/favicon/favicon.ico',
    headersRequestsJson: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    },
    imagesCDN: 'https://cdn.elluciancloud.com/assets/EDS2/4.4.0/img',
    peoplePictureUrl: `${webUrl}/People/Picture/`,
    siteConfigurationMissing: siteConfigurationMissing ? (siteConfigurationMissing.value === 'true') : false,
    webUrl: webUrl
};
export default Constants;
