function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { borderRadiusMedium, borderRadiusCircle, borderWidthThick, borderWidthThin, boxShadowFocus, colorBorderGlobalFocus, fontFamilyDefault, fontSizeDefault, widthInputFields, paddingTextField, spacing20, spacing30, spacing50, spacing40, heightInputFieldsSmall, zIndexTextFieldLabel, colorBrandNeutral250, fontWeightBold } from '../../styles/tokens';

var styles = function styles(theme) {
    var _root;

    return {
        container: {
            display: 'inline-flex', // Match other input fields
            flexDirection: 'column' // Added to make the helpertext go underneath
        },
        root: (_root = {
            margin: spacing30 + ' 0',

            /* DayPicker styles */
            '& .DayPicker': {
                display: 'inline-block',
                fontSize: fontSizeDefault,
                fontFamily: fontFamilyDefault,
                backgroundColor: theme.palette.grey[100],
                border: borderWidthThin + ' solid ' + theme.palette.grey[400],
                position: 'relative',
                background: theme.palette.grey[100],
                borderRadius: borderRadiusMedium,
                userSelect: 'none'
            },

            '& .DayPicker-wrapper': {
                position: 'relative',
                flexDirection: 'row',
                userSelect: 'none',
                '&:focus': {
                    outlineColor: theme.palette.focus
                }
            },

            '& .DayPicker-Months': {
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center'
            },

            '& .DayPicker-Month': {
                display: 'inline-table',
                margin: '0 ' + spacing30,
                paddingBottom: '0.75rem',
                borderSpacing: 0,
                borderCollapse: 'collapse',
                userSelect: 'none',

                '&:first-of-type': {
                    display: 'block',
                    paddingRight: '0.75rem',
                    borderRight: 'solid ' + borderWidthThin + ' ' + theme.palette.grey[300]
                }
            },

            '& .DayPicker-NavBar': {
                margin: '0.75rem ' + spacing30 + ' 0'
            },

            '& .DayPicker-NavButton': {
                position: 'absolute',
                top: spacing40,
                right: spacing50,
                left: 'auto',
                display: 'inline-block',
                marginTop: '.125rem',
                width: '1.25rem',
                height: '1.25rem',
                backgroundPosition: 'center',
                backgroundSize: '50%',
                backgroundRepeat: 'no-repeat',
                cursor: 'pointer',

                '&:hover': {
                    opacity: 0.8
                }
            },

            '& .DayPicker-NavButton--interactionDisabled': {
                display: 'none'
            },

            '& .DayPicker-Caption ': {
                display: 'table-caption',
                marginTop: spacing30,
                marginBottom: spacing20
            },

            '& .DayPicker-Weekdays': {
                display: 'table-header-group',
                marginTop: '1em'
            },

            '& .DayPicker-WeekdaysRow': {
                display: 'table-row',
                textTransform: 'uppercase'
            },

            '& .DayPicker-Weekday': {
                display: 'table-cell',
                padding: '0.75rem',
                color: theme.palette.grey[500],
                textAlign: 'center',
                fontSize: fontSizeDefault
            },

            '& .DayPicker-Weekday abbr[title]': {
                borderBottom: 'none',
                textDecoration: 'none'
            },

            '& .DayPicker-Body': {
                display: 'table-row-group'
            },

            '& .DayPicker-Week': {
                display: 'table-row'
            },

            '& .DayPicker-Day': {
                display: 'table-cell',
                borderRadius: borderRadiusCircle,
                verticalAlign: 'middle',
                textAlign: 'center',
                cursor: 'pointer',
                width: '2.75rem',
                height: '2.75rem'
            },
            '& .hedtech-day': {
                borderRadius: borderRadiusCircle,
                width: '1.875rem',
                height: '1.875rem',
                marginTop: spacing20,
                marginBottom: '.625rem',
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center'
                // transition: theme.transitions.create(['background', 'background-color', 'color'], {
                //     duration: 50,
                // }),
            },
            //Styles for current day
            '& .DayPicker-Today': {
                color: theme.palette.ctaColor.base,
                borderRadius: borderRadiusCircle,
                fontWeight: fontWeightBold
            },

            // TODO: Look into
            '& .DayPicker-WeekNumber': {
                display: 'table-cell',
                padding: '0.5em',
                minWidth: '1em',
                borderRight: '1px solid #EAECEC',
                color: '#8B9898',
                verticalAlign: 'middle',
                textAlign: 'right',
                fontSize: '0.75em',
                cursor: 'pointer'
            },

            // TODO: Look into
            '& .DayPicker--interactionDisabled .DayPicker-Day': {
                cursor: 'default'
            },

            // TODO: Look into
            '& .DayPicker-Footer': {
                paddingTop: '0.5em',

                // Center today button
                display: 'flex',
                justifyContent: 'center'
            },

            // NOTE: We don't use this at the moment but might in the future.
            '& .DayPicker-TodayButton': {
                border: 'none',
                backgroundColor: 'transparent',
                backgroundImage: 'none',
                boxShadow: 'none',
                color: theme.palette.ctaColor.base,
                fontSize: '0.875em',
                cursor: 'pointer',
                padding: '4px 8px',

                '&:hover': {
                    color: theme.palette.ctaColor.hover,
                    cursor: 'pointer'
                },
                '&:active': {
                    color: theme.palette.ctaColor.active
                },
                '&:focus': {
                    outline: 'none',
                    borderRadius: borderRadiusMedium,
                    boxShadow: '0 0 0 ' + borderWidthThick + ' ' + colorBorderGlobalFocus
                }
            },

            /* Default modifiers */

            // NOTE: We don't use this at the moment but might in the future.
            '& .DayPicker-Day--today': {
                // fontWeight: 700,
                // fontWeight: 600,
                // backgroundColor: theme.palette.ctaColor.tint,
                // color: theme.palette.ctaColor.base,
                // borderRadius: borderRadiusCircle,
            },

            // NOTE: We don't use this at the moment but might in the future.
            '& .DayPicker-Day--today.DayPicker-Day--disabled': {
                // color: theme.palette.ctaColor.base,
            },

            // NOTE: We don't use this for DateRangePicker but might for the normal DatePicker
            '& .DayPicker-Day--outside': {
                cursor: 'default',
                backgroundColor: theme.palette.grey[200],
                color: theme.palette.grey[500],
                borderRadius: 0
            },

            // ========
            // Disabled days
            // ========
            '& .DayPicker-Day--disabled': {
                cursor: 'default',
                backgroundColor: theme.palette.grey[200],
                color: theme.palette.grey[500],
                borderRadius: 0,
                '&:focus': {
                    outlineColor: theme.palette.focus
                }
                // NOTE: Ask UXD about this since we are still able to navigate to them if they are disabled???
                // '&:hover': {
                //     backgroundColor: `${theme.palette.grey[200]}`,
                //     color: `${theme.palette.grey[500]}`,
                //     cursor: 'not-allowed',
                // },
                // '&:hover, &:focus': {
                //     backgroundColor: `${theme.palette.grey[300]}`,
                //     color: `${theme.palette.grey[600]}`,
                //     cursor: 'not-allowed',
                // },
            },

            '& .DayPicker-Day--disabled:not(.DayPicker-Day--outside)': {
                '&:hover, &:focus': {
                    backgroundColor: '' + theme.palette.grey[200],
                    color: '' + theme.palette.grey[500],
                    cursor: 'not-allowed'
                }
            },

            // DateRangePicker
            '& .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside)': {
                position: 'relative',
                backgroundColor: theme.palette.ctaColor.base,
                color: theme.palette.grey[100],

                '&:hover, &:focus': {
                    backgroundColor: theme.palette.ctaColor.hover
                }
            }

        }, _defineProperty(_root, '& .DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside)', {
            // disable defaults
            '&:focus': {
                outline: 'none'
            },
            '&:hover': {
                '& .hedtech-day': {
                    cursor: 'pointer'
                }
            },
            '&:focus, &:hover': {
                '& .hedtech-day': {
                    outline: 'none',
                    backgroundColor: theme.palette.grey[250]
                }
            },
            '&:active': {
                outline: 'none',
                '& .hedtech-day': {
                    backgroundColor: theme.palette.ctaColor.active
                }
            }
        }), _defineProperty(_root, '& .DayPickerInput', {
            display: 'inline-block'
        }), _defineProperty(_root, '& .DayPickerInput-OverlayWrapper', {
            position: 'relative'
        }), _defineProperty(_root, '& .DayPickerInput-Overlay', {
            position: 'absolute',
            left: 0,
            zIndex: 1,
            background: 'white',
            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.15)'
        }), _root),
        dateRangePickerRoot: _defineProperty({
            // NOTE: might get rid of this
            // '& .DayPicker-Day.DayPicker-Day--disabled.DayPicker-Day--outside': {
            //     backgroundColor: theme.palette.grey[300],
            // },
            // ========
            // Hover state when selecting days
            // ========
            '&.hedtech-DateRangePicker .DayPicker-Day--hover:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside)': {
                '&:first-child': {
                    '& .hedtech-day': {
                        borderRadius: borderRadiusMedium + ' 0 0 ' + borderRadiusMedium
                    },
                    '&:focus, &:hover': {
                        '& .hedtech-day': {
                            borderRadius: borderRadiusCircle
                        }
                    }
                },
                '&:last-child': {
                    '& .hedtech-day': {
                        borderRadius: '0 ' + borderRadiusMedium + ' ' + borderRadiusMedium + ' 0'
                    },
                    '&:focus, &:hover': {
                        '& .hedtech-day': {
                            borderRadius: borderRadiusCircle
                        }
                    }
                },
                '& .hedtech-day': {
                    width: '100%',
                    borderRadius: 0,
                    outline: 'none',
                    backgroundColor: theme.palette.ctaColor.tint,
                    color: theme.palette.grey[600]
                },
                '& .DayPicker-Today': {
                    color: theme.palette.ctaColor.base
                },
                '&:focus, &:hover': {
                    // position: 'relative',
                    // zIndex: 1,
                    '& .hedtech-day': {
                        width: '1.875rem',
                        // backgroundColor: `${theme.palette.grey[500]}`,
                        // color: theme.palette.grey[100],
                        borderRadius: '50%'
                    }
                    // '&:before': {
                    //     top: '.375rem',
                    //     left: 0,
                    //     width: '1.5rem', // ~ half of parent div
                    //     height: '2rem',
                    //     content: '""',
                    //     display: 'block',
                    //     position: 'absolute', // this creates a new stacking z-index
                    //     zIndex: -1,
                    //     backgroundColor: theme.palette.ctaColor.tint,
                    // },
                }
            },
            // ========
            // Range of days
            // ========
            '&.hedtech-DateRangePicker .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside)': {
                backgroundColor: theme.palette.grey[100],
                borderRadius: '0',
                '&:first-child': {
                    '& .hedtech-day': {
                        borderRadius: borderRadiusMedium + ' 0 0 ' + borderRadiusMedium
                    }
                },
                '&:last-child': {
                    '& .hedtech-day': {
                        borderRadius: '0 ' + borderRadiusMedium + ' ' + borderRadiusMedium + ' 0'
                    }
                },
                '& .hedtech-day': {
                    width: '100%',
                    backgroundColor: theme.palette.ctaColor.tint,
                    color: theme.palette.grey[600],
                    borderRadius: '0'
                },
                '& .DayPicker-Today': {
                    color: theme.palette.ctaColor.base
                },
                '&:focus, &:hover': {
                    backgroundColor: theme.palette.grey[100],
                    outline: 'none',
                    '& .hedtech-day': {
                        borderRadius: '50%',
                        width: '2rem',
                        outline: 'none',
                        backgroundColor: colorBrandNeutral250,
                        color: theme.palette.grey[600]
                    },
                    '& .DayPicker-Today': {
                        color: theme.palette.ctaColor.base
                    }
                }
            },
            // ========
            // Start and End days
            // ========
            '&.hedtech-DateRangePicker .DayPicker-Day--start.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside), &.hedtech-DateRangePicker .DayPicker-Day--end.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside)': {
                position: 'relative',
                zIndex: 1,
                '&:first-child': {
                    '& .hedtech-day': {
                        borderRadius: borderRadiusCircle
                    }
                },
                '&:last-child': {
                    '& .hedtech-day': {
                        borderRadius: borderRadiusCircle
                    }
                },
                '&:before': {
                    top: spacing20,
                    right: 0,
                    width: '1.5rem', // ~ half of parent div
                    height: '1.875rem',
                    content: '""',
                    display: 'block',
                    position: 'absolute', // this creates a new stacking z-index
                    zIndex: -1,
                    backgroundColor: theme.palette.ctaColor.tint
                },
                '& .hedtech-day': {
                    width: '1.875rem',
                    backgroundColor: '' + theme.palette.ctaColor.base,
                    color: theme.palette.grey[100],
                    borderRadius: '50%',
                    fontWeight: fontWeightBold
                },
                '&:hover': {
                    '& .hedtech-day': {
                        backgroundColor: theme.palette.ctaColor.hover,
                        color: theme.palette.grey[100]
                    }
                },
                '&:focus, &:active': {
                    outline: 'none',
                    '& .hedtech-day': {
                        outline: 'none',
                        backgroundColor: theme.palette.ctaColor.hover,
                        color: theme.palette.grey[100]
                    }
                }
            },
            // When Start and End date are the same day
            '&.hedtech-DateRangePicker .DayPicker-Day--end.DayPicker-Day--start.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside)': {
                '&:before': {
                    backgroundColor: theme.palette.grey[100]
                }
            },
            // Start date
            '&.hedtech-DateRangePicker .DayPicker-Day--start.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) ': {
                '&:before': {
                    right: 0
                }
            },
            // End date
            '&.hedtech-DateRangePicker .DayPicker-Day--end.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside)': {
                '&:before': {
                    left: '0 !important'
                }
            }
        }, theme.breakpoints.down('sm'), {
            '& .DayPicker-Month': {
                '&:first-of-type': {
                    display: 'inline-table',
                    paddingRight: 0,
                    borderRight: 'none'
                }
            }
        }),
        singleMonth: {
            '& .DayPicker-Month': {
                '&:first-of-type': {
                    display: 'inline-table',
                    paddingRight: 0,
                    borderRight: 'none'
                }
            }
        },
        isSelectingStartDay: {
            '&.hedtech-DateRangePicker .DayPicker-Day--hover:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside)': {
                '&:focus, &:hover': {
                    '&:before': {
                        right: 0
                    }
                }
            }
        },

        hideOutsideDays: {
            '& .DayPicker-Day--outside': {
                cursor: 'default',
                backgroundColor: theme.palette.grey[100],
                '&:focus, &:hover': {
                    cursor: 'default',
                    backgroundColor: theme.palette.grey[100]
                }
            }
        },

        // Popper
        popper: {
            zIndex: theme.zIndex.drawer + 1,
            left: '-0.0625rem !important'
        },

        // IconContainer
        iconContainer: {
            display: 'flex',
            alignItems: 'center',
            padding: '0 ' + spacing20,
            fill: theme.palette.grey[500]
        },
        iconContainerDisabled: {
            backgroundColor: theme.palette.grey[200]
        },

        // InputContainer
        inputContainer: {
            border: 'solid ' + borderWidthThin + ' ' + theme.palette.grey[400] + ' ',
            display: 'inline-flex',
            borderRadius: borderRadiusMedium,
            height: 'auto',
            width: widthInputFields,
            backgroundColor: theme.palette.grey[100]
        },
        inputContainerFullWidth: {
            width: '100%'
        },
        inputContainerDisabled: {
            backgroundColor: theme.palette.grey[200]
        },
        inputContainerError: {
            border: borderWidthThick + ' solid ' + theme.palette.status.error.fill
        },

        // Small input
        smallDateRangePicker: {
            height: heightInputFieldsSmall
        },
        smallDateRangePickerInput: {
            height: '2.25rem',
            padding: 0,
            '& input': {
                height: '1.25rem',
                padding: '0.4375rem',
                fontSize: fontSizeDefault
            }
        },
        inputLabelSmall: {
            top: '-0.35rem',
            left: '0.625rem',
            fontSize: fontSizeDefault,
            '&[data-shrink=true]': {
                display: 'none'
            }
        },

        // Input
        inputRoot: {
            borderRadius: borderRadiusMedium
        },
        input: {
            height: '1.25rem',
            padding: paddingTextField,
            border: 'solid ' + borderWidthThin + ' transparent',
            '&:focus': {
                border: 'solid ' + borderWidthThin + ' ' + theme.palette.grey[400] + ' ',
                boxShadow: boxShadowFocus
            }
        },
        inputDisabled: {
            color: theme.palette.grey['500'],
            backgroundColor: theme.palette.grey[200],
            cursor: 'not-allowed',
            outline: 'none',
            '&:hover': {
                cursor: 'not-allowed',
                color: theme.palette.grey['500']
            },
            '&:active': {
                color: theme.palette.grey['500']
            }
        },
        startInput: {
            borderTopLeftRadius: borderRadiusMedium,
            borderBottomLeftRadius: borderRadiusMedium
        },
        endInput: {
            borderTopRightRadius: borderRadiusMedium,
            borderBottomRightRadius: borderRadiusMedium
        },
        inputFocused: {
            boxShadow: boxShadowFocus,
            border: 'solid ' + borderWidthThin + ' ' + theme.palette.grey[400] + ' ',
            '&$inputFocusedError': {
                boxShadow: '0 0 0 ' + borderWidthThick + ' ' + theme.palette.status.error.fill,
                borderColor: '' + theme.palette.status.error.fill
            }
        },
        inputFocusedError: {
            '&:focus': {
                boxShadow: '0 0 0 ' + borderWidthThick + ' ' + theme.palette.status.error.fill,
                border: 'solid ' + borderWidthThin + ' ' + theme.palette.status.error.fill
            }
        },

        // InputLabel
        inputLabelFormControl: {
            zIndex: zIndexTextFieldLabel,
            left: spacing40,
            transform: 'translate(0, 1.125rem) scale(1)'
        },
        inputLabelShrink: {
            fontSize: fontSizeDefault,
            transform: 'translate(0, .5rem) scale(0.75)'
        }
    };
};

export default styles;