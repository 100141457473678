function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { default as withStyles } from '../styles';
import { default as MuiAvatar } from '@material-ui/core/Avatar';
import { borderWidthThick, colorBorderGlobalFocus, fontFamilyHeader } from '../styles/tokens';
import AvatarUserIcon from './internal/AvatarUserIcon';

export var AVATAR_VARIANT_DEFAULT = 'default';
export var AVATAR_VARIANT_INTERACTIVE = 'interactive';

var styles = function styles(theme) {
    return {
        root: {
            fontFamily: fontFamilyHeader,
            fontSize: '1.125rem'
        },
        interactiveRoot: {
            transition: theme.transitions.create(['background-color', 'border', 'box-shadow'], {
                duration: theme.transitions.duration.shortest
            })
        },
        colorDefault: {
            color: theme.palette.grey['600'],
            backgroundColor: theme.palette.grey['300'],
            '&$interactiveRoot': {
                color: theme.palette.grey['600'],
                backgroundColor: theme.palette.grey['300'],
                border: 'solid ' + borderWidthThick + ' ' + theme.palette.grey['300'],
                '&:hover': {
                    backgroundColor: theme.palette.grey['250'],
                    border: 'solid ' + borderWidthThick + ' ' + theme.palette.grey['250']
                },
                '&:active': {
                    backgroundColor: theme.palette.grey['200'],
                    border: 'solid ' + borderWidthThick + ' ' + theme.palette.grey['400']
                },
                '&:focus': {
                    outline: 'solid ' + borderWidthThick + ' ' + colorBorderGlobalFocus
                },
                '&$purple': {
                    backgroundColor: theme.palette.tertiary.purple['400'],
                    border: 'solid ' + borderWidthThick + ' ' + theme.palette.tertiary.purple['400'],
                    '&:hover': {
                        backgroundColor: theme.palette.tertiary.purple['300'],
                        border: 'solid ' + borderWidthThick + ' ' + theme.palette.tertiary.purple['300']
                    },
                    '&:active': {
                        backgroundColor: theme.palette.tertiary.purple['200'],
                        border: 'solid ' + borderWidthThick + ' ' + theme.palette.tertiary.purple['400']
                    }
                },
                '&$iris': {
                    backgroundColor: theme.palette.tertiary.iris['400'],
                    border: 'solid ' + borderWidthThick + ' ' + theme.palette.tertiary.iris['400'],
                    '&:hover': {
                        backgroundColor: theme.palette.tertiary.iris['300'],
                        border: 'solid ' + borderWidthThick + ' ' + theme.palette.tertiary.iris['300']
                    },
                    '&:active': {
                        backgroundColor: theme.palette.tertiary.iris['200'],
                        border: 'solid ' + borderWidthThick + ' ' + theme.palette.tertiary.iris['400']
                    }
                },
                '&$fountain': {
                    backgroundColor: theme.palette.tertiary.fountain['400'],
                    border: 'solid ' + borderWidthThick + ' ' + theme.palette.tertiary.fountain['400'],
                    '&:hover': {
                        backgroundColor: theme.palette.tertiary.fountain['300'],
                        border: 'solid ' + borderWidthThick + ' ' + theme.palette.tertiary.fountain['300']
                    },
                    '&:active': {
                        backgroundColor: theme.palette.tertiary.fountain['200'],
                        border: 'solid ' + borderWidthThick + ' ' + theme.palette.tertiary.fountain['400']
                    }
                },
                '&$meadow': {
                    backgroundColor: theme.palette.tertiary.meadow['400'],
                    border: 'solid ' + borderWidthThick + ' ' + theme.palette.tertiary.meadow['400'],
                    '&:hover': {
                        backgroundColor: theme.palette.tertiary.meadow['300'],
                        border: 'solid ' + borderWidthThick + ' ' + theme.palette.tertiary.meadow['300']
                    },
                    '&:active': {
                        backgroundColor: theme.palette.tertiary.meadow['200'],
                        border: 'solid ' + borderWidthThick + ' ' + theme.palette.tertiary.meadow['400']
                    }
                },
                '&$kiwi': {
                    backgroundColor: theme.palette.tertiary.kiwi['400'],
                    border: 'solid ' + borderWidthThick + ' ' + theme.palette.tertiary.kiwi['400'],
                    '&:hover': {
                        backgroundColor: theme.palette.tertiary.kiwi['300'],
                        border: 'solid ' + borderWidthThick + ' ' + theme.palette.tertiary.kiwi['300']
                    },
                    '&:active': {
                        backgroundColor: theme.palette.tertiary.kiwi['200'],
                        border: 'solid ' + borderWidthThick + ' ' + theme.palette.tertiary.kiwi['400']
                    }
                },
                '&$saffron': {
                    backgroundColor: theme.palette.tertiary.saffron['400'],
                    border: 'solid ' + borderWidthThick + ' ' + theme.palette.tertiary.saffron['400'],
                    '&:hover': {
                        backgroundColor: theme.palette.tertiary.saffron['300'],
                        border: 'solid ' + borderWidthThick + ' ' + theme.palette.tertiary.saffron['300']
                    },
                    '&:active': {
                        backgroundColor: theme.palette.tertiary.saffron['200'],
                        border: 'solid ' + borderWidthThick + ' ' + theme.palette.tertiary.saffron['400']
                    }
                },
                '&$tangerine': {
                    backgroundColor: theme.palette.tertiary.tangerine['400'],
                    border: 'solid ' + borderWidthThick + ' ' + theme.palette.tertiary.tangerine['400'],
                    '&:hover': {
                        backgroundColor: theme.palette.tertiary.tangerine['300'],
                        border: 'solid ' + borderWidthThick + ' ' + theme.palette.tertiary.tangerine['300']
                    },
                    '&:active': {
                        backgroundColor: theme.palette.tertiary.tangerine['200'],
                        border: 'solid ' + borderWidthThick + ' ' + theme.palette.tertiary.tangerine['400']
                    }
                }

            }
        },
        purple: {},
        iris: {},
        fountain: {},
        meadow: {},
        kiwi: {},
        saffron: {},
        tangerine: {},
        image: {
            color: theme.palette.grey['600'],
            border: 'none',
            // Set transition to 0 for image Avatars to prevent weird UI visual
            transition: theme.transitions.create(['border'], { duration: 0 }),
            '&:hover': {
                border: 'none'
            },
            '&:active': {
                border: 'solid ' + borderWidthThick + ' ' + theme.palette.grey['400']
            },
            '&:focus': {
                outline: 'solid ' + borderWidthThick + ' ' + colorBorderGlobalFocus
            }
        },
        img: {
            // Needed to fix images when HTML button element used
            width: 40,
            height: 40,
            '&:hover': {
                opacity: '0.32'
            },
            '&:active': {
                opacity: '0.4'
            }
        }
    };
};

var TERTIARY_COLORS_MAP = ['default', 'purple', 'iris', 'fountain', 'meadow', 'kiwi', 'saffron', 'tangerine'];

/**
 * A component that creates a graphical representation of a user.
 * @done true
 * @updated true
 * @versionAdded v0.0.8
 * @examples
 *  AvatarIcon
 *  AvatarImage
 *  AvatarLetter
 *  AvatarInteractive
 *  AvatarGroup
 */
var Avatar = React.forwardRef(function (props, ref) {
    var _classNames;

    var classes = props.classes,
        src = props.src,
        srcSet = props.srcSet,
        internalVariant = props.variant,
        color = props.color,
        rest = _objectWithoutProperties(props, ['classes', 'src', 'srcSet', 'variant', 'color']);

    var propOverrides = {
        component: 'div'
    };

    var hasImg = src || srcSet;
    var isInteractive = internalVariant === AVATAR_VARIANT_INTERACTIVE;

    // If no img is sent in, render tertiary background
    var shouldBeTertiary = !hasImg && isInteractive;

    if (isInteractive) {
        propOverrides.component = 'button';
    }

    var classOverrides = {
        colorDefault: classes.colorDefault,
        root: classNames(classes.root, (_classNames = {}, _defineProperty(_classNames, classes.interactiveRoot, isInteractive), _defineProperty(_classNames, classes[color], shouldBeTertiary), _defineProperty(_classNames, classes.image, hasImg && isInteractive), _classNames)),
        img: classes.img
    };

    return React.createElement(
        MuiAvatar,
        Object.assign({
            ref: ref,
            src: src,
            srcSet: srcSet,
            classes: classOverrides
        }, propOverrides, rest),
        props.children === undefined ? React.createElement(AvatarUserIcon, null) : props.children
    );
});

Avatar.muiName = 'Avatar';

Avatar.propTypes = {
    /**
     * Used in combination with src or srcSet to provide an alt attribute for the rendered img element.
     */
    alt: PropTypes.string,
    /**
     * Used to render icon or text elements inside the Avatar. src and alt props will not be used and no img will be rendered by default.
     * This can be an element, or just a string.
     */
    children: PropTypes.node,
    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object,
    /**
     * The component used for the root node. Either a string to use a DOM element or a component.
     */
    component: PropTypes.node,
    /**
     * The color of the Avatar if the variant is set to `interactive`.
     */
    color: PropTypes.oneOf(TERTIARY_COLORS_MAP),
    /**
     * Attributes applied to the img element when the component is used to display an image.
     */
    imgProps: PropTypes.object,
    /**
     * The sizes attribute for the img element.
     */
    sizes: PropTypes.string,
    /**
     * The src attribute for the img element.
     */
    src: PropTypes.string,
    /**
     * The srcSet attribute for the img element.
     */
    srcSet: PropTypes.string,
    /**
     * The variant of the `Avatar` to render.
     */
    variant: PropTypes.oneOf([AVATAR_VARIANT_DEFAULT, AVATAR_VARIANT_INTERACTIVE])
};

Avatar.displayName = 'Avatar';

Avatar.defaultProps = {
    variant: AVATAR_VARIANT_DEFAULT
};

export default withStyles(styles)(Avatar);