function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { default as withStyles } from '../styles';
import { default as MuiExpansionPanelSummary } from '@material-ui/core/ExpansionPanelSummary';
import ChevronDown from '@ellucian/ds-icons/lib/ChevronDown';
import ExpansionPanelContext from './internal/ExpansionPanelContext';

import { spacingVariant } from './../globalSpacing';

import { spacing20, spacingInsetShort30, spacing40, colorBrandNeutral100, colorBrandNeutral400, colorBrandNeutral500, colorTextPrimary, fontSizeLarge, boxShadowFocusInset } from '../styles/tokens';

var styles = function styles(theme) {
    return {
        root: {
            padding: 0,
            color: colorTextPrimary,
            outline: 'none',
            minHeight: 'auto',
            '&$focused': {
                backgroundColor: 'transparent'
            },
            '&$disabled': {
                opacity: 1
            },
            '&$expanded': {
                minHeight: 'auto',
                margin: 0,
                '& $content': {
                    margin: 0
                }
            }
        },
        expanded: {},
        disabled: {
            color: colorBrandNeutral400,
            backgroundColor: colorBrandNeutral100
        },
        focused: {
            boxShadow: boxShadowFocusInset,
            outline: 'none'
        },
        iconButtonRoot: {
            fontSize: fontSizeLarge,
            '&$disabled': {
                color: colorBrandNeutral400
            }
        },
        content: {
            margin: 0
        },
        leftIconDisplay: {
            flexDirection: 'row-reverse'
        },
        expandIcon: {
            marginRight: 'auto',
            marginLeft: '-0.75rem'
        },
        standard: _defineProperty({
            padding: spacing20 + ' ' + spacing40
        }, theme.breakpoints.up('lg'), {
            padding: spacingInsetShort30
        }),
        none: {
            padding: 0
        }
    };
};

/**
 * A component that....
 */
var ExpansionPanelSummary = React.forwardRef(function (props, ref) {
    var classes = props.classes,
        leftIconDisplay = props.leftIconDisplay,
        expandIconProps = props.expandIconProps,
        rest = _objectWithoutProperties(props, ['classes', 'leftIconDisplay', 'expandIconProps']);

    var _useContext = useContext(ExpansionPanelContext),
        disabled = _useContext.disabled,
        spacingOptions = _useContext.spacingOptions;

    var classOverrides = {
        root: classNames(classes.root, _defineProperty({}, classes.leftIconDisplay, leftIconDisplay), _defineProperty({}, classes.standard, spacingOptions.spacing === spacingVariant.STANDARD), _defineProperty({}, classes.none, spacingOptions.spacing === spacingVariant.NONE)),
        disabled: classes.disabled,
        focused: classes.focused,
        content: classes.content,
        expanded: classes.expanded
    };

    if (leftIconDisplay) {
        classOverrides.expandIcon = classNames(classes.expandIcon, 'eds-expansion-panel-icon-left-position');
    }

    return React.createElement(MuiExpansionPanelSummary, Object.assign({
        expandIcon: React.createElement(ChevronDown, Object.assign({ fill: disabled ? colorBrandNeutral400 : colorBrandNeutral500
        }, expandIconProps)),
        IconButtonProps: { disabled: disabled, classes: { root: classes.iconButtonRoot } },
        classes: classOverrides,
        ref: ref
    }, rest));
});

ExpansionPanelSummary.muiName = 'ExpansionPanelSummary';
ExpansionPanelSummary.displayName = 'ExpansionPanelSummary';

ExpansionPanelSummary.propTypes = {
    /**
     * The content of the expansion panel summary.
     */
    children: PropTypes.node,
    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object,
    /**
     * @ignore
     */
    className: PropTypes.string,
    /**
     * Properties applied to the icon element.
     */
    expandIconProps: PropTypes.object,
    /**
     * Displays the expandIcon on the left side of the ExpansionPanel.
     */
    leftIconDisplay: PropTypes.bool,
    /**
     * @ignore
     */
    onChange: PropTypes.func,
    /**
     * @ignore
     */
    onClick: PropTypes.func
};

ExpansionPanelSummary.defaultProps = {
    leftIconDisplay: false
};

export default withStyles(styles)(ExpansionPanelSummary);