// Ellucian's CTA Colors
import { colorCtaBlueBase, colorCtaBlueHover, colorCtaBlueActive, colorCtaBlueTint, colorCtaGreenBase, colorCtaGreenHover, colorCtaGreenActive, colorCtaGreenTint, colorCtaIrisBase, colorCtaIrisHover, colorCtaIrisActive, colorCtaIrisTint, colorCtaPlumBase, colorCtaPlumHover, colorCtaPlumActive, colorCtaPlumTint, colorCtaTaupeBase, colorCtaTaupeHover, colorCtaTaupeActive, colorCtaTaupeTint } from '@ellucian/react-design-system/core/styles/tokens';

var ctaColor = {
    blue: {
        base: colorCtaBlueBase,
        hover: colorCtaBlueHover,
        active: colorCtaBlueActive,
        tint: colorCtaBlueTint
    },
    green: {
        base: colorCtaGreenBase,
        hover: colorCtaGreenHover,
        active: colorCtaGreenActive,
        tint: colorCtaGreenTint
    },
    iris: {
        base: colorCtaIrisBase,
        hover: colorCtaIrisHover,
        active: colorCtaIrisActive,
        tint: colorCtaIrisTint
    },
    plum: {
        base: colorCtaPlumBase,
        hover: colorCtaPlumHover,
        active: colorCtaPlumActive,
        tint: colorCtaPlumTint
    },
    taupe: {
        base: colorCtaTaupeBase,
        hover: colorCtaTaupeHover,
        active: colorCtaTaupeActive,
        tint: colorCtaTaupeTint
    }
};

export default ctaColor;