import { EventEmitter } from 'events';
import { PasswordConfirmationEventStatus } from '../../types/PasswordConfirmation/PasswordConfirmationEventStatus';
import { PasswordConfirmationStatus } from '../../types/PasswordConfirmation/PasswordConfirmationStatus';
import AppDispatcher from '../AppDispatcher';
class PasswordConfirmationStoreEmitter extends EventEmitter {
    constructor() {
        super();
        this.accountValidations = this.getDefaultAccountValidations();
        this.password = '';
        this.passwordPolicy = undefined;
    }
    addPasswordPolicyListener(callback) {
        this.addListener(PasswordConfirmationEventStatus.ChangePasswordPolicy, callback);
    }
    addPasswordValidationListener(callback) {
        this.addListener(PasswordConfirmationEventStatus.ChangePasswordValidation, callback);
    }
    removePasswordPolicyListener(callback) {
        this.removeListener(PasswordConfirmationEventStatus.ChangePasswordPolicy, callback);
    }
    removePasswordValidationListener(callback) {
        this.removeListener(PasswordConfirmationEventStatus.ChangePasswordValidation, callback);
    }
    emitPasswordPolicy() {
        this.emit(PasswordConfirmationEventStatus.ChangePasswordPolicy);
    }
    emitPasswordValidation() {
        this.emit(PasswordConfirmationEventStatus.ChangePasswordValidation);
    }
    getDefaultAccountValidations() {
        return {
            confirmPassword: '',
            confirmPasswordModified: false,
            emailInvalid: false,
            emailModified: false,
            existingUser: false,
            firstNameModified: false,
            hasErrors: false,
            hasPasswordPolicy: false,
            isNotPreviousPassword: false,
            isPwdUpperCase: false,
            isPwdLowerCase: false,
            isPwdNumberChar: false,
            isPwdSpecialChar: false,
            isMinimumChar: false,
            lastNameModified: false,
            passwordModified: false,
            previousPasswordModified: false
        };
    }
    getPasswordValidation() {
        return this.accountValidations;
    }
    getPassword() {
        return this.password;
    }
    getPasswordPolicy() {
        return this.passwordPolicy;
    }
    setPasswordPolicy(passwordPolicy) {
        this.passwordPolicy = passwordPolicy;
    }
    setPasswordValidation(password, accountValidations) {
        this.password = password || '';
        if (accountValidations) {
            this.accountValidations = accountValidations;
        }
        else {
            this.accountValidations = this.getDefaultAccountValidations();
        }
    }
}
const PasswordConfirmationStore = new PasswordConfirmationStoreEmitter();
AppDispatcher.register((callback) => {
    switch (callback.type) {
        case PasswordConfirmationStatus.SuccessSetPasswordPolicy:
            PasswordConfirmationStore.setPasswordPolicy(callback.payload.passwordPolicy);
            PasswordConfirmationStore.emitPasswordPolicy();
            break;
        case PasswordConfirmationStatus.SuccessSetPasswordValidation:
            PasswordConfirmationStore.setPasswordValidation(callback.payload.password, callback.payload.accountValidations);
            PasswordConfirmationStore.emitPasswordValidation();
            break;
        default:
            break;
    }
});
export default PasswordConfirmationStore;
