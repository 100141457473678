import LayoutActions from '../flux/actions/LayoutActions';
import LogData from '../helpers/LogData';
const Storage = {
    loadFromStorage(key) {
        try {
            const stateFromStorage = localStorage.getItem(key);
            if (stateFromStorage == null) {
                return undefined;
            }
            return JSON.parse(stateFromStorage);
        }
        catch (e) {
            LayoutActions.setLogData(LogData.fromException(this.loadFromStorage.name, e));
        }
    },
    saveToStorage(key, value) {
        try {
            const stateFromStorage = JSON.stringify(value);
            localStorage.setItem(key, stateFromStorage);
        }
        catch (e) {
            LayoutActions.setLogData(LogData.fromException(this.saveToStorage.name, e));
        }
    },
    removeFromStorage(key) {
        try {
            localStorage.removeItem(key);
        }
        catch (e) {
            LayoutActions.setLogData(LogData.fromException(this.removeFromStorage.name, e));
        }
    },
    clearStorage() {
        try {
            localStorage.clear();
        }
        catch (e) {
            LayoutActions.setLogData(LogData.fromException(this.clearStorage.name, e));
        }
    }
};
export default Storage;
