function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import React from 'react';
import PropTypes from 'prop-types';

import Paper from '../Paper';
import Typography from '../Typography';
import TreeView from './internal/TreeView';
import TreeItem from './internal/TreeItem';

import makeStyles from '../makeStyles';
import { borderWidthThin, spacing60 } from '../styles/tokens';

var useStyles = makeStyles(function (theme) {
    return {
        root: {
            display: 'flex',
            width: '100%',
            height: '100%'
        },
        sideMenuRoot: {
            borderRight: borderWidthThin + ' solid ' + theme.palette.grey[300],
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column'
        },
        sideTitle: {
            padding: spacing60,
            borderBottom: borderWidthThin + ' solid ' + theme.palette.grey[300]
        },
        sideMenuTreeViewContainer: {
            overflow: 'auto',
            paddingBottom: spacing60
        },
        sideMenuTreeView: {},
        contentRoot: {
            padding: spacing60,
            width: '100%',
            height: '100%',
            overflowY: 'auto'
        }
    };
});

/**
 * A layout container that has a side menu and content area
 * @done false
 * @updated true
 * @versionAdded v5.1.0
 * @template true
 * @examples
 *  MasterDetailExample
 */
var MasterDetail = React.forwardRef(function (props, ref) {
    var children = props.children,
        menu = props.menu,
        title = props.title,
        TitleTypographyProps = props.TitleTypographyProps,
        onNodeSelect = props.onNodeSelect,
        defaultExpanded = props.defaultExpanded,
        defaultSelected = props.defaultSelected,
        rest = _objectWithoutProperties(props, ['children', 'menu', 'title', 'TitleTypographyProps', 'onNodeSelect', 'defaultExpanded', 'defaultSelected']);

    var localClasses = useStyles();

    // Similar to this: https://material-ui.com/components/tree-view/#rich-object
    var generateMenu = function generateMenu() {
        var generateChildren = function generateChildren(items) {
            var treeItems = items.length > 0 && items.map(function (_ref) {
                var label = _ref.label,
                    nodeId = _ref.nodeId,
                    childrenProp = _ref.children,
                    itemRest = _objectWithoutProperties(_ref, ['label', 'nodeId', 'children']);

                return React.createElement(
                    TreeItem,
                    Object.assign({ key: nodeId + '-' + label, label: label, nodeId: nodeId }, itemRest),
                    childrenProp && generateChildren(childrenProp)
                );
            });
            return treeItems;
        };

        return React.createElement(
            TreeView,
            {
                onNodeSelect: onNodeSelect,
                className: localClasses.sideMenuTreeView,
                defaultExpanded: defaultExpanded,
                defaultSelected: defaultSelected
            },
            menu.map(function (child) {
                var label = child.label,
                    nodeId = child.nodeId,
                    childrenProp = child.children,
                    childProps = _objectWithoutProperties(child, ['label', 'nodeId', 'children']);

                return React.createElement(
                    TreeItem,
                    Object.assign({ key: nodeId + '-' + label, label: label, nodeId: nodeId }, childProps),
                    childrenProp && generateChildren(childrenProp)
                );
            })
        );
    };
    return React.createElement(
        Paper,
        Object.assign({ ref: ref, className: localClasses.root }, rest),
        React.createElement(
            'div',
            { className: localClasses.sideMenuRoot },
            React.createElement(
                Typography,
                Object.assign({ variant: 'h2', className: localClasses.sideTitle }, TitleTypographyProps),
                title
            ),
            React.createElement(
                'div',
                { className: localClasses.sideMenuTreeViewContainer },
                generateMenu()
            )
        ),
        React.createElement(
            'div',
            { className: localClasses.contentRoot },
            children
        )
    );
});

MasterDetail.muiName = 'MasterDetail';

MasterDetail.propTypes = {
    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object,
    /**
     * The content of the detail view.
     */
    children: PropTypes.node,
    /**
     * The title of the master view.
     */
    title: PropTypes.string,
    /**
     * Props applied to the `Typography` element of the `title` prop.
     */
    TitleTypographyProps: PropTypes.object,
    /**
     * The structure and content of the master tree.
     *
     * Accepts an array of objects, one for each node in the tree. Object format:
     *
     * * `label`: {string} (required) The node label.
     * * `nodeId`: {string} (required) The id of the node. Provided to `onNodeSelect` when the node is selected.
     * * `onClick`: {function} Callback that's invoked when the node is clicked.
     * * `children`: {array} The children of this node. Accepts an array with the same structure as the parent `menu`.
     */
    menu: PropTypes.array,
    /**
     * Callback fired when tree items are selected/unselected.
     *
     * `@param {object} event`: The event source of the callback
     *
     * `@param {string} value`: The `nodeId` of the selected node.
     */
    onNodeSelect: PropTypes.func,
    /**
     * The nodes that should be automatically expanded when the tree is rendered. Accepts an array of `nodeId`s.
     */
    defaultExpanded: PropTypes.arrayOf(PropTypes.string),
    /**
     * The node that should be selected by default when the tree is rendered. Accepts a `nodeId`.
     */
    defaultSelected: PropTypes.string
};

export default MasterDetail;