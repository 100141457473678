var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { default as withStyles } from '../styles';
import { default as MuiTooltip } from '@material-ui/core/Tooltip';
import { withEDSContext } from '../EDSContext/EDSContext';

import { spacing40, spacing20 } from '../styles/tokens';

import isNil from 'lodash/isNil';

var styles = function styles(theme) {
    return {
        tooltip: _defineProperty({
            paddingLeft: spacing40,
            paddingRight: spacing40,
            fontSize: theme.typography.body2.fontSize,
            maxWidth: '18.750rem',
            lineHeight: theme.typography.caption.lineHeight,
            textAlign: 'center',
            backgroundColor: theme.palette.grey[500]
        }, theme.breakpoints.up('sm'), {
            fontSize: theme.typography.caption.fontSize,
            padding: spacing20
        })
    };
};

/**
 * Use `Tooltip` to display a small amount of informative text.
 *
 * @done true
 * @updated false
 * @versionAdded v0.0.9
 * @examples
 *  TooltipExample
 *  TooltipPositionedExample
 *  TooltipDisabledButton
 */
var Tooltip = React.forwardRef(function (props, ref) {
    var classes = props.classes,
        edsContext = props.edsContext,
        PopperProps = props.PopperProps,
        openProp = props.open,
        onOpenProp = props.onOpen,
        onCloseProp = props.onClose,
        rest = _objectWithoutProperties(props, ['classes', 'edsContext', 'PopperProps', 'open', 'onOpen', 'onClose']);

    var classOverrides = {
        tooltip: classes.tooltip
    };

    // maintain open state of tooltip (if tooltip is uncontrolled)

    var _useState = useState(false),
        _useState2 = _slicedToArray(_useState, 2),
        open = _useState2[0],
        setOpen = _useState2[1];

    var popperPropOverrides = Object.assign({ dir: edsContext.dir }, PopperProps);

    // close tooltip when escape is pressed
    var escapeDetector = useCallback(function (e) {
        if (e.key === 'Escape' || e.key === 'Esc') {

            // controlled
            if (onCloseProp) {
                onCloseProp(e);

                // uncontrolled
            } else {
                setOpen(false);
            }
        }
    }, [onCloseProp, setOpen]);

    // register keyboard handler to detect escape key press when tooltip is opened
    useEffect(function () {

        if (open || openProp) {
            document.addEventListener('keydown', escapeDetector);
        }
        return function () {
            document.removeEventListener('keydown', escapeDetector);
        };
    }, [open, openProp, escapeDetector]);

    return React.createElement(MuiTooltip, Object.assign({
        PopperProps: popperPropOverrides,
        classes: classOverrides,
        ref: ref,
        onOpen: isNil(onOpenProp) ? function () {
            return setOpen(true);
        } : onOpenProp,
        onClose: isNil(onCloseProp) ? function () {
            return setOpen(false);
        } : onCloseProp,
        open: isNil(openProp) ? open : openProp
    }, rest));
});

Tooltip.muiName = 'Tooltip';
Tooltip.displayName = 'Tooltip';

Tooltip.propTypes = {
    /**
     * Tooltip reference element.
     */
    children: PropTypes.element.isRequired,
    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object,
    /**
     * @ignore
     */
    className: PropTypes.string,
    /**
     * Do not respond to focus events.
     */
    disableFocusListener: PropTypes.bool,
    /**
     * Do not respond to hover events.
     */
    disableHoverListener: PropTypes.bool,
    /**
     * Do not respond to long press touch events.
     */
    disableTouchListener: PropTypes.bool,
    /**
     * The number of milliseconds to wait before showing the tooltip.
     * This property won't impact the enter touch delay (`enterTouchDelay`).
     */
    enterDelay: PropTypes.number,
    /**
     * The number of milliseconds to wait before showing the tooltip after one was already recently opened.
     */
    enterNextDelay: PropTypes.number,
    /**
     * The number of milliseconds a user must touch the element before showing the tooltip.
     */
    enterTouchDelay: PropTypes.number,
    /**
     * The relationship between the tooltip and the wrapper component is not clear from the DOM.
     * By providing this property, we can use aria-describedby to solve the accessibility issue.
     */
    id: PropTypes.string,
    /**
     * The number of milliseconds to wait before hiding the tooltip.
     * This property won't impact the leave touch delay (`leaveTouchDelay`).
     */
    leaveDelay: PropTypes.number,
    /**
     * The number of milliseconds after the user stops touching an element before hiding the tooltip.
     */
    leaveTouchDelay: PropTypes.number,
    /**
     * Callback fired when the tooltip requests to be closed.
     *
     * @param {object} event The event source of the callback
     */
    onClose: PropTypes.func,
    /**
     * Callback fired when the tooltip requests to be open.
     *
     * @param {object} event The event source of the callback
     */
    onOpen: PropTypes.func,
    /**
     * If `true`, the tooltip is shown.
     */
    open: PropTypes.bool,
    /**
     * Tooltip placement.
     *
     * When using values besides "top" or "bottom", be sure to evaluate the Tooltip direction when your app is RTL.
     */
    placement: PropTypes.oneOf(['bottom-end', 'bottom-start', 'bottom', 'left-end', 'left-start', 'left', 'right-end', 'right-start', 'right', 'top-end', 'top-start', 'top']),
    /**
     * Properties applied to the `Popper` element.
     */
    PopperProps: PropTypes.object,
    /**
     * @ignore
     */
    theme: PropTypes.object,
    /**
     * Tooltip title. Zero-length titles string are never displayed.
     */
    title: PropTypes.node.isRequired
};

// If default props are needed, they must be set here
Tooltip.defaultProps = {
    enterDelay: 100,
    enterTouchDelay: 700,
    leaveDelay: 0,
    leaveTouchDelay: 1500,
    disableFocusListener: false,
    disableHoverListener: false,
    disableTouchListener: false,
    placement: 'bottom'
};

export default withEDSContext(withStyles(styles)(Tooltip));