'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _tokens = require('@ellucian/react-design-system/core/styles/tokens');

var palette = {
    // Iris
    primary: {
        main: _tokens.colorBrandPrimary,
        contrastText: _tokens.colorTextNeutral100
    },
    // Fountain
    secondary: {
        main: _tokens.colorBrandSecondary,
        contrastText: _tokens.colorTextNeutral100
    },
    // CTA Blue
    ctaColor: {
        base: _tokens.colorCtaBlueBase,
        hover: _tokens.colorCtaBlueHover,
        active: _tokens.colorCtaBlueActive,
        tint: _tokens.colorCtaBlueTint
    },
    // Disabled
    disabled: {
        background: _tokens.colorBrandNeutral400
    },
    // Global Focus
    focus: _tokens.colorBorderGlobalFocus,
    // Status: Error, Warning, Pending, Success
    status: {
        default: {
            text: _tokens.colorTextAlertDefault,
            background: _tokens.colorBackgroundAlertDefault
        },
        error: {
            text: _tokens.colorTextAlertError,
            background: _tokens.colorBackgroundAlertError,
            fill: _tokens.colorFillAlertError
        },
        success: {
            text: _tokens.colorTextAlertSuccess,
            background: _tokens.colorBackgroundAlertSuccess,
            fill: _tokens.colorFillAlertSuccess
        },
        neutral: {
            text: _tokens.colorTextAlertNeutral,
            background: _tokens.colorBackgroundAlertNeutral,
            fill: _tokens.colorFillAlertNeutral
        },
        warning: {
            text: _tokens.colorTextAlertWarning,
            background: _tokens.colorBackgroundAlertWarning,
            fill: _tokens.colorFillAlertWarning
        }
    },
    common: {
        black: _tokens.colorBrandNeutral600,
        white: _tokens.colorBrandNeutral100
    },
    text: {
        primary: _tokens.colorTextPrimary,
        secondary: _tokens.colorTextSecondary,
        disabled: _tokens.colorTextDisabled,
        white: _tokens.colorTextNeutral100
    },
    background: {
        default: _tokens.colorBackgroundDefault
    },
    tertiary: {
        purple: {
            100: '#F8EEF6',
            200: '#ECCCE6',
            300: '#DFAAD6',
            400: '#D389C6',
            500: '#C667B6',
            600: '#BA46A6'
        },
        iris: {
            100: '#EEEEFA',
            200: '#CFCFF2',
            300: '#B0B0EA',
            400: '#9191E2',
            500: '#7272D9',
            600: '#5353D1'
        },
        fountain: {
            100: '#EFF7FF',
            200: '#CEE7FF',
            300: '#AFD8FF',
            400: '#90C9FF',
            500: '#70BAFF',
            600: '#51ABFF'
        },
        meadow: {
            100: '#E8F9F5',
            200: '#BFEDE2',
            300: '#96E2CF',
            400: '#6CD6BC',
            500: '#43CBA9',
            600: '#1ABF96'
        },
        kiwi: {
            100: '#F4FAEF',
            200: '#E1F2D3',
            300: '#CEEAB7',
            400: '#BBE29B',
            500: '#A8D97E',
            600: '#95D162'
        },
        saffron: {
            100: '#FDF9EA',
            200: '#FBEFC3',
            300: '#F8E59C',
            400: '#F5DB75',
            500: '#F2D14F',
            600: '#EFC728'
        },
        tangerine: {
            100: '#FFF4EB',
            200: '#FFDFC8',
            300: '#FFCAA4',
            400: '#FFB581',
            500: '#FFA15D',
            600: '#FF8C3A'
        }
    }
}; // Ellucian's Default theme
exports.default = palette;