var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '../IconButton';
import TextField from '../TextField';
import { default as withStyles } from '../styles';
import classNames from 'classnames';
import TablePagination from '@material-ui/core/TablePagination';
import ChevronDownIcon from '@ellucian/ds-icons/lib/ChevronDown';
import ArrowEndLeftIcon from '@ellucian/ds-icons/lib/ArrowEndLeft';
import ArrowEndRightIcon from '@ellucian/ds-icons/lib/ArrowEndRight';
import ChevronRightIcon from '@ellucian/ds-icons/lib/ChevronRight';
import ChevronLeftIcon from '@ellucian/ds-icons/lib/ChevronLeft';
import Tooltip from '../Tooltip/Tooltip';
import Typography from '../Typography';
import { withEDSContext } from '../EDSContext/EDSContext';
import { borderRadiusSmall, borderRadiusMedium, borderWidthThin, sizingSmall, sizingXLarge, spacing60, spacing50, spacing40, spacing30, spacing20, spacing80, fontSizeDefault, boxShadowFocusInset, widthFluid, colorGlobalBorderDefault } from '../styles/tokens';

// PaginationAction Styles
var actionStyles = function actionStyles(theme) {
    var _root;

    return {
        root: (_root = {
            alignItems: 'center',
            display: 'flex',
            flex: 1,
            order: 2
        }, _defineProperty(_root, theme.breakpoints.down('md'), {
            flex: '1 1 100%',
            textAlign: 'center',
            paddingBottom: spacing30,
            borderBottom: borderWidthThin + ' solid ' + theme.palette.grey[300],
            marginBottom: spacing30,
            justifyContent: 'space-between'
        }), _defineProperty(_root, '& $paginationButtons', {
            // NOTE: Could create an 'icon' classes prop to handle this sizing
            '& svg': {
                width: sizingSmall,
                height: sizingSmall
            }
        }), _defineProperty(_root, '& $textField', {
            // NOTE: Remove when Textfield supports us overriding the classes prop
            width: sizingXLarge,
            marginLeft: spacing30,
            marginRight: spacing30,
            verticalAlign: 'baseline',
            '& input': {
                padding: '0.1rem',
                textAlign: 'center',
                fontSize: theme.typography.caption.fontSize
            }
        }), _root),
        typography: {
            display: 'flex',
            alignSelf: 'center'
        },
        // NOTE: This should be sent to the TextField classes.root
        // But our implementation doesn't allow this
        // textFieldRoot: {
        //     width: 32,
        //     marginLeft: 8,
        //     marginRight: 8,
        //     verticalAlign: 'baseline',
        // },
        // Remove if TextField can support classes override
        textField: {},
        // Remove after transparent IconButtons variant is created
        paginationButtons: {},
        pageNumberInput: _defineProperty({
            display: 'flex',
            margin: '0 ' + spacing80
        }, theme.breakpoints.down('md'), {
            margin: 0
        }),
        buttonSpacer: _defineProperty({
            marginRight: spacing50
        }, theme.breakpoints.down('md'), {
            marginRight: 0
        })
    };
};

var PaginationActions = function (_React$Component) {
    _inherits(PaginationActions, _React$Component);

    function PaginationActions() {
        var _ref;

        var _temp, _this, _ret;

        _classCallCheck(this, PaginationActions);

        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
        }

        return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = PaginationActions.__proto__ || Object.getPrototypeOf(PaginationActions)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
            pageNumberInput: _this.props.page + 1 || 1 // zero-based number
        }, _this.handleFirstPageButtonClick = function (event) {
            _this.setState({
                pageNumberInput: 1
            }, function () {
                return _this.props.onChangePage(event, 0);
            });
        }, _this.handleBackButtonClick = function (event) {
            var page = _this.props.page - 1;

            _this.setState({
                pageNumberInput: page + 1
            }, function () {
                return _this.props.onChangePage(event, page);
            });
        }, _this.handleNextButtonClick = function (event) {
            var page = _this.props.page + 1;
            _this.setState({
                pageNumberInput: page + 1
            }, function () {
                return _this.props.onChangePage(event, page);
            });
        }, _this.handleLastPageButtonClick = function (event) {
            // returns 0-based
            var page = Math.max(0, Math.ceil(_this.props.count / _this.props.rowsPerPage) - 1);

            // display value non-0 based
            _this.setState({
                pageNumberInput: page + 1
            }, function () {
                return _this.props.onChangePage(event, page);
            });
        }, _this.handleTextFieldOnChange = function (event) {
            var value = event.target.value;
            if (!isNaN(Number(value))) {
                _this.setState({
                    pageNumberInput: value
                });
            }
        }, _this.handleTextFieldOnBlur = function (event) {
            event.preventDefault();
            if (_this.paginationPageTextField.value - 1 !== _this.props.page) {
                _this.handlePageChange(event);
            }
        }, _this.handleTextFieldFocus = function () {
            _this.paginationPageTextField.select();
        }, _this.handleEnterKeyPress = function (event) {
            if (event.key === 'Enter') {
                event.preventDefault();
                if (_this.paginationPageTextField.value - 1 !== _this.props.page) {
                    _this.handlePageChange(event);
                }
            }
        }, _temp), _possibleConstructorReturn(_this, _ret);
    }

    // Implementation Note: pageNumberInput is the value that appears in the "page number" text field.
    // It's distinguished from the incoming "page" prop because it needs to vary independently; on one
    // hand, it should update whenever the page changes, but it ALSO needs to change as the user types
    // in that field, without updating the page (until the field blurs)

    _createClass(PaginationActions, [{
        key: 'componentDidUpdate',
        value: function componentDidUpdate(prevProps) {
            var _this2 = this;

            if (prevProps.rowsPerPage !== this.props.rowsPerPage || prevProps.count !== this.props.count) {
                this.setState({
                    pageNumberInput: 1
                }, function () {
                    return _this2.props.onChangePage({}, 0);
                });
            }
            if (prevProps.page !== this.props.page && this.props.page !== this.state.pageNumberInput - 1) {
                this.setState({
                    pageNumberInput: this.props.page + 1
                });
            }
        }
    }, {
        key: 'handlePageChange',


        // Shared function to change page or alter state
        // Receives `value` from the TextField
        value: function handlePageChange(event) {
            var _this3 = this;

            // 1-based
            var value = this.paginationPageTextField.value;

            // 0-based
            var totalNumberOfPages = Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1);

            if (value <= 0) {
                // If value is zero or negative
                // Go to first page
                this.setState({ pageNumberInput: 1 }, function () {
                    return _this3.props.onChangePage(event, 0);
                });
            } else if (value >= 1 && value <= totalNumberOfPages) {
                // If value is greater than 1
                // and value is less than or equal totalNumberOfPages
                this.setState({
                    pageNumberInput: value
                }, this.props.onChangePage(event, Number(value) - 1));
            } else if (value >= totalNumberOfPages + 1) {
                // If value is greater than total number of pages
                // Go to last page
                this.setState({
                    pageNumberInput: totalNumberOfPages + 1
                }, this.props.onChangePage(event, totalNumberOfPages));
            }
        }
    }, {
        key: 'render',
        value: function render() {
            var _this4 = this;

            var _props = this.props,
                classes = _props.classes,
                count = _props.count,
                page = _props.page,
                rowsPerPage = _props.rowsPerPage,
                theme = _props.theme,
                edsContext = _props.edsContext;
            var pageNumberInput = this.state.pageNumberInput;


            var totalNumberOfPages = Math.max(0, Math.ceil(count / rowsPerPage) - 1);
            var disablePreviousButtons = page === 0;
            var disableNextButtons = page >= Math.ceil(count / rowsPerPage) - 1;

            return React.createElement(
                'div',
                { className: '' + classes.root },
                React.createElement(
                    Tooltip,
                    { title: edsContext.formatMessage('component.Pagination.firstPage'), enterDelay: 1000, enterNextDelay: 1000 },
                    React.createElement(
                        IconButton,
                        {
                            onClick: this.handleFirstPageButtonClick,
                            disabled: disablePreviousButtons,
                            color: 'gray',
                            'aria-label': edsContext.formatMessage('component.Pagination.firstPage'),
                            className: classes.paginationButtons + ' ' + classes.buttonSpacer
                        },
                        theme.direction === 'rtl' ? React.createElement(ArrowEndRightIcon, null) : React.createElement(ArrowEndLeftIcon, null)
                    )
                ),
                React.createElement(
                    Tooltip,
                    { title: edsContext.formatMessage('component.Pagination.previousPage'), enterDelay: 1000, enterNextDelay: 1000 },
                    React.createElement(
                        IconButton,
                        {
                            onClick: this.handleBackButtonClick,
                            disabled: disablePreviousButtons,
                            color: 'gray',
                            'aria-label': edsContext.formatMessage('component.Pagination.previousPage'),
                            className: classes.paginationButtons
                        },
                        theme.direction === 'rtl' ? React.createElement(ChevronRightIcon, null) : React.createElement(ChevronLeftIcon, null)
                    )
                ),
                React.createElement(
                    'span',
                    { className: classes.pageNumberInput + ' page-input' },
                    React.createElement(
                        Typography,
                        { variant: 'body2', className: classes.typography },
                        edsContext.formatMessage('component.Pagination.page')
                    ),
                    React.createElement(TextField, {
                        className: classNames(classes.textField),
                        inputRef: function inputRef(input) {
                            return _this4.paginationPageTextField = input;
                        },
                        value: pageNumberInput,
                        inputProps: { 'aria-label': '' + pageNumberInput },
                        onChange: this.handleTextFieldOnChange,
                        margin: 'normal',
                        onKeyPress: this.handleEnterKeyPress,
                        onFocus: this.handleTextFieldFocus,
                        onBlur: this.handleTextFieldOnBlur,
                        disabled: totalNumberOfPages === 0
                    }),
                    React.createElement(
                        Typography,
                        { variant: 'body2', className: classes.typography },
                        edsContext.formatMessage('component.Pagination.ofTotalCount', { count: totalNumberOfPages + 1 })
                    )
                ),
                React.createElement(
                    Tooltip,
                    { title: edsContext.formatMessage('component.Pagination.nextPage'), enterDelay: 1000, enterNextDelay: 1000 },
                    React.createElement(
                        IconButton,
                        {
                            onClick: this.handleNextButtonClick,
                            disabled: disableNextButtons,
                            color: 'gray',
                            'aria-label': edsContext.formatMessage('component.Pagination.nextPage'),
                            className: classes.paginationButtons + ' ' + classes.buttonSpacer
                        },
                        theme.direction === 'rtl' ? React.createElement(ChevronLeftIcon, null) : React.createElement(ChevronRightIcon, null)
                    )
                ),
                React.createElement(
                    Tooltip,
                    { title: edsContext.formatMessage('component.Pagination.lastPage'), enterDelay: 1000, enterNextDelay: 1000 },
                    React.createElement(
                        IconButton,
                        {
                            onClick: this.handleLastPageButtonClick,
                            disabled: disableNextButtons,
                            color: 'gray',
                            'aria-label': edsContext.formatMessage('component.Pagination.lastPage'),
                            className: classes.paginationButtons
                        },
                        theme.direction === 'rtl' ? React.createElement(ArrowEndLeftIcon, null) : React.createElement(ArrowEndRightIcon, null)
                    )
                )
            );
        }
    }]);

    return PaginationActions;
}(React.Component);

PaginationActions.propTypes = {
    classes: PropTypes.object.isRequired,
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    theme: PropTypes.object.isRequired,
    edsContext: PropTypes.object.isRequired
};

var PaginationActionsWrapped = withEDSContext(withStyles(actionStyles, { withTheme: true })(PaginationActions));

// Pagination Styles
var styles = function styles(theme) {
    return {
        root: _defineProperty({
            // Target the "Total results..." text
            '& $caption:nth-last-child(2)': _defineProperty({
                order: 4,
                marginRight: 0,
                marginLeft: 'auto'
            }, theme.breakpoints.down('md'), {
                marginRight: spacing30
            })
        }, theme.breakpoints.down('md'), {
            width: widthFluid,
            paddingTop: spacing30,
            paddingBottom: spacing60,

            '&:last-child': {
                paddingTop: spacing30,
                paddingBottom: spacing60

                // TODO: To be used if it needs to be attached to bottom or made configurable
                // bottom: "0",
                // zIndex: "99",
                // background: "rgba(255, 255, 255, 1)",
                // position: "fixed",
                // left: 0,
                // boxShadow: "0px -3px 10px #ccc",
            } }),
        toolbar: _defineProperty({
            padding: 0,
            margin: '0 ' + spacing40 + ' ' + spacing40 + ' ' + spacing30,
            minHeight: '3.5rem'
        }, theme.breakpoints.down('md'), {
            justifyContent: 'flex-start',
            flexWrap: 'wrap',
            margin: '0 ' + spacing30 + ' 0 ' + spacing30
        }),
        spacer: {
            flex: 'none',
            order: 1
        },
        caption: {
            fontSize: theme.typography.caption.fontSize,
            color: theme.palette.grey[500],
            order: 3,
            marginRight: spacing40,
            marginLeft: spacing30
        },
        selectRoot: _defineProperty({
            marginRight: spacing80,

            '& $select': {
                paddingRight: spacing60,
                minHeight: 'auto'
            }

        }, theme.breakpoints.down('md'), {
            marginRight: 0
        }),
        selectIcon: {
            top: '0.4rem',
            right: spacing20,
            fill: theme.palette.grey[500],
            height: sizingSmall
        },
        select: {
            fontSize: theme.typography.caption.fontSize,
            '&:focus': {
                borderRadius: borderRadiusSmall,
                boxShadow: boxShadowFocusInset,
                backgroundColor: 'transparent'
            }
        },
        input: {
            order: 4
        },
        menuList: {
            border: colorGlobalBorderDefault + ' ' + borderWidthThin + ' solid',
            borderRadius: borderRadiusMedium
        },
        menuListItem: {
            '& :first-child': {
                borderTopRightRadius: borderRadiusSmall,
                borderTopLeftRadius: borderRadiusSmall
            },
            '& :last-child': {
                borderBottomRightRadius: borderRadiusSmall,
                borderBottomLeftRadius: borderRadiusSmall
            }
        },
        menuItem: {
            padding: spacing30 + ' 0.75rem',
            borderBottom: borderWidthThin + ' solid ' + colorGlobalBorderDefault,
            // Had to use `!important` below to override styles as MUI Pagination is adding inline styles.
            minWidth: spacing60 + ' !important',
            '&:last-child': {
                borderBottom: 'transparent ' + borderWidthThin + ' solid'
            },
            fontSize: fontSizeDefault,
            '&:hover': {
                color: theme.palette.grey[600],
                backgroundColor: theme.palette.grey[250]
            },
            //Using aria-selected as a workaround as accessing the selected styles of menuItem was not possible
            '&[aria-selected=true]': {
                backgroundColor: theme.palette.ctaColor.base,
                color: theme.palette.grey[100],
                '&:hover': {
                    backgroundColor: theme.palette.ctaColor.active
                }
            }
        },

        menuPaper: {
            boxShadow: 'none'
        }
    };
};

/**
 * Use `Pagination` to allow users to navigate large data sets by breaking out the data into smaller, more consumable "pages."
 * @done true
 * @updated false
 * @versionAdded v0.0.8
 * @examples
 *  PaginationExamples
 *  PaginationWithListExamples
 *  PaginationWithTableExamples
 */

var Pagination = function (_React$Component2) {
    _inherits(Pagination, _React$Component2);

    function Pagination() {
        _classCallCheck(this, Pagination);

        return _possibleConstructorReturn(this, (Pagination.__proto__ || Object.getPrototypeOf(Pagination)).apply(this, arguments));
    }

    _createClass(Pagination, [{
        key: 'render',
        value: function render() {
            var _props2 = this.props,
                classes = _props2.classes,
                rowsPerPageOptions = _props2.rowsPerPageOptions,
                count = _props2.count,
                edsContext = _props2.edsContext,
                rowsPerPageShowAll = _props2.rowsPerPageShowAll,
                labelRowsPerPage = _props2.labelRowsPerPage,
                SelectProps = _props2.SelectProps,
                rest = _objectWithoutProperties(_props2, ['classes', 'rowsPerPageOptions', 'count', 'edsContext', 'rowsPerPageShowAll', 'labelRowsPerPage', 'SelectProps']);

            // Append `count` to the Select input


            var _rowsPerPageOption = rowsPerPageOptions;
            if (rowsPerPageShowAll) {
                _rowsPerPageOption = [].concat(_toConsumableArray(rowsPerPageOptions), [count]);
            }

            var classOverrides = {
                root: classes.root,
                toolbar: classes.toolbar,
                spacer: classes.spacer,
                caption: classes.caption,
                selectRoot: classes.selectRoot,
                select: classes.select,
                selectIcon: classes.selectIcon,
                input: classes.input,
                menuItem: classes.menuItem
            };

            var selectProps = Object.assign({
                IconComponent: ChevronDownIcon,
                MenuProps: {
                    classes: {
                        paper: classes.menuPaper
                    },
                    MenuListProps: {
                        dir: edsContext.dir,
                        className: classes.menuList,
                        disablePadding: true,
                        classes: {
                            root: classes.menuListItem
                        }
                    }
                }
            }, SelectProps);

            return React.createElement(TablePagination, Object.assign({
                ActionsComponent: PaginationActionsWrapped,
                classes: classOverrides,
                count: count,
                rowsPerPageOptions: _rowsPerPageOption,
                labelRowsPerPage: labelRowsPerPage === Pagination.defaultProps.labelRowsPerPage ? // translate unless a label was provided
                edsContext.formatMessage('component.Pagination.perPage') : labelRowsPerPage,
                labelDisplayedRows: function labelDisplayedRows(rows) {
                    return edsContext.formatMessage('component.Pagination.totalResults') + ' ' + rows.count;
                },
                SelectProps: selectProps
            }, rest));
        }
    }]);

    return Pagination;
}(React.Component);

;

Pagination.muiName = 'Pagination';

Pagination.propTypes = {
    /**
     * The component used for the root node.
     * Either a string to use a DOM element or a component.
     * EX: Use TableCell if used within Table
     */
    component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object,
    /**
     * The total number of rows.
     */
    count: PropTypes.number.isRequired,
    /**
     * @ignore
     */
    edsContext: PropTypes.object,
    /**
     * Customize the rows per page label. Invoked with a `{ from, to, count, page }`
     * object.
     */
    labelRowsPerPage: PropTypes.node,
    /**
     * Callback fired when the page is changed.
     *
     * @param {object} event The event source of the callback
     * @param {number} page The page selected
     */
    onChangePage: PropTypes.func.isRequired,
    /**
     * Callback fired when the number of rows per page is changed.
     *
     * @param {object} event The event source of the callback
     */
    onChangeRowsPerPage: PropTypes.func,
    /**
     * The zero-based index of the current page.
     */
    page: PropTypes.number,
    /**
     * The number of rows per page.
     */
    rowsPerPage: PropTypes.number.isRequired,
    /**
     * Customizes the options of the rows per page select field. If less than two options are
     * available, no select field will be displayed.
     */
    rowsPerPageOptions: PropTypes.array,
    /**
     * Set to `true` to render a total count option in the `Select` in the last index.
     */
    rowsPerPageShowAll: PropTypes.bool,
    /**
     * Properties applied to the rows per page `Select` element.
     */
    SelectProps: PropTypes.object
};

// If default props are needed, they must be set here
Pagination.defaultProps = {
    labelRowsPerPage: 'Per page:',
    component: 'div',
    rowsPerPageOptions: [10, 20],
    rowsPerPageShowAll: false
};

export default withEDSContext(withStyles(styles)(Pagination));