var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import classnames from 'classnames';
import omit from 'lodash/omit';
import React, { forwardRef } from 'react';
import EdsCard from '@ellucian/react-design-system/core/es/Card';
import { createStyles, withStyles } from '../../core/styles/withStyles';
const styles = createStyles({
    noOverflow: {
        overflow: 'hidden'
    },
    root: {}
});
const Card = (props, ref) => {
    const { children, classes, noOverflow } = props, rest = __rest(props, ["children", "classes", "noOverflow"]);
    const classOverrides = omit(classes, ['noOverflow']);
    if (noOverflow) {
        classOverrides.root = classnames(classes.root, classes.noOverflow);
    }
    return (React.createElement(EdsCard, Object.assign({ classes: classOverrides, legacySpacingMode: false, ref: ref }, rest), children));
};
const CardFRC = forwardRef(Card);
CardFRC.displayName = 'CardFRC';
export default withStyles(styles, { name: 'CardWS' })(CardFRC);
